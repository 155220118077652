<template>
    <div class="my-3">
        <div class="w-100">
            <h1 class="text-center h3">
                Gastos registrados para
                {{ selectedMerchant.merchantType | merchantInfo("type") }}
                <strong class="text-primary">
                    {{ selectedMerchant.name }}
                </strong>
                {{ itemsNumber }}
            </h1>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div class="my-3 col-12">
                <time-filters v-model="query" />
            </div>
            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12"
            >
                <div
                    class="my-3 d-flex align-items-center justify-content-around"
                >
                    <vs-button v-show="query" relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>

                <div
                    class="my-3 d-flex align-items-center justify-content-around"
                >
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />

                    <tooltip-redirect
                        v-if="$ability.hasScope('cashOutput:Create')"
                        to="/app/cash-outputs/new"
                        message="Registrar gasto"
                    >
                    </tooltip-redirect>
                </div>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="single"
                responsive
                selectable
                outlined
                no-border-collapse
                hover
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(id)="data">
                    <router-link
                        :to="`/app/cash-outputs/detail/${data.item.id}?merchantType=${data.item.merchantType}&merchantId=${data.item.merchantId}`"
                        class="text-center font-weight-normal btn btn-primary p-1"
                    >
                        <small>
                            {{
                                data.item.value === data.item.valuePaid
                                    ? "🟢"
                                    : "⚪"
                            }}
                            &nbsp; {{ data.item.id }}
                        </small>
                    </router-link>
                </template>
                <template #cell(concept)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.concept | cut(36) }}
                    </small>
                </template>
                <template #cell(provider)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.provider }}
                    </small>
                </template>

                <template #cell(value)="data">
                    <div class="d-flex flex-column align-items-center">
                        <small class="text-center font-italic font-weight-bold">
                            {{ data.item.value | money }}
                        </small>
                        <small class="text-center font-italic text-muted">
                            &nbsp; {{ data.item.valuePaid | money }}
                        </small>
                    </div>
                </template>

                <template #cell(createdAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.createdAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import TimeFilters from "@/components/utils/TimeFilters.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "CashOutputsIndex",
    components: { TooltipRedirect, TimeFilters },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        query: {},
        itemsCurrent: [],
        $fields: [
            { key: "id", label: "Id", sortable: false },
            { key: "concept", label: "Concepto", sortable: true },
            { key: "provider", label: "Proveedor", sortable: true },
            { key: "value", label: "Valor/Valor pagado", sortable: true },
            { key: "createdAt", label: "📆 Creación", sortable: true }
        ],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        selected: []
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapState("control", ["activeDark"]),
        ...mapState("cashOutput", ["cashOutput"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        selectedMerchant() {
            if (this.showTable) {
                this.listItems(true);
            }
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        const pass = await verifyPageScope("cashOutput:List", "/app", true);
        if (pass) this.listItems(true);
    },
    methods: {
        ...mapActions("cashOutput", ["listCashOutputsPaginated"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const { items, pagination } =
                    await this.listCashOutputsPaginated({
                        complete,
                        page: this.currentPage,
                        merchantId: this.selectedMerchant.merchantId,
                        merchantType: this.selectedMerchant.merchantType,
                        ...this.query
                    });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        onRowSelected(items) {
            this.selected = items;
        },
        cloneSelected() {
            window
                .open(
                    `/app/cash-outputs/clone/${this.selected[0].id}`,
                    "_blank"
                )
                .focus();
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        }
    }
};
</script>
