<template>
    <div>
        <div class="text-center mt-4">
            <h1 class="h2">Ventas por producto</h1>
            <p>
                En esta sección podrás consultar las ventas de un producto en el
                periodo de tiempo que configures. Recuerda que una venta es una
                orden con estado de entrega completado.
            </p>
        </div>
        <div v-show="showTable" class="d-flex flex-wrap mt-4">
            <div class="col-12 text-center">
                <router-link :to="`/app/products/detail/${productCurrent.id}`">
                    <span class="h2 text-center font-weight-normal">
                        {{ productCurrent.name }}
                    </span>
                </router-link>
            </div>
            <div class="col-12 text-center mt-2">
                <span class="h3">Total {{ productCurrent.total }}</span>
            </div>
        </div>

        <div class="my-3 col-12">
            <time-filters v-model="query" />
        </div>

        <div
            class="d-flex justify-content-center flex-column flex-lg-row col-12"
        >
            <div class="my-3 d-flex justify-content-around">
                <vs-button v-show="query" relief @click="listItems">
                    Consultar 🔎
                </vs-button>
            </div>
        </div>
        <div v-show="showTable" class="w-100 mt-3">
            <b-table
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                responsive
                outlined
                no-border-collapse
                hover
                class="col-12"
            >
                <template #cell(id)="data">
                    <div class="d-flex align-items-center">
                        <a
                            :href="data.item.url"
                            target="_blank"
                            rel="noopener"
                            class="text-center font-weight-normal"
                        >
                            <h6>
                                {{ data.item.id }}
                            </h6>
                        </a>
                    </div>
                </template>
                <template #cell(productId)="data">
                    <router-link
                        :to="`/app/products/detail/${data.item.productId}`"
                        class="text-center font-weight-normal"
                    >
                        <span class="h6">
                            {{ data.item.productId }}
                        </span>
                    </router-link>
                </template>

                <template #cell(name)="data">
                    <small class="text-center">
                        {{ data.item.name }}
                    </small>
                </template>
                <template #cell(sku)="data">
                    <small class="text-center">
                        {{ data.item.sku }}
                    </small>
                </template>
                <template #cell(size)="data">
                    <small class="text-center text-capitalize">
                        {{ data.item.size }}
                    </small>
                </template>
                <template #cell(color)="data">
                    <small class="text-center text-capitalize">
                        {{ data.item.color }}
                    </small>
                </template>
                <template #cell(priceOffer)="data">
                    <small class="text-center">
                        {{ data.item.priceOffer | money }}
                    </small>
                </template>
                <template #cell(sales)="data">
                    <small class="text-center text-primary">
                        {{ data.item.sales }}
                    </small>
                </template>
            </b-table>
            <div class="d-flex">
                <vs-button icon @click="downloadExcelComponent">
                    ⏬ Descargar excel
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { downloadExcel } from "@/common/lib/excel";

import TimeFilters from "@/components/utils/TimeFilters.vue";

export default {
    name: "ProductsOrder",
    components: {
        TimeFilters
    },
    layout: "app",
    meta: {
        roles: ["administrator"]
    },
    data: () => ({
        query: { agoMon: 1 },
        loaderInstance: null,
        itemsCurrent: [],
        idSku: "",
        $fields: [
            { key: "id", label: "ID Ref.", sortable: false },
            {
                key: "size",
                label: "Tamaño/Talla/Valor",
                sortable: true
            },
            {
                key: "color",
                label: "Color",
                sortable: false
            },
            {
                key: "priceOffer",
                label: "Precio Actual",
                sortable: true
            },
            {
                key: "sales",
                label: "Ventas",
                sortable: true
            }
        ],
        showTable: false,
        isLoading: false,
        productCurrent: {
            id: 0,
            name: "",
            total: 0
        }
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("sale:ListByProduct", "/app"))) return;
        if ("idSku" in this.$route.params) {
            const idSku = this.$route.params.idSku;
            this.idSku = idSku;
            this.listItems();
        } else {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                icon: "info",
                title: "Ingresa el ID o SKU del producto",
                text: "El SKU identifica al producto lo puedes encontrar en el detalle del producto. También puedes consultar la venta de los productos haciendo click los botónes con el icono 📈 en las cards de productos.",
                input: "text",
                confirmButtonColor: "#253C99",
                showDenyButton: true,
                showCloseButton: false,
                denyButtonColor: "#f07325",
                confirmButtonText: "Validar Id o Sku Ingresado",
                denyButtonText: "Ir a consulta de productos"
            });
            if (dialogResult.isConfirmed) {
                this.idSku = dialogResult.value;
                const url = this.$route.fullPath;
                window.history.pushState(
                    { idsku: "references" },
                    "Product",
                    `${url}/${this.idSku}`
                );
                this.query = {
                    agoMon: 1
                };
                this.listItems();
            } else {
                this.$router.push("/app/products/search");
            }
        }
    },
    beforeDestroy() {
        this.$swal.close();
    },
    methods: {
        ...mapActions("orders", ["listSalesByProduct"]),
        async listItems() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                const result = await this.listSalesByProduct({
                    idSku: this.idSku,
                    query: this.query
                });
                this.itemsCurrent = result.references;
                this.productCurrent = {
                    id: result.id,
                    name: result.name,
                    total: result.total
                };
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        downloadExcelComponent() {
            const filename = `sales_${this.productCurrent.name.replaceAll(
                " ",
                "_"
            )}
                `.substring(0, 31);
            downloadExcel({ row: this.itemsCurrent, filename });
        }
    }
};
</script>
