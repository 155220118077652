<template>
    <div>
        <div class="ml-2">
            <h1 v-if="id" class="h2">
                Detalle de tienda

                <strong class="text-primary">
                    #{{ id }} {{ itemComponent.isOwn ? "(Propia)" : "" }}
                </strong>
            </h1>
            <h1 v-else class="h2">Nueva Tienda</h1>
        </div>

        <vs-navbar
            v-if="id"
            id="tabs-products"
            v-model="activeTab"
            center-collapsed
            not-line
            class="position-static"
        >
            <template #left>
                <vs-navbar-item id="home">
                    <router-link
                        to="/app/stores"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                </vs-navbar-item>

                <vs-navbar-item
                    id="general"
                    :active="['general', 'home'].includes(activeTab)"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> General </span>
                        <span>&nbsp;⚙</span>
                    </div>
                </vs-navbar-item>

                <vs-navbar-item
                    v-if="$ability.hasScope('store:GetMoney')"
                    id="money"
                    :active="activeTab === 'money'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> Dinero </span>
                        <span>&nbsp;💰</span>
                    </div>
                </vs-navbar-item>

                <vs-navbar-item
                    v-if="$ability.hasScope('store:GetServices')"
                    id="services"
                    :active="activeTab === 'services'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> Servicios </span>
                        <span>&nbsp;👨‍🏭</span>
                    </div>
                </vs-navbar-item>

                <vs-navbar-item
                    v-if="$ability.hasScope('user:ListOwn')"
                    id="users"
                    :active="activeTab === 'users'"
                    class="px-2 px-md-3"
                    @click="listMerchantsUserComponent"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> Usuarios </span>
                        <span>&nbsp;👩‍🔧</span>
                    </div>
                </vs-navbar-item>

                <vs-navbar-item
                    v-if="$enabledEcommerceOptions"
                    id="seo"
                    :active="activeTab === 'seo'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> SEO&nbsp; </span>
                        <span>&nbsp;🐱‍💻</span>
                    </div>
                </vs-navbar-item>

                <vs-navbar-item
                    id="advance"
                    :active="activeTab === 'advance'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> Avanzado&nbsp; </span>
                        <span>🤖</span>
                    </div>
                </vs-navbar-item>
            </template>
            <template #right>
                <vs-switch
                    v-if="$ability.hasScope('store:Update')"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </template>
        </vs-navbar>

        <div v-show="['general', 'home'].includes(activeTab)">
            <div v-if="id" class="d-flex flex-wrap">
                <div
                    class="col-12 col-md-6 mt-5 d-flex justify-content-center align-items-center"
                >
                    <div
                        v-if="
                            $enabledEcommerceOptions &&
                            $ability.hasScope('store:UpdateActive')
                        "
                    >
                        <active-selector
                            v-model="itemComponent.enabledForDeliveries"
                            :enabled="enabledUpdate"
                            :weight="parseInt(itemComponent.weight)"
                            label="Habilitado para entregas"
                            on-label="Si"
                            off-label="No"
                            @change="validateToggleActive"
                        />
                    </div>
                </div>
                <div
                    class="col-12 col-md-6 mt-5 p-0 d-flex justify-content-around align-items-center"
                >
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ itemComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.name"
                        border
                        shadow
                        :state="stateEnabled"
                        icon
                        label="Nombre"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="setSlug"
                    >
                        <template
                            v-if="!isValidName & introValidators.name"
                            #message-danger
                        >
                            Nombre de tienda invalido
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.slug"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Slug"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.slug = true"
                    >
                        <template
                            v-if="!isValidSlug && introValidators.slug"
                            #message-danger
                        >
                            Slug invalido
                        </template>
                    </vs-input>
                </div>
            </div>

            <div class="d-flex flex-wrap mt-3">
                <div class="col-12 mx-auto px-3">
                    <label for="description">Descripción</label>
                    <markdown-editor
                        v-model="itemComponent.description"
                        class="mt-4"
                        :enabled="enabledUpdate"
                    />
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <fragment v-if="$enabledEcommerceOptions">
                    <div class="col-12 col-md-6 mt-5">
                        <vs-input
                            v-model="itemComponent.shortDescription"
                            border
                            shadow
                            :state="stateEnabled"
                            label="Descripción"
                            class="opacity-false"
                            :disabled="!enabledUpdate"
                        />
                    </div>
                </fragment>

                <div class="col-12 col-md-6 mt-5">
                    <city-code-selector
                        v-model="itemComponent.cityCode"
                        :enabled="enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.address"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Dirección Ej. Cll 42 # 12 - 8"
                        placeholder="Cll 42 # 12 - 8"
                        :disabled="!enabledUpdate"
                        @blur="onBlurAddress"
                    >
                        <template
                            v-if="!isValidAddress & introValidators.address"
                            #message-danger
                        >
                            Dirección invalida
                        </template>
                    </vs-input>
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.addressComplement"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Complemento de Dirección Ej. Local 123"
                        placeholder="Local 123"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.neighborhood"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Barrio / Localidad"
                        :disabled="!enabledUpdate"
                    />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.phone"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Celular de contacto"
                        type="number"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.phone = true"
                    >
                        <template
                            v-if="!isValidPhone & introValidators.phone"
                            #message-danger
                        >
                            Celular invalido
                        </template>
                    </vs-input>
                </div>
                <fragment v-if="$enabledEcommerceOptions">
                    <div class="col-12 col-md-6 mt-5">
                        <vs-input
                            v-model="itemComponent.telegramChannelId"
                            border
                            shadow
                            :state="stateEnabled"
                            class="w-100 opacity-false"
                            label="Id de canal de Telegram"
                            :disabled="!enabledUpdate"
                        />
                    </div>
                    <div class="col-12 col-md-6 mt-5">
                        <vs-input
                            v-model="itemComponent.tin"
                            border
                            shadow
                            :state="stateEnabled"
                            class="w-100 opacity-false"
                            label="Nit (Optional)"
                            :disabled="!enabledUpdate"
                        />
                    </div>
                    <div class="col-12 col-md-6 mt-5">
                        <keywords-selector
                            v-model="itemComponent.keywords"
                            label="Palabras clave"
                            :enabled="enabledUpdate"
                        />
                    </div>
                    <div class="col-12 col-md-6 mt-5">
                        <coordinates-selector
                            v-model="itemComponent.coordinates"
                        />
                    </div>
                </fragment>
            </div>

            <div class="d-flex flex-wrap mt-4">
                <images-manage
                    :id="`${id}`"
                    v-model="images"
                    type="tienda"
                    :enabled="enabledUpdate"
                />
            </div>

            <div class="d-flex flex-wrap mt-4">
                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button
                        v-if="!id"
                        size="large"
                        @click="createItemComponent"
                    >
                        Crear tienda
                    </vs-button>
                    <vs-button v-else size="large" @click="updateItemComponent">
                        Actualizar tienda
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'money'">
            <div class="d-flex mt-4 mx-3">
                En esta sección puedes actualizar el dinero que esta en la
                caja.Ten cuidado de no hacerlo en un horario donde haya mucho
                flujo de trasacciones ya que puedes eliminar el dinero ingresado
                por una trasacción accidentalmente.
            </div>
            <money-config-manage
                v-model="itemComponent.moneyConfig"
                :enabled="
                    $ability.hasScope('store:UpdateMoney') && enabledUpdate
                "
            />
            <div class="d-flex flex-wrap mt-4">
                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button @click="updateMerchantMoneyComponent">
                        Actualizar dinero en tienda
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'services'">
            <services-in-merchant-manage
                v-model="itemComponent.services"
                :enabled="
                    $ability.hasScope('store:UpdateServices') && enabledUpdate
                "
                class="mt-4"
            />
            <div class="d-flex flex-wrap mt-4">
                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button @click="updateServicesMerchantComponent">
                        Actualizar servicios
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'users'">
            <div
                v-if="isLoadingUsers"
                class="col-12 d-flex justify-content-center my-4"
            >
                <span>Cargando...</span>
            </div>
            <div v-else>
                <users-roles-manage
                    v-model="users"
                    :enabled="
                        $ability.hasScope('user:UpdateOwn') && enabledUpdate
                    "
                    :default-filters="{ type: 'merchant' }"
                />
                <div class="d-flex flex-wrap mt-4">
                    <div
                        v-if="enabledUpdate"
                        class="col-12 d-flex justify-content-center"
                    >
                        <vs-button @click="updateUsersMerchantComponent">
                            Actualizar usuarios
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'seo'" id="seoTab">
            <seo-analytic
                :meta-description="itemComponent.shortDescription"
                :url="`/stores/${itemComponent.slug}`"
                :title="itemComponent.name"
                :keywords="itemComponent.keywords"
                :description="`# ${itemComponent.name} 
                ${itemComponent.description}`"
            />
        </div>

        <div v-if="activeTab === 'advance'" id="advanceTab" class="">
            <div id="title-routes" class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h2>Avanzado</h2>
                    <span>
                        En esta sección prodrás activar o desactivar inventario,
                        sincronizar precios o eliminar la tienda aqui, ten mucho
                        cuidado.
                    </span>
                </div>
            </div>
            <div v-if="id" class="d-flex flex-wrap justify-content-around mt-5">
                <vs-button
                    v-if="enabledUpdate"
                    @click="selectMerchantComponent"
                >
                    Selecciona tienda para operaciones
                </vs-button>
                <vs-button
                    v-if="
                        $enabledEcommerceOptions &&
                        $ability.hasScope('store:Transfer')
                    "
                    @click="transferComponent"
                >
                    Establecer como
                    {{ itemComponent.isOwn ? "proveedor" : "sucursal" }}
                </vs-button>
                <vs-button
                    v-if="$ability.hasScope('inventory:SyncPrices')"
                    @click="syncPricesInventoryComponent"
                >
                    Sincronizar precios con {{ $siteType }}
                </vs-button>
                <vs-button
                    v-if="$ability.hasScope('inventory:Active')"
                    @click="activeInventoryComponent"
                >
                    Activar todo el inventario
                </vs-button>

                <vs-button
                    v-if="$ability.hasScope('inventory:Active')"
                    warn
                    @click="disableInventoryComponent"
                >
                    Desactivar todo el inventario
                </vs-button>
                <vs-button
                    v-if="$ability.hasScope('store:Delete')"
                    danger
                    @click="deleteItemComponent"
                >
                    Eliminación
                </vs-button>
                <vs-button
                    v-if="$ability.hasScope('store:Delete')"
                    danger
                    @click="deleteItemDefinitelyComponent"
                >
                    Eliminación definitiva
                </vs-button>
            </div>
        </div>

        <vs-dialog
            v-model="openActiveModal"
            auto-width
            @close="closeActiveModal"
        >
            <template #header>
                <h2>Quieres habilitar la tienda ?</h2>
            </template>
            <div class="my-3 mx-2">
                <div class="col-10 mx-auto">
                    <span>
                        Recuerda cargar imágenes para la tienda, de esta forma
                        será más atractiva. Selecciona el peso de ordenamiento,
                        la tienda con peso 1 será la primera en aparecer en el
                        listado.
                    </span>
                </div>

                <vs-input
                    v-model="itemComponent.weight"
                    class="col-8 mx-auto mt-5 opacity-false"
                    label="Peso"
                    state="primary"
                    type="number"
                    step="1"
                    min="1"
                />
            </div>
            <template #footer>
                <div class="con-footer d-flex justify-content-center my-2">
                    <vs-button @click="updateActiveComponent">
                        Activar tienda
                    </vs-button>
                </div>
            </template>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { EMPTY_MONEY_CONFIG } from "@/common/lib/money";
import {
    addressRegex,
    phoneRegex,
    slugRegex,
    titleRegex
} from "@/common/lib/regex";
import { slugify } from "@/common/lib/transformers";
import { validateConfirmationMessage } from "@/common/lib/transformers";

import UsersRolesManage from "@/components/users/UsersRolesManage.vue";
import ActiveSelector from "@/components/utils/ActiveSelector.vue";
import CityCodeSelector from "@/components/utils/CityCodeSelector.vue";
import CoordinatesSelector from "@/components/utils/CoordinatesSelector.vue";
import ImagesManage from "@/components/utils/ImagesManage.vue";
import KeywordsSelector from "@/components/utils/KeywordsSelector.vue";
import MarkdownEditor from "@/components/utils/MarkdownEditor.vue";
import MoneyConfigManage from "@/components/utils/MoneyConfigManage.vue";
import SeoAnalytic from "@/components/utils/SeoAnalytic.vue";
import ServicesInMerchantManage from "@/components/utils/ServicesInMerchantManage.vue";

export default {
    name: "StoreDetail",
    components: {
        SeoAnalytic,
        ImagesManage,
        ActiveSelector,
        MarkdownEditor,
        UsersRolesManage,
        KeywordsSelector,
        CoordinatesSelector,
        CityCodeSelector,
        MoneyConfigManage,
        ServicesInMerchantManage
    },
    data: () => ({
        activeTab: "general",
        id: "",
        isLoading: false,
        isLoadingUsers: false,
        enabledUpdate: false,
        loaderInstance: null,
        loadedAllData: false,
        openActiveModal: false,
        users: [],
        images: [],
        itemComponent: {
            id: "",
            isOwn: false,
            name: "",
            slug: "",
            tin: "",
            description: "",
            enabledForDeliveries: false,
            shortDescription: "",
            phone: "",
            coordinates: { latitude: 4.63082, longitude: -74.15035 },
            moneyConfig: EMPTY_MONEY_CONFIG,
            address: "",
            cityCode: "11001",
            keywords: [],
            neighborhood: "",
            addressComplement: "",
            weight: 1000,
            telegramChannelId: "",
            images: []
        },
        introValidators: {
            phone: false,
            address: false,
            name: false,
            slug: false
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidAddress() {
            return addressRegex.test(this.itemComponent.address);
        },
        isValidPhone() {
            return phoneRegex.test(this.itemComponent.phone);
        },
        isValidName() {
            return titleRegex.test(this.itemComponent.name);
        },
        isValidSlug() {
            return slugRegex.test(this.itemComponent.slug);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {},
    created() {
        this.$watch(
            () => this.$route,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    location.reload();
                }
            },
            {
                deep: true
            }
        );
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("store:Get", "/app/stores"))) return;
            const id = this.$route.params.id;
            this.id = id;
            await this.getBaseItem(id);
        } else if ("idClone" in this.$route.params) {
            if (!(await verifyPageScope("store:Create", "/app/stores"))) return;
            this.id = "";
            this.enabledUpdate = true;
            const idTmp = this.$route.params.idClone;
            await this.getBaseItem(idTmp);
            this.itemComponent = {
                ...this.itemComponent,
                name: `${this.itemComponent.name} CLONE`,
                slug: `${this.itemComponent.slug}-clone`,
                enabledForDeliveries: false
            };
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando una tienda",
                icon: "info",
                showCloseButton: true
            });
        } else {
            await verifyPageScope("store:Create", "/app/stores");
            this.enabledUpdate = true;
            this.loadedAllData = true;
        }
    },
    methods: {
        ...mapMutations("commons", ["SET_MERCHANT_SELECTED"]),
        ...mapActions("auth", ["applyMerchantScopes"]),
        ...mapActions("stores", [
            "getStoreById",
            "updateStore",
            "deleteStore",
            "createStore",
            "updateStoreMoney",
            "setStoreActive",
            "transferStore",
            "updateStoreServices"
        ]),
        ...mapActions("inventories", [
            "syncPricesInventory",
            "activeAllInventories",
            "disableAllInventories"
        ]),
        ...mapActions("users", ["listUsersMerchant", "updateUsersMerchant"]),
        async getBaseItem(id) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const data = await this.getStoreById({ id });
                this.itemComponent = {
                    ...data,
                    ...data.communicationConfig,
                    services: data.services.map((item) => ({
                        priceOffer: item.priceOffer,
                        name: item.product.name
                    }))
                };
                this.images = [...this.itemComponent.images];
                this.loadedAllData = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/stores");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async listMerchantsUserComponent() {
            this.isLoadingUsers = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                this.users = await this.listUsersMerchant({
                    merchantId: this.id,
                    merchantType: "store"
                });
            } catch (error) {
                this.isLoadingUsers = false;
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingUsers = false;
                this.loaderInstance.close();
            }
        },
        async updateActiveComponent() {
            this.openActiveModal = false;
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.setStoreActive(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async updateItemComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateStore(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createItemComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const data = await this.createStore(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Id: ${data.id}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de tiendas",
                    cancelButtonText: "Nueva tienda"
                });
                if (dialogResult.isConfirmed) {
                    this.id = data.id;
                    this.itemComponent = { ...data, ...this.itemComponent };
                    this.$router.push(`/app/stores/detail/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/stores/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        phone: false,
                        address: false,
                        name: false,
                        slug: false
                    };
                    this.itemComponent = {
                        id: "",
                        name: "",
                        slug: "",
                        enabledForDeliveries: false,
                        description: "",
                        shortDescription: "",
                        phone: "",
                        coordinates: {
                            latitude: 4.63082,
                            longitude: -74.15035
                        },
                        moneyConfig: EMPTY_MONEY_CONFIG,
                        address: "",
                        cityCode: "11001",
                        keywords: [],
                        neighborhood: "",
                        addressComplement: "",
                        weight: 1000
                    };
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/stores");
                }
            } finally {
                this.isLoading = false;
            }
        },
        async updateMerchantMoneyComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateStoreMoney({
                    id: this.itemComponent.id,
                    moneyConfig: this.itemComponent.moneyConfig
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async updateServicesMerchantComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateStoreServices({
                    id: this.itemComponent.id,
                    services: this.itemComponent.services.map(
                        (item, index) => ({
                            priceOffer: item.priceOffer,
                            product: {
                                name: item.name
                            },
                            quantity: 1,
                            id: index + 1
                        })
                    )
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async updateUsersMerchantComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateUsersMerchant({
                    merchantType: "store",
                    merchantId: this.id,
                    users: this.users.map((item) => ({
                        email: item.email,
                        roleSlug: item.role.slug
                    }))
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async setSlug() {
            this.introValidators.name = true;
            if (
                this.enabledUpdate &&
                this.isValidName &&
                !this.itemComponent.enabledForDeliveries
            ) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Quieres cambiar el slug ?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33ddd",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                });
                if (dialogResult.isConfirmed) {
                    this.itemComponent.slug = slugify(this.itemComponent.name);
                }
            }
        },
        async deleteItemComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: `Estas seguro deseas eliminar la tienda ?`,
                text: "Las tiendas solo deben eliminarse en caso de que se esten haciendo pruebas, eres consiente de lo que estas haciendo ?. Escribe 'Si' para eliminar",
                icon: "warning",
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (
                dialogResult.isConfirmed &&
                validateConfirmationMessage(dialogResult.value)
            ) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "corners" });
                try {
                    await this.deleteStore({
                        id: this.id
                    });
                    this.$router.push("/app");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async deleteItemDefinitelyComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: `Estas seguro`,
                text: `Las tiendas solo deben eliminarse en caso de que se esten haciendo pruebas, eres consiente de lo que estas haciendo ?. Escribe 'Si' para eliminar`,
                icon: "warning",
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (
                dialogResult.isConfirmed &&
                validateConfirmationMessage(dialogResult.value)
            ) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "corners" });
                try {
                    await this.deleteStore({
                        id: this.id,
                        definitely: true
                    });
                    this.$router.push("/app");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async syncPricesInventoryComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Vas a sincronizar los precios de tu inventario ?",
                text: `Quieres que TODOS los precios del comercio ${this.itemComponent.name} tendrá el mismo precio de la referencia en el ${this.$siteType}. Para reestablecer un precio debes entrar el detalle de la referencia.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({
                    type: "square-rotate"
                });
                try {
                    await this.syncPricesInventory({
                        merchantType: "store",
                        merchantId: this.itemComponent.id
                    });
                    const sound = this.$sounds.get("success");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Actualización exitosa",
                        icon: "success",
                        showCloseButton: true
                    });
                } catch (error) {
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async transferComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: this.itemComponent.isOwn
                    ? "Vas a marcar la tienda como proveedor ?"
                    : "Vas a marcar la tienda como sucursal ?",
                text: this.itemComponent.isOwn
                    ? `A partir de ahora cada vez que ingreses un producto al inventario no estará habilitado por defecto para asignación de ordenes recibidas por el ${this.$siteType}.`
                    : `A partir de ahora tendrás disponible cada producto que ingreses en el inventario para asignación de ordenes recibidas por el ${this.$siteType}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({
                    type: "square-rotate"
                });
                try {
                    await this.transferStore({
                        id: this.itemComponent.id,
                        isOwn: !this.itemComponent.isOwn
                    });
                    this.itemComponent.isOwn = !this.itemComponent.isOwn;
                    const sound = this.$sounds.get("success");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Actualización exitosa",
                        icon: "success",
                        showCloseButton: true
                    });
                } catch (error) {
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async activeInventoryComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Vas a activar el inventario ?",
                text: `Quieres todos los productos en el inventario esten disponibles para ser comprados en el ${this.$siteType}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({
                    type: "square-rotate"
                });
                try {
                    await this.activeAllInventories({
                        merchantType: "store",
                        merchantId: this.itemComponent.id
                    });
                    const sound = this.$sounds.get("success");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Actualización exitosa",
                        icon: "success",
                        showCloseButton: true
                    });
                } catch (error) {
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async disableInventoryComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Vas a activar el inventario ?",
                text: `Quieres todos los productos en el inventario NO esten disponibles para ser comprados en el ${this.$siteType}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({
                    type: "square-rotate"
                });
                try {
                    await this.disableAllInventories({
                        merchantType: "store",
                        merchantId: this.itemComponent.id
                    });
                    const sound = this.$sounds.get("success");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Actualización exitosa",
                        icon: "success",
                        showCloseButton: true
                    });
                } catch (error) {
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async setStoreActiveComponent() {
            this.openActiveModal = false;

            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                await this.setStoreActive(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        selectMerchantComponent() {
            const selectMerchant = {
                name: this.itemComponent.name,
                address: this.itemComponent.address,
                merchantType: "store",
                merchantId: this.itemComponent.id,
                moneyConfig: this.itemComponent.moneyConfig
            };
            this.SET_MERCHANT_SELECTED(selectMerchant);
            this.applyMerchantScopes([]);
        },
        closeActiveModal() {
            this.itemComponent.enabledForDeliveries = false;
        },
        validateToggleActive() {
            if (!this.itemComponent.enabledForDeliveries) {
                return this.updateActiveComponent();
            }
            this.openActiveModal = true;
        },
        removeUser({ id }) {
            const index = this.users.findIndex((item) => item.id == id);
            let users = [...this.users];
            users.splice(index, 1);
            this.users = [...users];
        },
        validateData() {
            this.introValidators = {
                slug: true,
                name: true,
                phone: true,
                address: true
            };

            const toComplete = [];

            if (!this.isValidSlug) {
                toComplete.push("Slug");
            }

            if (!this.isValidName) {
                toComplete.push("Nombre");
            }

            if (!this.isValidPhone) {
                toComplete.push("Celular");
            }

            if (!this.isValidAddress) {
                toComplete.push("Dirección");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Tienda invalida",
                    text: `Revisa por favor revisa los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        getDataSend() {
            return {
                ...this.itemComponent,
                communicationConfig: {
                    telegramChannelId: this.itemComponent.telegramChannelId
                },
                images: this.images.map((item) => ({
                    id: item.id,
                    sequence: item.sequence.sequence
                })),
                id: this.id
            };
        },
        onBlurAddress() {
            this.introValidators.address = true;
            this.itemComponent.address = this.itemComponent.address.trim();
        }
    }
};
</script>
