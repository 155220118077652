<template>
    <div>
        <div class="d-flex flex-column">
            <div id="title-general">
                <h1 v-if="slug" class="h2">
                    &nbsp;Detalle de blog #{{ slug }} /
                    <span class="text-primary">
                        {{ title }}
                    </span>
                </h1>
                <h1 v-else class="h2">&nbsp;Nuevo blog</h1>
            </div>
        </div>

        <div class="my-4 d-flex flex-wrap">
            <vs-navbar
                id="tabs-order"
                v-model="activeTab"
                center-collapsed
                not-line
                class="position-static"
            >
                <template #left>
                    <vs-navbar-item id="home">
                        <router-link
                            to="/app/blogs"
                            class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                        >
                            <span>🏠</span>
                        </router-link>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="general"
                        :active="['general', 'home'].includes(activeTab)"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                General&nbsp;
                            </span>
                            <span>⚙️</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="slug"
                        id="products"
                        :active="activeTab === 'products'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Productos&nbsp;
                            </span>
                            <span>🛍️</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="slug"
                        id="files"
                        :active="activeTab === 'files'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Archivos adjuntos&nbsp;
                            </span>
                            <span>🗂</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="slug && $enabledEcommerceOptions"
                        id="seo"
                        :active="activeTab === 'seo'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> SEO&nbsp; </span>
                            <span>&nbsp;🐱‍💻</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="advance"
                        :active="activeTab === 'advance'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Avanzado&nbsp;
                            </span>
                            <span>🤖</span>
                        </div>
                    </vs-navbar-item>
                </template>
                <template #right>
                    <div class="d-none d-md-block">
                        <vs-switch
                            v-if="$ability.hasScope('blog:Update') && slug"
                            v-model="enabledUpdate"
                        >
                            🖊
                        </vs-switch>
                    </div>
                </template>
            </vs-navbar>
        </div>

        <div
            v-if="$ability.hasScope('blog:Update') && slug"
            class="d-flex justify-content-center"
        >
            <div class="d-block d-md-none my-3">
                <vs-switch
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>

        <div v-show="['general', 'home'].includes(activeTab)" id="blog-general">
            <div id="general-title" class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h3>Características Generales</h3>
                    <span class="mt-3">
                        Los blogs son una parte fundamental de la estrategia de
                        contenido de cualquier marketplace. con las
                        características generales del blog tienes la oportunidad
                        de generar contenido útil, incluir palabras clave
                        relevantes y atraer tráfico orgánico.
                    </span>
                </div>
            </div>

            <div v-if="slug" class="d-flex flex-wrap">
                <div
                    v-if="$ability.hasScope('blog:Publish')"
                    class="col-12 col-md-6 mt-5 d-flex justify-content-center align-items-center"
                >
                    <div>
                        <active-selector
                            v-model="itemComponent.active"
                            :enabled="enabledUpdate"
                            :weight="parseInt(itemComponent.weight)"
                            on-label="Publicada"
                            off-label="Oculta"
                            @change="validateTogglePublish"
                        />
                    </div>
                </div>

                <div
                    class="col-12 col-md-6 mt-5 p-0 d-flex justify-content-around align-items-center"
                >
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ itemComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 mt-5">
                    <vs-input
                        v-model="itemComponent.title"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Título"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="blurTitle"
                        @click-icon="openProduction('')"
                    >
                        <template v-if="itemComponent.active" #icon>
                            🚀
                        </template>
                        <template
                            v-if="!isValidTitle & introValidators.title"
                            #message-danger
                        >
                            Título invalido
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 mt-5">
                    <vs-input
                        v-model="itemComponent.shortDescription"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Descripción"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.shortDescription = true"
                    >
                        <template
                            v-if="
                                !isValidShortDescription &
                                introValidators.shortDescription
                            "
                            #message-danger
                        >
                            Descripción invalida. Debe tener mínimo 1 caracter
                            máximo 255
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 mt-5">
                    <vs-input
                        v-model="itemComponent.slug"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Slug"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.slug = true"
                    >
                        <template
                            v-if="!isValidSlug & introValidators.slug"
                            #message-danger
                        >
                            Slug de blog invalido
                        </template>
                    </vs-input>
                </div>

                <div class="col-12 mx-auto px-3 py-5">
                    <label for="description" class="font-weight-bold">
                        Contenido
                    </label>
                    <markdown-editor
                        v-model="itemComponent.description"
                        class="mt-2"
                        :enabled="enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <keywords-manage
                        v-model="itemComponent.keywords"
                        label="Palabras clave"
                        :enabled="enabledUpdate"
                    />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <blog-types-selector
                        v-model="itemComponent.type"
                        :enabled="enabledUpdate"
                    />
                </div>
                <div class="col-12 mt-2">
                    <label for="description" class="font-weight-bold">
                        Imagen
                    </label>
                    <image-manage
                        v-model="itemComponent.image"
                        :enabled="enabledUpdate"
                        message="Te recomendamos usar imágenes tipo custom"
                        class="mt-2"
                    />
                </div>

                <div
                    v-if="enabledUpdate"
                    class="col-12 d-flex justify-content-center mt-5"
                >
                    <vs-button
                        v-if="!slug"
                        size="large"
                        @click="createBlogComponent"
                    >
                        Crear blog
                    </vs-button>
                    <vs-button v-else size="large" @click="updateBlogComponent">
                        Actualizar blog
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'products'" id="productsTab">
            <div class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h3>Productos asociados</h3>
                    <span class="mt-3">
                        Un blog es una herramienta valiosa para brindar
                        información, consejos y sugerencias sobre el uso de los
                        productos. Al asociar productos con un blog, se
                        proporcionan enlaces directos para que los clientes
                        puedan realizar compras de manera sencilla y eficiente.
                    </span>
                </div>
            </div>
            <div v-if="enabledUpdate" class="mt-4">
                <h3 class="h4">
                    Busca las productos que quieres asociar al blog
                </h3>
                <references-search-scanner
                    :default-filters="{ active: true }"
                    :enabled-scanner="enabledScanner"
                    any
                    @input="validatePushReferences"
                />
            </div>
            <references-cards
                v-model="itemComponent.references"
                class="mt-2"
                :enabled="enabledUpdate"
                empty-message="Nuestro sistema NO encuentra productos asociados 0️⃣."
            />
            <div v-if="enabledUpdate" class="d-flex justify-content-center">
                <vs-button
                    :loading="isLoadingProducts"
                    class="mt-4"
                    @click="updateBlogProductsComponent"
                >
                    Actualizar productos
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'files'" id="filesTab">
            <div id="line-container">
                <div id="line-title" class="d-flex flex-wrap">
                    <div class="d-flex flex-column">
                        <h2>Archivos adjuntos para el blog</h2>
                        <span>
                            Los archivos adjuntos, como guías de uso de
                            productos, código fuente y otros materiales
                            relevantes, pueden ser agregados al blog para
                            complementar y fortalecer el contenido que se está
                            compartiendo.
                        </span>
                    </div>
                </div>

                <files-manage
                    :id="itemComponent.slug"
                    v-model="itemComponent.files"
                    :enabled="enabledUpdate"
                    class="mt-3"
                />
                <div v-if="enabledUpdate" class="d-flex justify-content-center">
                    <vs-button
                        :loading="isLoadingFiles"
                        class="mt-4"
                        @click="updateBlogFilesComponent"
                    >
                        Actualizar archivos
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'seo'" id="seoTab">
            <seo-analytic
                :meta-description="itemComponent.shortDescription"
                :url="`/blogs/${itemComponent.slug}`"
                :title="itemComponent.title"
                :keywords="itemComponent.keywords"
                :description="itemComponent.description"
            />
        </div>

        <div v-if="activeTab === 'advance'" id="advanceTab" class="">
            <div id="title-routes" class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h2>Avanzado</h2>
                    <span>
                        En esta sección podrás eliminar el blog, ten mucho
                        cuidado.
                    </span>
                </div>
            </div>
            <div class="d-flex justify-content-around mt-5">
                <vs-button
                    v-if="$ability.hasScope('blog:Delete')"
                    danger
                    @click="deleteItemComponent"
                >
                    Eliminación
                </vs-button>
            </div>
        </div>

        <vs-dialog
            v-model="openActiveModal"
            auto-width
            @close="closeActiveModal"
        >
            <template #header>
                <h2>Quieres publicar el blog ?</h2>
            </template>
            <div class="my-3 mx-2">
                <div class="col-10 mx-auto">
                    <span>
                        Recuerda cargar la imagen del blog. Selecciona el peso
                        de ordenamiento, el blog con peso 1 será la primero en
                        aparecer en el listado.
                    </span>
                </div>

                <vs-input
                    v-model="itemComponent.weight"
                    class="col-8 mx-auto mt-5 opacity-false"
                    label="Peso"
                    state="primary"
                    type="number"
                    step="1"
                    min="1"
                />
            </div>
            <template #footer>
                <div class="con-footer d-flex justify-content-center my-2">
                    <vs-button @click="publishComponent">
                        Activar blog
                    </vs-button>
                </div>
            </template>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
    shortDescriptionRegex,
    slugRegex,
    titleRegex
} from "@/common/lib/regex";
import { slugify } from "@/common/lib/transformers";

import BlogTypesSelector from "@/components/blogs/BlogTypesSelector.vue";
import ReferencesCards from "@/components/references/ReferencesCards.vue";
import ReferencesSearchScanner from "@/components/references/ReferencesSearchScanner.vue";
import ActiveSelector from "@/components/utils/ActiveSelector.vue";
import FilesManage from "@/components/utils/FilesManage.vue";
import ImageManage from "@/components/utils/ImageManage.vue";
import KeywordsManage from "@/components/utils/KeywordsManage.vue";
import MarkdownEditor from "@/components/utils/MarkdownEditor.vue";
import SeoAnalytic from "@/components/utils/SeoAnalytic.vue";

export default {
    name: "CreateUpdateBlog",
    components: {
        SeoAnalytic,
        ImageManage,
        FilesManage,
        MarkdownEditor,
        ActiveSelector,
        ReferencesCards,
        KeywordsManage,
        BlogTypesSelector,
        ReferencesSearchScanner
    },
    data: () => ({
        itemComponent: {
            active: false,
            slug: "",
            title: "",
            description: "",
            shortDescription: "",
            keywords: [],
            weight: 1000,
            type: "tutorial",
            image: { id: 0 },
            files: [],
            references: []
        },
        introValidators: { slug: false, title: false, shortDescription: false },
        activeTab: "general",
        loaderInstance: null,
        isLoading: false,
        enabledUpdate: false,
        isLoadingFiles: false,
        isLoadingProducts: false,
        openActiveModal: false,
        slug: "",
        title: "",
        enabledScanner: true
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidTitle() {
            return titleRegex.test(this.itemComponent.title);
        },
        isValidShortDescription() {
            return shortDescriptionRegex.test(
                this.itemComponent.shortDescription
            );
        },
        isValidSlug() {
            return slugRegex.test(this.itemComponent.slug);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("slug" in this.$route.params) {
            if (!(await verifyPageScope("blog:Get", "/app/blogs"))) return;
            const slug = this.$route.params.slug;
            this.slug = slug;
            await this.getBaseItem(slug);
        } else if ("slugClone" in this.$route.params) {
            if (!(await verifyPageScope("blog:Create", "/app/blogs"))) return;
            this.enabledUpdate = true;
            const slugTmp = this.$route.params.slugClone;
            this.slug = `${slugTmp}-clone`;
            await this.getBaseItem(slugTmp);
            this.itemComponent = {
                ...this.itemComponent,
                name: `${this.itemComponent.title} CLONE`,
                slug: `${this.itemComponent.slug}-clone`,
                active: false
            };
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando un Blog",
                icon: "info",
                showCloseButton: true
            });
        } else {
            await verifyPageScope("blog:Create", "/app/blogs");
            this.enabledUpdate = true;
        }
    },
    methods: {
        ...mapActions("blogs", [
            "getBlogBySlug",
            "updateBlog",
            "deleteBlog",
            "publishBlog",
            "createBlog",
            "updateBlogsFiles",
            "updateBlogsProducts"
        ]),
        async getBaseItem(slug) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const blog = await this.getBlogBySlug({ slug });
                const { products, ...otherProperties } = blog;
                this.itemComponent = {
                    ...otherProperties,
                    references: products.map((item) => ({
                        ...item.defaultReference,
                        product: {
                            id: item.id,
                            name: item.name,
                            slug: item.slug,
                            sku: item.sku,
                            brand: item.brand
                        }
                    }))
                };
                this.title = this.itemComponent.title;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/blogs");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateBlogComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateBlog(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createBlogComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const data = await this.createBlog(this.getDataSend());
                this.loaderInstance.close();
                this.itemComponent = { ...data, ...this.itemComponent };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Slug: ${data.slug}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de Blogs",
                    cancelButtonText: "Nuevo blog"
                });
                if (dialogResult.isConfirmed) {
                    this.slug = data.slug;
                    this.itemComponent = { ...data, ...this.itemComponent };
                    this.$router.push(`/app/blogs/detail/${this.slug}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/blogs/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        slug: false,
                        title: false,
                        shortDescription: false
                    };
                    this.itemComponent = {
                        title: "",
                        description: "",
                        slug: "",
                        shortDescription: "",
                        keywords: [],
                        files: [],
                        products: [],
                        image: { id: null }
                    };
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async deleteItemComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Seguro deseas eliminar el blog ?",
                text: "Recuerda se va a eliminar tu blog y puedes perder tiempo 💀",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "circles" });
                try {
                    await this.deleteBlog({
                        slug: this.itemComponent.slug
                    });
                    this.$vs.notification({
                        title: `Blog # ${this.itemComponent.id} eliminado`,
                        color: "primary",
                        icon: "🚧"
                    });
                    this.$router.push("/app/blogs");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async setSlug() {
            if (
                this.enabledUpdate &&
                this.isValidTitle &&
                !this.itemComponent.active
            ) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Quieres cambiar el slug ?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33ddd",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                });
                if (dialogResult.isConfirmed) {
                    this.itemComponent.slug = slugify(this.itemComponent.title);
                }
            }
        },
        async updateBlogFilesComponent() {
            this.isLoadingFiles = true;
            try {
                await this.updateBlogsFiles({
                    blogSlug: this.itemComponent.slug,
                    filesIds: this.itemComponent.files.map((item) => ({
                        id: item.slug
                    }))
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingFiles = false;
            }
        },
        async updateBlogProductsComponent() {
            this.isLoadingProducts = true;
            try {
                await this.updateBlogsProducts({
                    blogSlug: this.itemComponent.slug,
                    productsIds: this.itemComponent.references.map((item) => ({
                        id: item.product.id
                    }))
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingProducts = false;
            }
        },
        closeActiveModal() {
            this.itemComponent.active = false;
        },
        blurTitle() {
            this.introValidators.title = true;
            this.setSlug();
        },
        validateTogglePublish() {
            if (!this.itemComponent.active) {
                return this.publishComponent();
            }
            this.openActiveModal = true;
        },
        validateData() {
            this.introValidators = {
                slug: true,
                title: true,
                shortDescription: true
            };

            const toComplete = [];
            if (!this.isValidSlug) {
                toComplete.push("Slug");
            }

            if (!this.isValidTitle) {
                toComplete.push("Titulo");
            }

            if (!this.isValidShortDescription) {
                toComplete.push("Descripción");
            }

            if (!this.itemComponent.image.id) {
                toComplete.push("Imagen");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Blog invalido",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        validatePushReferences(reference) {
            const index = this.itemComponent.references.findIndex(
                (item) => item.product.id === reference.product.id
            );
            if (index === -1) {
                this.itemComponent.references.unshift(reference);
                return;
            }
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Producto ya incluido",
                text: `El producto ${reference.product.name} ya esta en la lista de productos asociados al blog`,
                icon: "info"
            });
        },
        getDataSend() {
            return {
                ...this.itemComponent,
                imageId: this.itemComponent.image.id
            };
        },
        async publishComponent() {
            this.openActiveModal = false;
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.publishBlog(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
            } catch (error) {
                this.itemComponent.active = !this.itemComponent.active;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        onFocus() {
            this.enabledScanner = false;
        },
        onBlur() {
            this.enabledScanner = true;
        },
        openProduction(complement) {
            window
                .open(
                    `${this.$marketplaceUrl}/${this.itemComponent.slug}${complement}`,
                    "_blank"
                )
                .focus();
        }
    }
};
</script>
