<template>
    <div class="my-3">
        <div class="text-center">
            <h1 class="h3">
                Productos encontrados
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div class="col-12 my-3">
                <products-filters
                    v-model="query"
                    :enabled-filters="$data.$enabledFilters"
                    :default-filters="$data.$defaultFilters"
                    @enter="reloadItems"
                />
                <div class="d-flex justify-content-center">
                    <vs-button v-show="query" relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>
            </div>

            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12 my-3"
            >
                <div class="d-flex align-items-center justify-content-around">
                    <tooltip-button
                        v-show="selected.length"
                        icon="🧹"
                        message="Limpiar selección"
                        @click="clearSelected"
                    />

                    <tooltip-button
                        v-show="
                            selected.length === 1 &&
                            $ability.hasScope('product:Create')
                        "
                        icon="©"
                        message="Clonar producto"
                        @click="cloneSelected"
                    />
                    <tooltip-button
                        v-show="
                            selected.length &&
                            $ability.hasScope('product:Publish')
                        "
                        icon="🟡"
                        message="Activa / Desactiva productos seleccionados"
                        @click="activeMassiveProductsComponent"
                    />
                </div>

                <div class="d-flex align-items-center justify-content-around">
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />

                    <tooltip-redirect
                        v-if="$ability.hasScope('product:Record')"
                        to="/app/products/record"
                        message="Nuevo producto"
                    >
                    </tooltip-redirect>
                </div>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="multi"
                responsive
                selectable
                outlined
                no-border-collapse
                hover
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(sku)="data">
                    <router-link
                        :to="`/app/products/detail/${data.item.id}`"
                        class="text-center font-weight-normal btn btn-primary p-1"
                    >
                        <small>
                            {{ data.item.active ? "🟢" : "⚪" }}
                            &nbsp; {{ data.item.sku || data.item.id }}
                        </small>
                    </router-link>
                </template>
                <template #cell(slug)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.slug | cut(20) }}
                    </small>
                </template>
                <template #cell(name)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.name | cut(36) }}
                    </small>
                </template>
                <template #cell(shortDescription)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.shortDescription | cut(36) }}
                    </small>
                </template>
                <template #cell(price)="data">
                    <a
                        :href="`/app/orders/product/${data.item.id}`"
                        target="_blank"
                        rel="noopener"
                        class="d-flex flex-column align-items-center"
                    >
                        <small class="text-center font-italic font-weight-bold">
                            {{ data.item.priceOffer | money }}
                        </small>
                        <small class="text-center font-italic text-muted">
                            &nbsp; {{ data.item.price | money }}
                        </small>
                    </a>
                </template>
                <template #cell(brandSlug)="data">
                    <a
                        :href="`/app/brands/detail/${data.item.brandSlug}`"
                        target="_blank"
                        rel="noopener"
                        class="d-flex flex-column align-items-center text-base"
                    >
                        <small class="text-center">
                            {{ data.item.brandSlug }}
                        </small>
                    </a>
                </template>
                <template #cell(lineSlug)="data">
                    <a
                        :href="`/app/lines/detail/${data.item.lineSlug}`"
                        target="_blank"
                        rel="noopener"
                        class="d-flex flex-column align-items-center text-base"
                    >
                        <small class="text-center">
                            {{ data.item.lineSlug }}
                        </small>
                    </a>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>

        <vs-dialog
            v-model="isOpenModalErrors"
            scroll
            overflow-hidden
            auto-width
        >
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex justify-content-center align-items-center flex-column flex-lg-row"
                >
                    <span class="h4"> Error en operación </span>
                </div>
            </template>
            <div class="con-content">
                Errores encontrados
                <error-viewer
                    v-for="(errorItem, index) in errors"
                    :key="index"
                    :error="errorItem"
                    :base="errorsKeys"
                />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ProductsFilters from "@/components/products/ProductsFilters.vue";
import ErrorViewer from "@/components/utils/ErrorViewer.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "ProductsIndex",
    components: {
        ProductsFilters,
        TooltipRedirect,
        TooltipButton,
        ErrorViewer
    },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        query: {},
        itemsCurrent: [],
        $fields: [
            { key: "sku", label: "Sku", sortable: false },
            { key: "name", label: "Nombre", sortable: true },
            { key: "slug", label: "Slug", sortable: true },
            {
                key: "shortDescription",
                label: "Descripción",
                sortable: false
            },
            { key: "price", label: "Precio", sortable: true },
            { key: "brandSlug", label: "Marca", sortable: true },
            { key: "lineSlug", label: "Línea", sortable: true },
            { key: "updatedAt", label: "📆 Actualización", sortable: true }
        ],
        $enabledFilters: ["brand", "line", "keyword", "active", "barcode"],
        $defaultFilters: ["keyword"],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        selected: [],
        errors: [],
        errorsKeys: ["id"],
        isOpenModalErrors: false
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapState("products", ["products"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("product:List", "/app"))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("products", [
            "listProductsPaginated",
            "publishMassiveProducts"
        ]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const { items, pagination } = await this.listProductsPaginated({
                    ...this.query,
                    complete,
                    page: this.currentPage
                });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        async activeMassiveProductsComponent() {
            const dialogResult = await this.$swal.fire({
                title: `Vas a alternal la publicación de ${
                    this.selected.length
                } producto${this.selected.length > 1 ? "s" : ""} ?`,
                background: this.backgroundColor,
                icon: "info",
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Si",
                cancelButtonText: "No",
                confirmButtonColor: "#02913f",
                cancelButtonColor: "#f07325",
                allowOutsideClick: false
            });
            if (!dialogResult.isConfirmed) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const { errors, pass, productsUpdated } =
                    await this.publishMassiveProducts({
                        products: this.selected.map((item, index) => ({
                            item: { id: item.id },
                            index
                        }))
                    });

                if (!pass) {
                    this.isOpenModalErrors = true;
                    this.errors = errors;
                    return;
                }
                let itemsCurrent = [...this.itemsCurrent];
                productsUpdated.forEach((item) => {
                    const index = itemsCurrent.findIndex(
                        (item2) => item2.id == item.id
                    );
                    if (index !== -1) {
                        itemsCurrent[index] = {
                            ...itemsCurrent[index],
                            ...item
                        };
                    }
                });
                this.itemsCurrent = [...itemsCurrent];
                this.selected = [];
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                this.currentStep = 3;
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        onRowSelected(items) {
            this.selected = items;
        },
        cloneSelected() {
            window
                .open(`/app/products/clone/${this.selected[0].id}`, "_blank")
                .focus();
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        }
    }
};
</script>
