import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";
import { applyFormatOrder } from "@/common/lib/transformers";

const actions = {
    async createCustomer(
        { commit, dispatch },
        {
            email,
            firstName,
            identificationNumber,
            identificationType,
            lastName,
            location,
            needAddress,
            phone
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/customers", {
                phone,
                email,
                lastName,
                firstName,
                identificationType,
                identificationNumber,
                needAddress,
                ...{
                    ...(needAddress
                        ? {
                              location
                          }
                        : null)
                }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CUSTOMERS", parseError);
            throw parseError;
        }
    },
    async listCustomerOrdersPaginated(
        { commit, dispatch },
        { complete, customerId, page }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/customers/${customerId}/orders`,
                {
                    params: { page, complete }
                }
            );
            const items = data.items.map(applyFormatOrder);
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CUSTOMERS", parseError);
            throw parseError;
        }
    },
    async listCustomersPaginated(
        { commit, dispatch },
        { complete, email, identificationNumber, page }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/customers", {
                params: { complete, email, identificationNumber, page }
            });
            const items = data.items;
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CUSTOMERS", parseError);
            throw parseError;
        }
    },

    async listCustomerLocations(
        { commit, dispatch },
        { complete, customerId, page }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/customers/${customerId}/locations`,
                {
                    params: { complete, page }
                }
            );
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CUSTOMERS", parseError);
            throw parseError;
        }
    },

    async getCustomerById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/customers/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CUSTOMERS", parseError);
            throw parseError;
        }
    },

    async updateCustomer(
        { commit, dispatch },
        {
            active,
            checked,
            firstName,
            id,
            identificationNumber,
            identificationType,
            lastName,
            phone
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/customers", {
                id,
                phone,
                active,
                checked,
                lastName,
                firstName,
                identificationType,
                identificationNumber
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CUSTOMERS", parseError);
            throw parseError;
        }
    },

    async searchCustomers(
        { commit, dispatch },
        { email, identificationNumber }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/customers/search", {
                params: {
                    email,
                    identificationNumber
                }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CUSTOMERS", parseError);
            throw parseError;
        }
    }
};

export default actions;
