<template>
    <div>
        <div class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> {{ label }} </label>
            </div>

            <div class="d-flex flex-wrap px-2 align-items-center">
                <div
                    v-for="(item, index) in keywords"
                    :key="index"
                    class="bg-primary rounded mx-1 px-1 d-flex align-items-center mt-1"
                    :label="item"
                    :value="item"
                >
                    <small>{{ item }}</small>
                    <button
                        v-if="enabled"
                        class="bg-primary rounded btn ml-1 p-1/2"
                        @click="deleteKeyword(index)"
                    >
                        <small> ❌ </small>
                    </button>
                </div>
                <div v-if="keywords.length === 0" class="">Sin {{ label }}</div>
                <div v-if="enabled">
                    <vs-tooltip up border>
                        <template #tooltip> Agregar palabras </template>
                        <button
                            class="btn bg-success rounded mx-1 px-1 mt-1"
                            @click="isOpenInput = true"
                        >
                            <small>➕</small>
                        </button>
                    </vs-tooltip>
                </div>
            </div>
        </div>
        <vs-dialog v-model="isOpenInput" overflow-hidden auto-width>
            <div class="d-flex py-3 mt-3">
                <vs-input
                    v-model="keyword"
                    border
                    shadow
                    class="w-100 mw-100"
                    :label="`Nueva ${label} (Separa por ',')`"
                    :state="stateInputDark"
                    @keyup.enter="addNewKeyword"
                />
                <vs-button icon border @click="addNewKeyword"> ✔️ </vs-button>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "KeywordsManage",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        label: {
            type: String,
            default: () => "",
            required: true
        },
        enabled: {
            default: () => false,
            type: Boolean,
            required: false
        },
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    emits: ["change"],
    data: () => ({
        keywords: [],
        isOpenInput: false,
        keyword: ""
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },

    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        setInput(value) {
            this.keywords = [...value].filter(
                (value, index, self) =>
                    self.findIndex((item) => item === value) === index
            );
        },
        deleteKeyword(index) {
            this.keywords.splice(index, 1);
        },
        addNewKeyword() {
            if (this.keyword) {
                const value = [
                    ...this.keyword.split(", "),
                    ...this.keywords
                ].filter(
                    (value, index, self) =>
                        self.findIndex((item) => item === value) === index
                );
                this.keywords = value;
                this.keyword = "";
                this.onInput();
            }
            this.isOpenInput = false;
        },
        onInput() {
            this.$emit("change", this.keywords);
        }
    }
};
</script>
