<template>
    <div>
        <div class="d-flex flex-column">
            <div class="d-flex flex-column flex-md-row">
                <div
                    id="title-general"
                    class="col-12 d-flex flex-column flex-md-row justify-content-between align-items-center"
                >
                    <h1 v-if="slug" class="h2">
                        &nbsp;Detalle de configuración logística
                        <strong class="text-primary"> #{{ slug }} </strong>
                    </h1>

                    <h1 v-else>&nbsp;Nueva configuración logística</h1>
                </div>
            </div>
        </div>

        <div class="my-3 d-flex flex-wrap">
            <vs-navbar
                id="tabs-order"
                v-model="activeTab"
                center-collapsed
                not-line
                class="position-static"
            >
                <template #left>
                    <vs-navbar-item id="home">
                        <router-link
                            to="/app/logistic-configurations"
                            class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                        >
                            <span>🏠</span>
                        </router-link>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="general"
                        :active="['general', 'home'].includes(activeTab)"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> General </span>
                            <span>&nbsp;⚙</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="slug"
                        id="simulate"
                        :active="activeTab === 'simulate'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> Simulación </span>
                            <span>&nbsp;🔬</span>
                        </div>
                    </vs-navbar-item>
                </template>
                <template #right>
                    <div class="d-none d-md-block">
                        <vs-switch
                            v-if="
                                $ability.hasScope(
                                    'logisticConfiguration:Update'
                                ) && slug
                            "
                            v-model="enabledUpdate"
                            :class="
                                enabledUpdate ? 'bg-primary' : 'bg-secondary'
                            "
                        >
                            🖊
                        </vs-switch>
                    </div>
                </template>
            </vs-navbar>
        </div>

        <div
            v-if="$ability.hasScope('logisticConfiguration:Update') && slug"
            class="d-flex justify-content-center"
        >
            <div class="d-block d-md-none my-3">
                <vs-switch
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>

        <div v-show="['general', 'home'].includes(activeTab)">
            <div class="d-flex flex-wrap">
                <span class="mt-3">
                    La configuración logística determina los tiempos de entrega
                    de una ordén dependiendo de los productos que tenga dicha
                    orden.
                </span>
                <div
                    v-if="slug"
                    class="col-12 mt-3 p-0 d-flex justify-content-around"
                >
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ itemComponent.updatedAt | date }}
                    </small>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.name"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Nombre"
                        class="opacity-false"
                        aria-label="Nombre de configuración lógistica"
                        :disabled="!enabledUpdate"
                        @blur="blurName"
                    >
                        <template
                            v-if="!isValidName & introValidators.name"
                            #message-danger
                        >
                            Nombre de configuración logística invalida
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.slug"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Slug"
                        class="opacity-false"
                        aria-label="Slug de configuración lógistica"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.slug = true"
                    >
                        <template
                            v-if="!isValidSlug & introValidators.slug"
                            #message-danger
                        >
                            Slug de configuración logística invalida
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 mt-5 d-flex flex-column">
                    <h4>Tiempo estimado de preparación</h4>
                    <prepare-time-manage
                        v-model="itemComponent.prepareTime"
                        :enabled="enabledUpdate"
                    />
                </div>
                <div class="col-12 mt-5 d-flex flex-column">
                    <h4>
                        Tiempo estimado de preparación cuando el inventario
                        siempre esta activo
                    </h4>
                    <prepare-time-manage
                        v-model="itemComponent.alwaysStockPrepareTime"
                        :enabled="enabledUpdate"
                    />
                </div>
                <div class="col-12 mt-5 d-flex flex-column">
                    <h4>
                        Configuraciones tiempos y costos de entrega por ciudad
                    </h4>
                    <delivery-configurations-by-city-manage
                        v-model="itemComponent.deliveryConfigurationsByCityCode"
                        :enabled="enabledUpdate"
                    />
                </div>
                <div
                    v-if="enabledUpdate"
                    class="col-12 mt-5 d-flex justify-content-center"
                >
                    <vs-button
                        v-if="!slug"
                        @click="createLogisticConfigurationComponent"
                    >
                        Crear configuración logística
                    </vs-button>
                    <vs-button
                        v-else
                        @click="updateLogisticConfigurationComponent"
                    >
                        Actualizar configuración logística
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'simulate'">
            <div class="d-flex flex-wrap">
                <span class="mt-3">
                    Para simular una configuración logística precisa,
                    simplemente proporciona la ciudad de destino, la cantidad de
                    unidades requeridas y el valor de referencia del producto.
                    Con esta información, nuestro sistema calculará el tiempo
                    necesario para preparar el pedido y te mostrará los días de
                    entrega disponibles.
                </span>
                <div class="col-12 col-md-6 mt-5">
                    <strong>Fecha de pedido </strong>

                    <b-form-datepicker
                        v-model="simulateInput.date"
                        locale="es"
                        size="sm"
                        :dark="activeDark"
                    ></b-form-datepicker>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <city-code-selector
                        v-model="simulateInput.cityCode"
                        label="Ciudad de entrega"
                        class=""
                    />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <input-money-component
                        v-model="simulateInput.referencePriceOffer"
                        border
                        label="Precio de referencias"
                        class="opacity-false"
                        icon="💲"
                    />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="simulateInput.referenceQuantity"
                        border
                        shadow
                        label="Unidades"
                        :state="stateInputDark"
                        type="number"
                        min="1"
                        class="opacity-false"
                    />
                </div>
                <div class="col-12 mt-5 d-flex justify-content-center">
                    <vs-button
                        size="large"
                        :loading="isLoadingSimulate"
                        @click="simulateComponent"
                    >
                        Simular
                    </vs-button>
                </div>
                <div v-if="showSimulation" class="col-12 mt-4">
                    <div class="mt-3">
                        <span class="h3">
                            Precio de entrega
                            <span class="text-primary">
                                {{ simulationResult.deliveryPrice | currency }}
                            </span>
                        </span>
                    </div>

                    <div class="mt-3">
                        <span class="h3">
                            Fecha en que esta listo el producto para despacho
                        </span>
                        <date-delivery-viewer
                            class="mt-2"
                            :input="simulationResult.packageDate"
                        />
                    </div>
                    <div class="mt-3">
                        <span class="h3"> Fechas de entrega </span>
                        <div class="d-flex flex-wrap">
                            <date-delivery-viewer
                                v-for="(
                                    item, i
                                ) in simulationResult.deliveryDates"
                                :key="i"
                                :input="item"
                                class="mr-3 mt-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapState } from "vuex";

import { slugRegex, titleRegex } from "@/common/lib/regex";
import { slugify } from "@/common/lib/transformers";

import CityCodeSelector from "@/components/utils/CityCodeSelector.vue";
import DateDeliveryViewer from "@/components/utils/DateDeliveryViewer.vue";
import DeliveryConfigurationsByCityManage from "@/components/utils/DeliveryConfigurationsByCityManage.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";
import PrepareTimeManage from "@/components/utils/PrepareTimeManage.vue";

export default {
    name: "SupplyChainDetails",
    components: {
        CityCodeSelector,
        PrepareTimeManage,
        DeliveryConfigurationsByCityManage,
        DateDeliveryViewer,
        InputMoneyComponent
    },
    data: () => ({
        slug: "",
        activeTab: "general",
        loadItem: false,
        isNew: true,
        isLoading: false,
        isLoadingSimulate: false,
        enabledUpdate: false,
        loaderInstance: null,
        introValidators: { slug: false, name: false },
        itemComponent: {
            slug: "",
            name: "",
            createdAt: "",
            updatedAt: "",
            prepareTime: { minutes: 0, hours: 1, days: 0, months: 0 },
            alwaysStockPrepareTime: {
                minutes: 0,
                hours: 1,
                days: 0,
                months: 0
            },
            deliveryConfigurationsByCityCode: [
                {
                    cityCode: "default",
                    configuration: [
                        { deliveryHours: 1, deliveryPrice: 1000, min: 1 }
                    ]
                }
            ]
        },
        simulateInput: {
            cityCode: "co",
            date: DateTime.now().toFormat("yyyy-MM-dd"),
            referenceQuantity: 1,
            referencePriceOffer: 1000
        },
        showSimulation: false,
        simulationResult: {
            deliveryPrice: 0,
            packageDate: {
                date: "",
                hour: "",
                format: ""
            },
            deliveryDates: []
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        ...mapState("control", ["activeDark"]),
        isValidName() {
            return titleRegex.test(this.itemComponent.name);
        },
        isValidSlug() {
            return slugRegex.test(this.itemComponent.slug);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("slug" in this.$route.params) {
            const slug = this.$route.params.slug;
            this.slug = slug;
            this.isNew = false;
            await this.getBaseItem(slug);
        } else if ("slugClone" in this.$route.params) {
            if (
                !(await verifyPageScope(
                    "logisticConfiguration:Create",
                    "/app/logistic-configurations"
                ))
            )
                return;
            this.enabledUpdate = true;
            const slugTmp = this.$route.params.slugClone;
            this.slug = `${slugTmp}-clone`;
            await this.getBaseItem(slugTmp);
            this.itemComponent = {
                ...this.itemComponent,
                name: `${this.itemComponent.name} CLONE`,
                slug: this.slug,
                active: false
            };
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando una configuración logística",
                icon: "info",
                showCloseButton: true
            });
        } else {
            if (
                !(await verifyPageScope(
                    "logisticConfiguration:Create",
                    "/app/logistic-configurations"
                ))
            )
                return;
            this.enabledUpdate = true;
            this.loadItem = true;
        }
    },
    methods: {
        ...mapActions("logisticConfigurations", [
            "getLogisticConfigurationBySlug",
            "updateLogisticConfiguration",
            "createLogisticConfiguration",
            "simulateLogisticConfiguration"
        ]),
        async getBaseItem(slug) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                this.itemComponent = await this.getLogisticConfigurationBySlug({
                    slug
                });
                this.loadItem = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/logistic-configurations");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async simulateComponent() {
            this.isLoadingSimulate = true;
            this.showSimulation = false;
            try {
                const simulationResult =
                    await this.simulateLogisticConfiguration({
                        slug: this.slug,
                        ...this.simulateInput
                    });
                this.simulationResult = simulationResult;
                this.showSimulation = true;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingSimulate = false;
                this.loaderInstance.close();
            }
        },
        async updateLogisticConfigurationComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateLogisticConfiguration(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createLogisticConfigurationComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const data = await this.createLogisticConfiguration(
                    this.getDataSend()
                );
                this.loaderInstance.close();
                this.itemComponent = { ...data, ...this.itemComponent };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Slug: ${data.slug}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de configuraciones logísticas",
                    cancelButtonText: "Nueva configuración logística"
                });
                if (dialogResult.isConfirmed) {
                    this.slug = data.slug;
                    this.itemComponent = { ...this.itemComponent, ...data };
                    this.isNew = false;
                    this.$router.push(
                        `/app/logistic-configurations/detail/${this.slug}`
                    );
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/logistic-configurations");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        slug: false,
                        name: false,
                        shortDescription: false
                    };
                    this.itemComponent = {
                        slug: "",
                        name: "",
                        createdAt: "",
                        updatedAt: "",
                        coordinates: {
                            latitude: 4.63082,
                            longitude: -74.15035
                        },
                        prepareTime: {
                            minutes: 0,
                            hours: 1,
                            days: 0,
                            months: 0
                        },
                        alwaysStockPrepareTime: {
                            minutes: 0,
                            hours: 1,
                            days: 0,
                            months: 0
                        },
                        deliveryConfigurationsByCityCode: {
                            cityCode: "11001",
                            configuration: {
                                1: { deliveryHours: 0, deliveryPrice: 0 }
                            }
                        }
                    };
                }
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async setSlug() {
            if (this.enabledUpdate && this.isValidName && this.isNew) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Quieres cambiar el slug ?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33ddd",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                });
                if (dialogResult.isConfirmed) {
                    this.itemComponent.slug = slugify(this.itemComponent.name);
                }
            }
        },
        blurName() {
            this.introValidators.name = true;
            this.setSlug();
        },
        validateData() {
            this.introValidators = {
                slug: true,
                name: true
            };
            const toComplete = [];
            if (!this.isValidSlug) {
                toComplete.push("Slug");
            }

            if (!this.isValidName) {
                toComplete.push("Nombre");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Configuración logística invalida",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        getDataSend() {
            return {
                ...this.itemComponent
            };
        },
        deleteLogisticConfiguration() {
            alert("// TODO");
        }
    }
};
</script>
