<template>
    <div class="w-100">
        <span> Tipo de opción extra </span>
        <vs-select
            v-if="enabled"
            v-model="option"
            class="w-100 mw-100 border-custom"
            :state="stateInputDark"
            @input="onInput"
        >
            <vs-option
                v-for="(value, key) in $data.$enabledOptions"
                :key="key"
                :label="value"
                :value="key"
            >
                {{ value }}
            </vs-option>
        </vs-select>
        <div v-else>
            <span> Tipo de opción extra </span>
            <vs-input
                v-model="label"
                border
                shadow
                class="w-100 opacity-false"
                disabled
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { productExtraOptionTypesEnum } from "@/common/fields/product-extra-option-types";

export default {
    name: "ProductExtraOptionType",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: String,
            default: () => "",
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "",
        label: "",
        $enabledOptions: productExtraOptionTypesEnum
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onInput(key) {
            this.$emit("change", key);
        },
        setInput(key) {
            this.option = key;
            this.label = key ? productExtraOptionTypesEnum[key] : "";
        }
    }
};
</script>
