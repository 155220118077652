<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
                <h1 v-if="slug" class="h2">
                    &nbsp;Detalle de línea
                    <strong class="text-primary"> #{{ slug }} </strong>
                </h1>
                <h1 v-else>&nbsp;Registrar línea</h1>
            </div>
            <span class="mt-3">
                Las líneas de productos facilitan la clasificación y agrupación
                eficiente de artículos similares, permitiendo filtrar el
                inventario de manera práctica.
            </span>
        </div>

        <div class="my-4">
            <vs-navbar
                id="tabs-sublines"
                v-model="activeTab"
                center-collapsed
                not-line
                class="position-static"
            >
                <template #left>
                    <vs-navbar-item id="home">
                        <router-link
                            class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                            to="/app/lines"
                        >
                            <span>🏠</span>
                        </router-link>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="general"
                        :active="['general', 'home'].includes(activeTab)"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> General </span>
                            <span>&nbsp;⚙️</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="
                            slug &&
                            $ability.hasScope('line:Get') &&
                            $enabledEcommerceOptions
                        "
                        id="filters"
                        :active="activeTab === 'filters'"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> Filtros </span>
                            <span>&nbsp;🔍</span>
                        </div>
                    </vs-navbar-item>

                    <vs-navbar-item
                        v-if="
                            slug &&
                            $ability.hasScope('line:Get') &&
                            $enabledEcommerceOptions
                        "
                        id="seo"
                        :active="activeTab === 'seo'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> SEO&nbsp; </span>
                            <span>&nbsp;🐱‍💻</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="
                            slug &&
                            $ability.hasScope('line:Get') &&
                            $enabledEcommerceOptions
                        "
                        id="categories"
                        :active="activeTab === 'categories'"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Categorías y marcas
                            </span>
                            <span>&nbsp;🏷️</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="slug && $ability.hasScope('line:Get')"
                        id="advance"
                        :active="activeTab === 'advance'"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> Avanzado </span>
                            <span>&nbsp;🤖</span>
                        </div>
                    </vs-navbar-item>
                </template>
                <template #right>
                    <div class="d-none d-md-block">
                        <vs-switch
                            v-if="$ability.hasScope('line:Update') && slug"
                            v-model="enabledUpdate"
                            :class="
                                enabledUpdate ? 'bg-primary' : 'bg-secondary'
                            "
                        >
                            🖊
                        </vs-switch>
                    </div>
                </template>
            </vs-navbar>
        </div>

        <div
            v-if="
                $ability.hasScope('line:Update') &&
                slug &&
                $enabledEcommerceOptions
            "
            class="d-flex justify-content-center"
        >
            <div class="d-block d-md-none my-3">
                <vs-switch
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>

        <div v-show="['general', 'home'].includes(activeTab)" id="generalTab">
            <div v-if="slug" class="d-flex flex-wrap">
                <div
                    v-if="$enabledEcommerceOptions"
                    class="col-12 col-md-6 mt-3 d-flex justify-content-around align-items-center"
                >
                    <active-selector
                        v-model="itemComponent.active"
                        :enabled="enabledUpdate"
                        on-label="Publicado"
                        off-label="Oculto"
                    />
                    <active-selector
                        v-if="itemComponent.active"
                        v-model="itemComponent.highlight"
                        :enabled="enabledUpdate"
                        on-label="Destacado"
                        off-label="Común"
                    />
                </div>
                <div
                    class="col-12 col-md-6 mt-3 p-0 d-flex justify-content-around"
                >
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ itemComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.name"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Nombre"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="blurName"
                    >
                        <template
                            v-if="!isValidName & introValidators.name"
                            #message-danger
                        >
                            Nombre de línea invalida
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.slug"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Slug"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.slug = true"
                    >
                        <template
                            v-if="!isValidSlug & introValidators.slug"
                            #message-danger
                        >
                            Slug de categoría invalida
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 mt-5">
                    <vs-input
                        v-model="itemComponent.shortDescription"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Descripción"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.shortDescription = true"
                    >
                        <template
                            v-if="
                                !isValidShortDescription &
                                introValidators.shortDescription
                            "
                            #message-danger
                        >
                            Descripción de categoría invalida
                        </template>
                    </vs-input>
                </div>
                <fragment v-if="$enabledEcommerceOptions">
                    <div class="col-12 mx-auto px-3 mt-5">
                        <label for="description">Contenido</label>
                        <markdown-editor
                            v-model="itemComponent.description"
                            class="mt-2"
                            :enabled="enabledUpdate"
                        />
                    </div>
                    <div class="col-12 col-md-6 mt-5">
                        <keywords-selector
                            v-if="loadItem || !slug"
                            v-model="itemComponent.keywords"
                            label="Palabras clave"
                            :enabled="enabledUpdate"
                        />
                    </div>
                    <div class="col-12 mt-5">
                        <images-manage
                            :id="slug"
                            v-model="images"
                            type="línea"
                            :enabled="enabledUpdate"
                        />
                    </div>
                </fragment>

                <div
                    v-if="enabledUpdate"
                    class="col-12 mt-5 d-flex justify-content-center"
                >
                    <vs-button v-if="!slug" @click="createLineComponent">
                        Crear línea
                    </vs-button>
                    <vs-button v-else @click="updateLineComponent">
                        Actualizar línea
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'filters'" id="filtersTab">
            <div id="filters-container">
                <div id="filters-title" class="d-flex flex-wrap">
                    <div class="d-flex flex-column">
                        <h2>Filtros</h2>
                        <span> Configura los filtros de la sublínea </span>
                    </div>
                </div>
                <div class="d-flex flex-wrap">
                    <filters-manage
                        v-model="itemComponent.filters"
                        :enabled="enabledUpdate"
                    >
                        <template slot="action">
                            <vs-button
                                :loading="isLoadingFilters"
                                class="mt-4"
                                @click="updateLineFiltersComponent()"
                            >
                                Actualizar Filtros
                            </vs-button>
                        </template>
                    </filters-manage>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'seo'" id="seoTab">
            <seo-analytic
                :meta-description="itemComponent.shortDescription"
                :url="`/lines/${itemComponent.slug}`"
                :title="itemComponent.name"
                :keywords="itemComponent.keywords"
                :description="`# ${itemComponent.name} 
                ${itemComponent.description}`"
            />
        </div>

        <div v-if="activeTab === 'advance'" id="advanceTab">
            <div id="title-routes" class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h2>Avanzado</h2>
                    <span>
                        Puedes eliminar la línea aqui, ten mucho cuidado.
                    </span>
                </div>
            </div>
            <div class="d-flex justify-content-around mt-5">
                <vs-button
                    v-if="$ability.hasScope('line:Delete') && slug"
                    danger
                    @click="deleteLineComponent"
                >
                    Eliminación
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
    genericStringRegex,
    shortDescriptionRegex,
    slugRegex,
    titleRegex
} from "@/common/lib/regex";
import { slugify } from "@/common/lib/transformers";

import ActiveSelector from "@/components/utils/ActiveSelector.vue";
import FiltersManage from "@/components/utils/FiltersManage.vue";
import ImagesManage from "@/components/utils/ImagesManage.vue";
import KeywordsSelector from "@/components/utils/KeywordsSelector.vue";
import MarkdownEditor from "@/components/utils/MarkdownEditor.vue";
import SeoAnalytic from "@/components/utils/SeoAnalytic.vue";

export default {
    name: "CreateUpdateLine",
    components: {
        ImagesManage,
        KeywordsSelector,
        FiltersManage,
        MarkdownEditor,
        SeoAnalytic,
        ActiveSelector
    },
    data: () => ({
        slug: "",
        images: [],
        loadItem: false,
        isLoading: false,
        enabledUpdate: false,
        activeTab: "general",
        loaderInstance: null,
        introValidators: { slug: false, name: false, shortDescription: false },
        isLoadingFilters: false,
        itemComponent: {
            name: "",
            description: "",
            slug: "",
            shortDescription: "",
            keywords: [],
            images: [],
            filters: {},
            updatedAt: "",
            createdAt: "",
            highlight: false,
            highlightType: null,
            active: false
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidName() {
            return titleRegex.test(this.itemComponent.name);
        },
        isValidShortDescription() {
            return shortDescriptionRegex.test(
                this.itemComponent.shortDescription
            );
        },
        isValidSlug() {
            return slugRegex.test(this.itemComponent.slug);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("slug" in this.$route.params) {
            const slug = this.$route.params.slug;
            this.slug = slug;
            await this.getBaseItem(slug);
        } else if ("slugClone" in this.$route.params) {
            if (!(await verifyPageScope("line:Create", "/app/lines"))) return;
            this.enabledUpdate = true;
            const slugTmp = this.$route.params.slugClone;
            this.slug = `${slugTmp}-clone`;
            await this.getBaseItem(slugTmp);
            this.itemComponent = {
                ...this.itemComponent,
                name: `${this.itemComponent.name} CLONE`,
                slug: this.slug,
                active: false,
                highlight: false,
                highlightType: null
            };
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando una línea",
                icon: "info",
                showCloseButton: true
            });
        } else {
            if (!(await verifyPageScope("line:Create", "/app/lines"))) return;
            this.enabledUpdate = true;
            this.loadItem = true;
        }
    },
    methods: {
        ...mapActions("lines", [
            "getLineBySlug",
            "updateLine",
            "createLine",
            "deleteLine",
            "updateLineFilters"
        ]),
        async getBaseItem(slug) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const info = await this.getLineBySlug({ slug });
                this.itemComponent = {
                    ...info,
                    highlight: info.highlightType === "highlight"
                };
                this.images = this.itemComponent.images;
                this.loadItem = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/lines");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateLineFiltersComponent() {
            this.isLoadingBrands = true;
            try {
                await this.updateLineFilters({
                    lineSlug: this.itemComponent.slug,
                    filters: this.itemComponent.filters
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.isLoadingBrands = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingBrands = false;
            }
        },
        async updateLineComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateLine(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createLineComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const data = await this.createLine(this.getDataSend());
                this.loaderInstance.close();
                this.itemComponent = { ...data, ...this.itemComponent };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Slug: ${data.slug}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de líneas",
                    cancelButtonText: "Registrar línea"
                });
                if (dialogResult.isConfirmed) {
                    this.slug = data.slug;
                    this.itemComponent = { ...data, ...this.itemComponent };
                    this.$router.push(`/app/lines/detail/${this.slug}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/lines/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        slug: false,
                        name: false,
                        shortDescription: false
                    };
                    this.itemComponent = {
                        name: "",
                        description: "",
                        slug: "",
                        shortDescription: "",
                        keywords: [],
                        images: [],
                        active: false,
                        highlight: false,
                        highlightType: null
                    };
                    this.images = [];
                }
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async setSlug() {
            if (
                this.enabledUpdate &&
                this.isValidName &&
                !this.itemComponent.active
            ) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Quieres cambiar el slug ?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33ddd",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                });
                if (dialogResult.isConfirmed) {
                    this.itemComponent.slug = slugify(this.itemComponent.name);
                }
            }
        },
        blurName() {
            this.introValidators.name = true;
            this.setSlug();
        },
        validateData() {
            const toComplete = [];

            if (!this.isValidSlug) {
                toComplete.push("Slug");
            }

            if (!this.isValidShortDescription) {
                toComplete.push("Descripción");
            }

            if (
                !genericStringRegex.test(this.itemComponent.description) &&
                this.$enabledEcommerceOptions
            ) {
                toComplete.push("Contenido");
            }

            if (!this.isValidName) {
                toComplete.push("Nombre");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Línea invalida",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }

            return true;
        },
        getDataSend() {
            return {
                ...this.itemComponent,
                highlightType: this.itemComponent.highlight
                    ? "highlight"
                    : null,
                images: this.images.map((item) => ({
                    id: item.id,
                    sequence: item.sequence.sequence
                }))
            };
        },
        async deleteLineComponent() {
            if (this.itemComponent.active) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Línea esta activa",
                    text: "Desactiva primero la línea antes de eliminar el producto",
                    icon: "info"
                });
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.deleteLine({
                    slug: this.itemComponent.slug
                });
                this.$router.push("/app/lines");
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        }
    }
};
</script>
