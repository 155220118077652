<template>
    <div class="mx-3">
        <div class="d-flex justify-content-between">
            <strong>Selecciona tipo de filtro</strong>
            <vs-button circle icon floating @click="redirectToCreating">
                ➕
            </vs-button>
        </div>
        <div class="w-100 mt-3 d-flex justify-content-around">
            <vs-radio
                v-for="(value, key) in $data.$filtersTypes"
                :key="key"
                v-model="filterType"
                :val="key"
            >
                {{ value }}
            </vs-radio>
        </div>

        <div class="mt-5 mb-2">
            <span v-show="errorString" class="text-danger">
                {{ errorString }}
            </span>
            <div v-if="filterType === 'email'" class="mt-5 d-flex">
                <vs-input
                    id="email"
                    v-model="email"
                    type="text"
                    name="email"
                    label="Correo"
                    class="input-theme w-100 mr-2"
                    :class="!isValidEmail ? '' : 'border-custom'"
                    aria-label="Email"
                    icon-after
                    state="primary"
                    :disabled="isLoading"
                    @focus="onFocus"
                    @blur="onBlurEmail"
                    @input="lowerCaseEmail"
                    @keyup.enter="onClickEmail"
                >
                    <template #icon> ✉ </template>
                    <template #message-danger>
                        <div v-show="introEmail && !isValidEmail">
                            Ingrese correo valido
                        </div>
                    </template>
                </vs-input>
                <vs-button
                    circle
                    icon
                    :loading="isLoading"
                    @click="onClickEmail"
                >
                    🔎
                </vs-button>
            </div>
            <div
                v-if="filterType === 'identificationNumber'"
                class="mt-5 d-flex"
            >
                <vs-input
                    id="document"
                    v-model="identificationNumber"
                    type="text"
                    name="document"
                    state="primary"
                    label="Número de documento"
                    class="input-theme w-100 mr-2"
                    aria-label="Document"
                    icon-after
                    :disabled="isLoading"
                    @blur="onBlurIdentificationNumber"
                    @focus="onFocus"
                    @keyup.enter="onClickIdentification"
                    @input="isLoaded = false"
                >
                    <template #icon> 🖋 </template>
                    <template #message-danger>
                        <div
                            v-show="
                                introIdentificationNumber &&
                                !isValidIdentificationNumber
                            "
                        >
                            Ingrese documento Valido
                        </div>
                    </template>
                </vs-input>
                <vs-button
                    circle
                    icon
                    :loading="isLoading"
                    @click="onClickIdentification"
                >
                    🔎
                </vs-button>
            </div>
            <div v-if="filterType === 'new'">
                <span>
                    Crea el usuario haciendo click en el siguiente botón. NO se
                    perderá el registro, te abrirá una nueva pestaña crea el
                    usuario y regresa a esta pestaña para continuar con el
                    registro.
                </span>
                <div class="mt-2 d-flex justify-content-center">
                    <vs-button @click="redirectToCreating">
                        Redigir a página de creación
                    </vs-button>
                </div>
            </div>
        </div>
        <div v-if="isLoaded">
            <span class="mb-2">Resultados de búsqueda {{ users.length }}</span>

            <user-table-items
                v-for="item in users"
                :key="item.id"
                :user="item"
                :slug="
                    isCustomer
                        ? `/app/customers/detail/${item.id}`
                        : `/app/users/detail/${item.id}`
                "
                @click="selectUser"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

import {
    emailSimpleRegex,
    identificationNumberRegex
} from "@/common/lib/regex";

import UserTableItems from "./UserTableItems.vue";

export default {
    name: "SearchUser",
    components: { UserTableItems },
    model: {
        event: "input",
        prop: "itemCurrent"
    },
    props: {
        isCustomer: {
            type: Boolean,
            default: () => false, // default customer
            required: false
        },
        itemCurrent: {
            type: Object,
            default: () => ({
                email: "",
                firstName: "",
                id: 4,
                identificationNumber: "",
                identificationType: "cc",
                lastName: "",
                phone: "",
                updatedAt: "2022-03-28T01:01:34.813Z"
            }),
            required: false
        }
    },
    emits: ["input", "blur", "focus"],
    data: () => ({
        $filtersTypes: {
            identificationNumber: "Identificación",
            email: "Correo"
        },
        filterType: "identificationNumber",
        email: "",
        identificationNumber: "",
        errorString: "",
        firstName: "",
        lastName: "",
        isValidEmail: false,
        introEmail: false,
        introIdentificationNumber: false,
        introFirstName: false,
        introLastName: false,
        isLoading: false,
        isLoaded: false,
        users: []
    }),
    computed: {
        isValidIdentificationNumber() {
            return identificationNumberRegex.test(this.identificationNumber);
        }
    },
    watch: {
        filterType() {
            this.reset();
        }
    },
    methods: {
        ...mapActions("users", ["searchUsers"]),
        ...mapActions("customers", ["searchCustomers"]),
        lowerCaseEmail() {
            this.isLoaded = false;
            this.isValidEmail = emailSimpleRegex.test(this.email);
            this.email = this.email.toLowerCase();
        },
        onClickEmail() {
            this.listItems({ email: this.email });
        },
        onClickIdentification() {
            this.listItems({
                identificationNumber: this.identificationNumber
            });
        },
        onClickNames() {
            this.listItems({
                firstName: this.firstName,
                lastName: this.lastName
            });
        },
        async listItems(query) {
            this.errorString = "";
            this.isLoading = true;
            this.isLoaded = false;
            try {
                if (this.isCustomer) {
                    this.users = await this.searchCustomers(query);
                } else {
                    this.users = await this.searchUsers(query);
                }
            } catch (error) {
                this.errorString = `Error code ${error.statusCode}`;
            } finally {
                this.isLoading = false;
                this.isLoaded = true;
            }
        },
        selectUser(user) {
            this.$emit("change", user);
            this.reset();
        },
        reset() {
            this.introEmail = false;
            this.introIdentificationNumber = false;
            this.introFirstName = false;
            this.introLastName = false;
            this.email = "";
            this.identificationNumber = "";
            this.firstName = "";
            this.lastName = "";
        },
        redirectToCreating() {
            window
                .open(
                    this.isCustomer ? "/app/customers/new" : "/app/users/new",
                    "_blank"
                )
                .focus();
        },
        onBlurEmail() {
            this.introEmail = true;
            this.$emit("blur");
        },
        onBlurIdentificationNumber() {
            this.introIdentificationNumber = true;
            this.$emit("blur");
        },
        onFocus() {
            this.$emit("focus");
        }
    }
};
</script>
