<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
                <h1 v-if="slug" class="h2">
                    &nbsp;Detalle de marca #{{ slug }} /
                    <span class="text-primary">
                        {{ name }}
                    </span>
                </h1>
                <h1 v-else class="h2">&nbsp;Registrar marca</h1>
            </div>
            <span class="mt-3">
                En esta sección, puedes registrar una marca para asociarla con
                tus productos.
            </span>
        </div>

        <div class="my-4">
            <vs-navbar
                v-model="activeTab"
                center-collapsed
                not-line
                class="position-static"
            >
                <template #left>
                    <vs-navbar-item id="home">
                        <router-link
                            class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                            to="/app/brands"
                        >
                            <span>🏠</span>
                        </router-link>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="general"
                        :active="['general', 'home'].includes(activeTab)"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> General </span>
                            <span>&nbsp;⚙️</span>
                        </div>
                    </vs-navbar-item>

                    <vs-navbar-item
                        v-if="
                            slug &&
                            $ability.hasScope('brand:Get') &&
                            $enabledEcommerceOptions
                        "
                        id="seo"
                        :active="activeTab === 'seo'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> SEO&nbsp; </span>
                            <span>&nbsp;🐱‍💻</span>
                        </div>
                    </vs-navbar-item>
                </template>
                <template #right>
                    <div class="d-none d-md-block">
                        <vs-switch
                            v-if="$ability.hasScope('brand:Update') && slug"
                            v-model="enabledUpdate"
                            :class="
                                enabledUpdate ? 'bg-primary' : 'bg-secondary'
                            "
                        >
                            🖊
                        </vs-switch>
                    </div>
                </template>
            </vs-navbar>
        </div>

        <div v-show="['general', 'home'].includes(activeTab)" id="generalTab">
            <div v-if="slug" class="d-flex flex-wrap">
                <div
                    v-if="$enabledEcommerceOptions"
                    class="col-12 col-md-6 mt-3 d-flex justify-content-center align-items-center"
                >
                    <active-selector
                        v-model="itemComponent.active"
                        :enabled="enabledUpdate"
                        on-label="Publicada"
                        off-label="Oculta"
                    />
                </div>
                <div
                    class="col-12 col-md-6 mt-3 p-0 d-flex justify-content-around"
                >
                    <small class="text-left">
                        📆💾 {{ itemComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ itemComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.name"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Nombre"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="blurName"
                    >
                        <template
                            v-if="!isValidName & introValidators.name"
                            #message-danger
                        >
                            Nombre de marca invalida
                        </template>
                    </vs-input>
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.slug"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Slug"
                        class="opacity-false"
                        @blur="introValidators.slug = true"
                    >
                        <template
                            v-if="!isValidSlug & introValidators.slug"
                            #message-danger
                        >
                            Slug de marca invalido
                        </template>
                    </vs-input>
                </div>

                <div class="col-12 mt-5">
                    <vs-input
                        v-model="itemComponent.shortDescription"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Descripción"
                        class="opacity-false"
                        :disabled="!enabledUpdate"
                        @blur="introValidators.shortDescription = true"
                    >
                        <template
                            v-if="
                                !isValidShortDescription &
                                introValidators.shortDescription
                            "
                            #message-danger
                        >
                            Descripción invalida. Debe tener mínimo 1 caracter
                            máximo 255
                        </template>
                    </vs-input>
                </div>

                <fragment v-if="$enabledEcommerceOptions">
                    <div class="col-12 mx-auto py-5 px-3">
                        <label for="description">Descripción</label>
                        <markdown-editor
                            v-model="itemComponent.description"
                            class="mt-2"
                            :enabled="enabledUpdate"
                        />
                    </div>

                    <div class="col-12 col-md-6 mt-5">
                        <keywords-selector
                            v-model="itemComponent.keywords"
                            label="Palabras clave"
                            :enabled="enabledUpdate"
                        />
                    </div>

                    <div class="col-12">
                        <images-manage
                            :id="slug"
                            v-model="images"
                            message="marca"
                            :enabled="enabledUpdate"
                        />
                    </div>
                </fragment>

                <div class="col-12 h-10 mt-5">
                    <div
                        v-if="enabledUpdate"
                        class="col-12 d-flex justify-content-center"
                    >
                        <vs-button
                            v-if="!slug"
                            size="large"
                            @click="createBrandComponent"
                        >
                            Crear marca
                        </vs-button>
                        <vs-button
                            v-else
                            size="large"
                            @click="updateBrandComponent"
                        >
                            Actualizar marca
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'seo'" id="seoTab">
            <seo-analytic
                :meta-description="itemComponent.shortDescription"
                :url="`/brands/${itemComponent.slug}`"
                :title="itemComponent.name"
                :keywords="itemComponent.keywords"
                :description="`# ${itemComponent.name} 
                ${itemComponent.description}`"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
    shortDescriptionRegex,
    slugRegex,
    titleRegex
} from "@/common/lib/regex";
import { slugify } from "@/common/lib/transformers";

import ActiveSelector from "@/components/utils/ActiveSelector.vue";
import ImagesManage from "@/components/utils/ImagesManage.vue";
import KeywordsSelector from "@/components/utils/KeywordsSelector.vue";
import MarkdownEditor from "@/components/utils/MarkdownEditor.vue";
import SeoAnalytic from "@/components/utils/SeoAnalytic.vue";

export default {
    name: "CreateUpdateBrand",
    components: {
        SeoAnalytic,
        ImagesManage,
        ActiveSelector,
        MarkdownEditor,
        KeywordsSelector
    },
    data: () => ({
        slug: "",
        name: "",
        isLoading: false,
        enabledUpdate: false,
        activeTab: "general",
        loaderInstance: null,
        images: [],
        introValidators: { slug: false, name: false, shortDescription: false },
        itemComponent: {
            name: "",
            description: "",
            slug: "",
            shortDescription: "",
            keywords: [],
            images: [],
            active: false
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidSlug() {
            return slugRegex.test(this.itemComponent.slug);
        },
        isValidName() {
            return titleRegex.test(this.itemComponent.name);
        },
        isValidShortDescription() {
            return shortDescriptionRegex.test(
                this.itemComponent.shortDescription
            );
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("slug" in this.$route.params) {
            this.slug = this.$route.params.slug;
            await this.getBaseItem(this.slug);
        } else if ("slugClone" in this.$route.params) {
            if (!(await verifyPageScope("brand:Create", "/app/brands"))) return;
            this.slug = "";
            this.enabledUpdate = true;
            await this.getBaseItem(this.$route.params.slugClone);
            this.itemComponent = {
                ...this.itemComponent,
                name: `${this.itemComponent.name} CLONE`,
                slug: `${this.itemComponent.slug}-clone`,
                active: false
            };
            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando una marca",
                icon: "info",
                showCloseButton: true
            });
        } else {
            await verifyPageScope("brand:Create", "/app/brands");
            this.enabledUpdate = true;
        }
    },
    methods: {
        ...mapActions("brands", [
            "getBrandBySlug",
            "updateBrand",
            "createBrand"
        ]),
        async getBaseItem(slug) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                this.itemComponent = await this.getBrandBySlug({ slug });
                this.name = this.itemComponent.name;
                this.images = this.itemComponent.images;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/brands");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateBrandComponent() {
            if (!this.validateData()) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Marca invalida",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateBrand({
                    ...this.itemComponent,
                    images: this.images.map((item) => ({
                        id: item.id,
                        sequence: item.sequence.sequence
                    })),
                    slug: this.slug
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createBrandComponent() {
            if (!this.validateData()) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Marca invalida",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const data = await this.createBrand(this.itemComponent);
                this.loaderInstance.close();
                this.itemComponent = { ...data, ...this.itemComponent };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Slug: ${data.slug}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de marcas",
                    cancelButtonText: "Registrar marca"
                });
                if (dialogResult.isConfirmed) {
                    this.slug = data.slug;
                    this.itemComponent = { ...data, ...this.itemComponent };
                    this.$router.push(`/app/brands/detail/${this.slug}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/brands/");
                }
                if (dialogResult.isDismissed) {
                    this.resetItem();
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async setSlug() {
            if (
                this.enabledUpdate &&
                this.isValidName &&
                !this.itemComponent.active
            ) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Quieres cambiar el slug ?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33ddd",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                });
                if (dialogResult.isConfirmed) {
                    this.itemComponent.slug = slugify(this.itemComponent.name);
                }
            }
        },
        blurName() {
            this.introValidators.name = true;
            this.setSlug();
        },
        validateData() {
            this.introValidators = {
                slug: true,
                name: true,
                shortDescription: true
            };
            return (
                this.isValidShortDescription &&
                this.isValidSlug &&
                this.isValidName
            );
        },
        resetItem() {
            this.introValidators = {
                slug: false,
                name: false,
                shortDescription: false
            };
            this.itemComponent = {
                name: "",
                description: "",
                slug: "",
                shortDescription: "",
                keywords: [],
                images: []
            };
            this.images = [];
        }
    }
};
</script>
