<template>
    <div>
        <div class="d-flex justify-content-center">
            <h1 class="h2">Subir y registrar archivo</h1>
        </div>
        <div id="info-container" class="d-flex flex-wrap">
            <div
                class="offset-lg-8 col-lg-4 col-12 mt-2 d-flex align-items-center justify-content-between"
            >
                <small for="swDateBetween">
                    ¿ Necesitas ayuda para crear las archivos ?
                </small>
                <vs-button
                    icon
                    border
                    :active="enableHelp"
                    @click="enableHelp = !enableHelp"
                >
                    ℹ️
                </vs-button>
            </div>
        </div>
        <div v-show="enableHelp">
            <div class="col-10 mx-auto rounded border border-success py-4">
                <ul class="list-group">
                    <li class="py-2 px-1">
                        1. Llena el formulario con los datos de la archivo.
                    </li>
                    <li class="py-2 px-1">
                        2. Dale click en el botón con el icono de carga(⬆️)
                    </li>
                    <li class="py-2 px-1">
                        3. Selecciona el archivo, asegurate que tenga las
                        dimensiones adecuadas segun el tipo de archivo.
                    </li>
                    <li class="py-2 px-1">
                        4. El sistema te va a preguntar si estas seguro de la
                        carga de la archivo, responde si. En caso de responder
                        si y haber cargado la archivo erronea. Contactate con el
                        administrador (Nicolas).
                    </li>
                    <li class="py-2 px-1">
                        4. Cuando la archivo se haya cargado de forma adecuada,
                        el sistema te habilita el botón Crear Archivo, verifica
                        los datos y dale click en el botón.
                    </li>
                </ul>
            </div>
        </div>
        <div
            class="d-flex flex-column flex-lg-row justify-content-center align-items-center"
        >
            <vs-radio
                v-for="(value, key) in $data.$typesFiles"
                :key="key"
                v-model="type"
                class="mt-1 mx-1"
                :disabled="!!file.id"
                :val="key"
            >
                <small>
                    {{ value }}
                </small>
            </vs-radio>
        </div>
        <div class="d-flex flex-wrap">
            <file-uploader
                v-model="file"
                :type="type"
                required-alt
                auto-loader
                @loading="onLoading"
                @created="onCreated"
            />
        </div>
        <div class="d-flex justify-content-center mt-3">
            <vs-button
                gradient
                :active="!!file.id"
                :disabled="!file.id || isLoading"
                size="large"
                @click="createFileComponent"
            >
                Crear archivo
            </vs-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { typesFiles } from "@/common/fields/type-files";

import FileUploader from "@/components/utils/FileUploader.vue";

export default {
    name: "FilesNew",
    components: {
        FileUploader
    },
    data: () => ({
        id: 0,
        enableHelp: false,
        isLoading: false,
        file: { id: null, name: "", url: "" },
        $typesFiles: typesFiles,
        type: "productFile",
        loaderInstance: null
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("file:Create", "/app");
    },
    methods: {
        ...mapActions("files", ["uploadFile", "createFile"]),
        async createFileComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const result = await this.createFile({
                    ...this.file
                });
                this.loaderInstance.close();
                this.isLoading = false;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Deseas continuar subiendo archivos ?",
                    text: `Creada con el ID: ${result.id}`,
                    icon: "success",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp"
                    },
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "Si",
                    cancelButtonText: "Quiero regresar",
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#113ea2",
                    allowOutsideClick: false,
                    showCloseButton: true
                });
                if (!dialogResult.isConfirmed) {
                    this.$router.push("/app/files");
                }
                this.file = {
                    id: 0,
                    name: "undefined",
                    type: "productFile",
                    url: ""
                };
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        onLoading(value) {
            this.isLoading = value;
        },
        onCreated() {
            this.isLoading = false;
        }
    }
};
</script>
