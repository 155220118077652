import { TARGET_GROUPS } from "../constants";

export const filtersTypes = [
    {
        name: "Audiencia",
        value: "Audiencia",
        type: "checkbox",
        key: "targetGroups",
        items: Object.entries(TARGET_GROUPS).map((item) => ({
            value: item[0],
            name: item[1]
        })),
        range: {
            start: 5000,
            end: 200000,
            step: 10000
        }
    },
    {
        name: "Etiquetas",
        value: "Etiquetas",
        type: "checkbox",
        key: "tags",
        items: [],
        range: {
            start: 5000,
            end: 200000,
            step: 10000
        }
    },
    {
        name: "Precio",
        value: "Precio",
        type: "range",
        key: "priceOffer",
        items: [],
        range: {
            start: 5000,
            end: 200000,
            step: 10000
        }
    }
];
