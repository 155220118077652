<template>
    <div>
        <div class="border-bottom border-info rounded px-1 d-flex flex-wrap">
            <div
                class="col-lg-2 col-md-3 col-4 d-flex justify-content-center align-items-center py-3"
            >
                <button
                    v-if="'images' in reference"
                    class="w-28 h-28 p-0"
                    @click="goToMainPage(reference.product.slug)"
                >
                    <img
                        class="w-100 h-100"
                        :src="reference.images[0].url | image"
                        :alt="reference.images[0].alt"
                        loading="lazy"
                    />
                </button>
                <img
                    v-else
                    class="w-28 h-28 p-0"
                    :src="$data.$defaultImageUrl"
                    loading="lazy"
                />
            </div>
            <div
                class="col-lg-10 col-md-9 col-8 d-flex flex-column justify-content-around py-3 px-1"
            >
                <div
                    class="d-flex justify-content-lg-between flex-lg-row flex-column align-items-center my-2"
                >
                    <div
                        v-if="reference.productId"
                        class="d-flex flex-column w-100"
                    >
                        <router-link
                            :to="
                                merchant
                                    ? `/app/inventories/${merchant.merchantType}/${merchant.merchantId}/detail/${reference.id}`
                                    : `/app/inventories/detail/${reference.id}`
                            "
                            class="btn-a p-0"
                        >
                            <strong>
                                {{ reference.product.name | cut(50) }}&nbsp;
                                {{
                                    reference.active === undefined
                                        ? ""
                                        : reference.active
                                        ? "🟢"
                                        : "⚪"
                                }}
                            </strong>
                        </router-link>
                        <div>
                            <small>
                                Prod.
                                <router-link
                                    :to="`/app/products/detail/${reference.productId}`"
                                    class="btn-a p-0"
                                >
                                    {{
                                        reference.product.sku
                                            ? `SKU ${reference.product.sku}`
                                            : `ID ${reference.productId}`
                                    }}
                                </router-link>

                                | Ref.
                                <router-link
                                    :to="`/app/products/detail/${reference.productId}?reference=${reference.id}&active=references`"
                                    class="btn-a p-0"
                                >
                                    {{
                                        reference.barcode
                                            ? `Cód. ${reference.barcode}`
                                            : `ID ${reference.id}`
                                    }}
                                </router-link>
                            </small>
                        </div>
                    </div>
                    <div v-else class="d-flex flex-column w-100">
                        <span class="p-0">
                            {{ reference.product.name }}
                        </span>
                        <small> Servicio / P.A. </small>
                    </div>
                    <div
                        v-if="enabled"
                        id="actions"
                        class="d-flex w-100 justify-content-end mt-2 align-items-center"
                    >
                        <vs-tooltip v-if="enabledDiscount" border>
                            <vs-checkbox
                                v-model="availableDiscount"
                                warn
                                @input="changeAvailableDiscount"
                            />
                            <template #tooltip> Aplicar Descuento 🤑</template>
                        </vs-tooltip>

                        <b-form-spinbutton
                            v-model="quantity"
                            class="mx-2"
                            max="100"
                            min="1"
                            size="sm"
                            inline
                            @input="changeQuantity"
                        >
                            <template #decrement>➖</template>
                            <template #increment>➕</template>
                        </b-form-spinbutton>

                        <vs-button danger circle icon @click="remove">
                            🗑
                        </vs-button>
                    </div>
                </div>

                <div
                    class="d-flex flex-column flex-lg-row flex-wrap justify-content-lg-between"
                >
                    <div
                        v-if="reference.color"
                        class="d-flex flex-lg-column justify-content-between pr-2"
                    >
                        <strong class="">Color </strong>
                        <div class="d-flex align-items-center">
                            <small class="text-capitalize">
                                {{ reference.color }}
                            </small>
                            <div
                                class="ml-1 len-3 rounded-circle border border-secondary"
                                :style="{
                                    'background-color': reference.colorRef
                                }"
                            ></div>
                        </div>
                    </div>
                    <div
                        v-if="reference.size"
                        class="d-flex flex-lg-column justify-content-between pr-2"
                    >
                        <strong class="">Tamaño </strong>
                        <small class="text-capitalize">
                            {{ reference.size }}
                        </small>
                    </div>
                    <div
                        v-if="Number.isInteger(reference.stock)"
                        class="d-flex flex-lg-column justify-content-between pr-2"
                        :class="{
                            'font-weight-bold text-danger':
                                reference.stock < quantity,
                            'text-through': reference.stock === 0
                        }"
                    >
                        <strong class="">Stock </strong>
                        <small>
                            {{ reference.stock }}
                        </small>
                    </div>
                    <div
                        class="d-flex flex-lg-column justify-content-between pr-2"
                    >
                        <strong class="">Cantidad </strong>
                        <small
                            :class="{
                                'text-through font-weight-bold': quantity === 0
                            }"
                        >
                            {{ quantity }}
                        </small>
                    </div>
                    <div
                        class="d-flex flex-lg-column justify-content-between pr-2"
                    >
                        <strong class="">$ Unidad </strong>
                        <small>
                            {{ reference.priceOffer | money }}
                        </small>
                    </div>
                    <div
                        v-if="
                            Number.isInteger(
                                reference.appliedDiscountPerReference
                            )
                        "
                        class="d-flex flex-lg-column justify-content-between pr-2"
                    >
                        <strong class="">$ Descuento u. </strong>
                        <small>
                            {{ reference.appliedDiscountPerReference | money }}
                        </small>
                    </div>
                    <div
                        v-if="
                            reference.cost && Number.isInteger(reference.cost)
                        "
                        class="d-flex flex-lg-column justify-content-between pr-2"
                    >
                        <strong class="">Costo unidad </strong>
                        <small>
                            {{ reference.cost | money }}
                        </small>
                    </div>
                    <div class="w-100 mt-2 d-flex pr-2">
                        <span class="text-primary font-weight-bold">
                            $ Referencias: &nbsp;
                        </span>
                        <span
                            class="font-weight-bold h5"
                            :class="{
                                'text-through ': quantity === 0
                            }"
                        >
                            {{
                                ((reference.priceOffer -
                                    (reference.appliedDiscountPerReference ||
                                        0)) *
                                    quantity)
                                    | money
                            }}
                        </span>
                    </div>
                </div>

                <div v-if="availableDiscount && enabled" class="mt-4 pr-2">
                    <input-money-component
                        v-model="appliedDiscountPerReference"
                        border
                        enabled
                        label="Descuento neto por referencia"
                        icon="💲"
                        :message-error="
                            appliedDiscountPerReference >
                            reference.priceOffer * 0.6
                                ? 'Descuento no debe exceder el 60% del valor de la referencia'
                                : ''
                        "
                        @blur="blurAppliedDiscountPerReference"
                        @focus="focusInput"
                    />
                </div>
            </div>
        </div>
        <vs-dialog
            v-model="isOpenModalGetInventory"
            scroll
            overflow-hidden
            auto-width
            :loading="isLoadingGetInventory"
        >
            <template #header>
                <div class="d-flex flex-column mx-3 my-4">
                    <span class="h3">
                        Inventario para referencia
                        <span class="text-primary"> # {{ reference.id }} </span>
                    </span>
                    <small>
                        Color {{ reference.color }} | Tamaño
                        {{ reference.size }}
                    </small>
                </div>
            </template>
            <div class="con-content">
                <inventory-by-reference-table
                    :inventories="inventories"
                    :stock-active="stockActive"
                />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { DEFAULT_IMAGE_URL } from "@/common/constants";

import InventoryByReferenceTable from "@/components/inventories/InventoryByReferenceTable.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ReferenceTableItem",
    components: { InputMoneyComponent, InventoryByReferenceTable },
    props: {
        reference: {
            type: Object,
            required: true,
            default: () => ({
                id: 20,
                size: "pequeño",
                color: "rojo",
                colorRef: "#f00",
                quantity: 1,
                price: 10000,
                priceOffer: 10000,
                appliedDiscountPerReference: 0,
                images: [
                    {
                        id: 1,
                        url: DEFAULT_IMAGE_URL,
                        alt: "alt"
                    }
                ],
                product: {
                    id: "",
                    name: "",
                    shortDescription: ""
                }
            })
        },
        merchant: {
            default: () => null,
            type: Object, //{merchantId:string,merchantType:string}
            required: false
        },
        enabled: {
            default: () => false,
            type: Boolean,
            required: false
        },
        enabledDiscount: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    emits: ["update", "remove", "focus"],
    data: () => ({
        quantity: 0,
        appliedDiscountPerReference: 0,
        inventories: [],
        isOpenModalGetInventory: false,
        availableDiscount: false,
        isLoadingGetInventory: false,
        stockActive: 0,
        $defaultImageUrl: DEFAULT_IMAGE_URL
    }),
    computed: { ...mapGetters("control", ["backgroundColor"]) },
    watch: {
        reference(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.reference);
    },
    methods: {
        ...mapActions("inventories", ["listInventoryByReference"]),
        async listInventoryReferenceComponent() {
            this.isLoading = true;
            this.isOpenModalGetInventory = true;
            this.isLoadingGetInventory = true;
            try {
                const { items, stockActive } =
                    await this.listInventoryByReference({
                        id: this.reference.id
                    });
                this.inventories = items;
                this.stockActive = stockActive;
            } catch (error) {
                this.isOpenModalGetInventory = false;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.isLoadingGetInventory = false;
            }
        },
        goToMainPage(slug) {
            if (this.reference.active) {
                window
                    .open(`${this.$marketplaceUrl}/${slug}`, "_blank")
                    .focus();
            }
        },
        remove() {
            this.$emit("remove", { id: this.reference.id });
        },
        blurAppliedDiscountPerReference() {
            this.$emit("update", {
                ...this.reference,
                quantity: this.quantity,
                appliedDiscountPerReference: this.appliedDiscountPerReference
            });
        },
        focusInput() {
            this.$emit("focus");
        },
        setInput(value) {
            this.quantity = Number(value.quantity);
            this.appliedDiscountPerReference =
                Number(value.appliedDiscountPerReference) || 0;
            this.availableDiscount = this.appliedDiscountPerReference > 0;
        },
        changeQuantity(value) {
            this.$emit("update", {
                ...this.reference,
                quantity: value,
                appliedDiscountPerReference: this.appliedDiscountPerReference
            });
        },
        changeAvailableDiscount(value) {
            if (!value) {
                this.appliedDiscountPerReference = 0;
                this.blurAppliedDiscountPerReference();
            }
        }
    }
};
</script>
