var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-10 pb-10 d-flex justify-content-between"},[_c('h1',{staticClass:"text-center"},[_vm._v(" Detalles de grupo de orden "),_c('strong',{staticClass:"text-primary"},[_vm._v(" #"+_vm._s(_vm.itemComponent.id)+" ")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('vs-button',{attrs:{"circle":"","icon":"","floating":""},on:{"click":_vm.getBaseItem}},[_c('reload-icon')],1)],1)])]),(_vm.hasLoadedBase)?_c('div',{staticClass:"my-4 d-flex flex-wrap"},[_c('div',{staticClass:"col-12 col-md-6 d-flex flex-column my-2"},[_c('strong',[_vm._v("Fecha de Fragmentación ")]),_c('span',{staticClass:"mt-2 ml-2"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.itemComponent.createdAt))+" ")])]),_c('div',{staticClass:"col-12 col-md-6 d-flex flex-column my-2"},[_c('strong',[_vm._v("Fecha de última actualización ")]),_c('span',{staticClass:"mt-2 ml-2"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.itemComponent.updatedAt))+" ")])]),_c('div',{staticClass:"col-12 col-md-6 d-flex flex-column my-2"},[_c('strong',[_vm._v("Estado de pago del grupo de ordenes ")]),_c('span',{staticClass:"mt-2 ml-2",class:_vm.$data.$paymentStatusGroupMessageClass[
                        _vm.itemComponent.paymentStatusGroup
                    ]},[_vm._v(" "+_vm._s(_vm.$data.$paymentStatusesGroupCodes[ _vm.itemComponent.paymentStatusGroup ])+" ")])]),_c('div',{staticClass:"col-12 col-md-6 d-flex flex-column my-2"},[_c('strong',[_vm._v("Estado de entrega del grupo de ordenes ")]),_c('span',{staticClass:"mt-2 ml-2",class:_vm.$data.$deliveryStatusesGroupMessageClass[
                        _vm.itemComponent.deliveryStatusGroup
                    ]},[_vm._v(" "+_vm._s(_vm.$data.$deliveryStatusesGroupCodes[ _vm.itemComponent.deliveryStatusGroup ])+" ")])]),_c('div',{staticClass:"d-flex overflow-x-auto mt-4 col-12"},_vm._l((_vm.itemComponent.orders),function(column){return _c('div',{key:column.id,staticClass:"mr-1"},[_c('div',{staticClass:"border rounded border-secondary w-72 px-2 pt-3 h-100"},[_c('router-link',{staticClass:"btn-a p-0",attrs:{"to":("/app/orders/detail/" + (column.id))}},[_c('h3',[_vm._v(" Orden # "+_vm._s(column.id)+" ")])]),_c('div',{staticClass:"d-flex justify-content-center flex-column"},[_c('span',{staticClass:"my-1"},[_c('strong',[_vm._v("Proveedor : ")]),_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("merchantInfo")(column.supplyMerchant.merchantType,"icon", "type"))+" "+_vm._s(column.supplyMerchant.name)+" ")])]),_c('span',{staticClass:"my-1"},[_c('strong',[_vm._v("Dirección proveedor : ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("cut")(_vm._f("address")(column.supplyMerchant.address),28))+" "+_vm._s(((column.supplyMerchant .addressComplement) + "," + (column.supplyMerchant.neighborhood ? ("barrio " + (column.supplyMerchant.neighborhood) + " de ") : "") + (column.supplyMerchant.city)).trim())+" ")])]),_c('span',{staticClass:"my-1"},[_c('strong',[_vm._v(" Estado de entrega : ")]),_c('span',{class:_vm.$data.$deliveryStatusesMessageClass[
                                        column.deliveryStatus
                                    ]},[_vm._v(" "+_vm._s(_vm.$data.$deliveryStatusesCodes[ column.deliveryStatus ])+" ")])]),_c('span',[_c('strong',[_vm._v(" Estado de pago : ")]),_c('span',{class:_vm.$data.$deliveryStatusesMessageClass[
                                        column.deliveryStatus
                                    ]},[_vm._v(" "+_vm._s(_vm.$data.$deliveryStatusesCodes[ column.deliveryStatus ])+" ")])]),_c('span',{staticClass:"my-1"},[_c('strong',[_vm._v(" 🗓 Actualización : ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(column.updatedAt))+" ")])]),_c('span',{staticClass:"my-1"},[_c('strong',[_vm._v(" Total: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("money")(column.total))+" ")])]),_c('span',{staticClass:"my-1"},[_c('strong',[_vm._v(" Precio de productos: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("money")(column.referencesValueWithDiscount))+" ")])]),_c('span',{staticClass:"mt-1"},[_c('strong',[_vm._v(" Productos: ")]),_c('span',[_vm._v(_vm._s(column.references.length))])]),_vm._l((column.references),function(row){return _c('reference-table-item-2',{key:row.id,staticClass:"my-2",attrs:{"reference":row}})})],2)],1)])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }