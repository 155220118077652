<template>
    <div class="w-100">
        <div class="d-flex flex-wrap mt-4">
            <table v-if="itemsCurrent.length" class="table" :class="tableDark">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Llave de indetificación</th>
                        <th scope="col">Tipo</th>
                        <th scope="col d-flex items-align-center">Nombre</th>
                        <th scope="col d-flex items-align-center">
                            Sección donde es requerido
                        </th>
                        <th v-if="enabled" scope="col">
                            <div class="d-flex justify-content-center">
                                <span>⚙</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in itemsCurrent" :key="index">
                        <th scope="col-1">{{ index + 1 }}</th>
                        <td class="col-3">
                            <span>
                                {{ item.key }}
                            </span>
                        </td>
                        <td class="col-2">
                            <span>
                                {{
                                    $data.$productExtraOptionTypesEnum[
                                        item.type
                                    ]
                                }}
                            </span>
                        </td>
                        <td class="col-3">
                            <span>
                                {{ item.name }}
                            </span>
                        </td>
                        <td class="col-1">
                            <span>
                                {{
                                    $data
                                        .$productExtraOptionSectionWhereRequested[
                                        item.sectionWhereRequested
                                    ]
                                }}
                            </span>
                        </td>
                        <td v-if="enabled" class="col-1">
                            <div class="d-flex justify-content-center">
                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    :disabled="index === 0"
                                    @click="moveOrder(index, index - 1)"
                                >
                                    ⬆
                                </button>

                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    :disabled="index >= itemsCurrent.length - 1"
                                    @click="moveOrder(index, index + 1)"
                                >
                                    ⬇
                                </button>
                                <button
                                    class="btn btn-danger len-3 d-flex align-items-center justify-content-center"
                                    @click="deleteItem(index)"
                                >
                                    🗑️
                                </button>
                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    @click="editItem(index)"
                                >
                                    🖊
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else><span class="h3">No hay opciones extra</span></div>
        </div>

        <div v-if="enabled" class="d-flex flex-wrap">
            <div class="col-8 offset-2 d-flex justify-content-center">
                <slot name="action"></slot>
            </div>
            <div class="col-2">
                <vs-tooltip border>
                    <button
                        v-if="enabled"
                        class="ml-4 mt-2 border border-secondary btn rounded-circle d-flex justify-content-center align-items-center p-2"
                        @click="openNewFilter"
                    >
                        <span>➕</span>
                    </button>
                    <template #tooltip> Agregar opción extra</template>
                </vs-tooltip>
            </div>
        </div>

        <div>
            <vs-dialog
                v-model="isOpenModalSelectItem"
                scroll
                overflow-hidden
                auto-width
            >
                <template #header> </template>
                <div
                    class="con-content py-2 px-4 d-flex flex-column align-items-center justify-content-center h-100"
                >
                    <div class="d-flex flex-wrap">
                        <div class="d-flex flex-column col-12">
                            <span class="h3 text-center">
                                {{
                                    isEditingItem
                                        ? "Editar opción extra"
                                        : "Agregar opción extra"
                                }}
                            </span>
                            <span class="mt-3">
                                Selecciona el tipo de opción extra que quieres,
                                configura las opciones adicionales y luego da
                                click en Guardar.
                            </span>
                        </div>
                        <div class="col-12 mt-4">
                            <product-extra-option-type-selector
                                v-model="itemTemp.type"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <span> Llave de identificación </span>
                            <vs-input
                                id="key_"
                                v-model="itemTemp.key"
                                autocomplete="off"
                                placeholder="need-cutlery"
                                border
                                shadow
                                state="success"
                                type="text"
                                name="key"
                                @input="onInputKey"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <span> Nombre </span>
                            <vs-input
                                id="name_"
                                v-model="itemTemp.name"
                                border
                                shadow
                                autocomplete="off"
                                state="success"
                                type="text"
                                name="name"
                                placeholder="Nombre del campo solicitado"
                            />
                        </div>
                        <div class="col-12 mt-4">
                            <span> Descripción </span>
                            <vs-input
                                id="name_"
                                v-model="itemTemp.shortDescription"
                                border
                                shadow
                                autocomplete="off"
                                state="success"
                                type="text"
                                name="name"
                                placeholder="Incluye una descripción para que el cliente interprete el campo solicitado"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <product-extra-option-requested-by-entity-selector
                                v-model="itemTemp.requestedByEntity"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <product-extra-option-section-where-requested-selector
                                v-model="itemTemp.sectionWhereRequested"
                            />
                        </div>
                    </div>
                    <div
                        v-if="
                            itemTemp.type === 'textChoice' ||
                            itemTemp.type === 'multiTextChoice'
                        "
                        class="my-4"
                    >
                        <p>
                            El <strong>nombre</strong> hace referencia la cadena
                            de texto que se muestra al cliente. El
                            <strong>llave</strong> es el identificador de la
                            opción debe estar escrito en formato "snake_case".
                        </p>
                        <feature-manage
                            v-model="features"
                            enabled
                            value-label="Llave"
                            @change="updateOptionsByFeatures"
                        />
                    </div>

                    <div v-if="itemTemp.type === 'imageChoice'" class="my-4">
                        <images-manage
                            v-model="images"
                            class="my-4"
                            message="opción extra"
                            @change="updateOptionsByImages"
                        />
                    </div>

                    <div v-if="showDuplicate" class="mx-3 mt-2">
                        <span class="h3 text-danger mt-4">
                            Ya tienes un opción extra con la llave
                            {{ itemTemp.key }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <vs-button
                            :disabled="isInvalidCurrentItem"
                            class="mt-3 mb-5 mx-auto"
                            border
                            gradient
                            @click="pushOrUpdateItem"
                        >
                            <span class="h4"> Guardar</span>
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { productExtraOptionRequestedByEntities } from "@/common/fields/product-extra-option-requested-by-entities";
import { productExtraOptionSectionWhereRequested } from "@/common/fields/product-extra-option-section-where-requested";
import { productExtraOptionTypesEnum } from "@/common/fields/product-extra-option-types";
import { slugify } from "@/common/lib/transformers";

import FeatureManage from "@/components/utils/FeatureManage.vue";
import ImagesManage from "@/components/utils/ImagesManage.vue";

import ProductExtraOptionRequestedByEntitySelector from "./ProductExtraOptionRequestedByEntitySelector.vue";
import ProductExtraOptionSectionWhereRequestedSelector from "./ProductExtraOptionSectionWhereRequestedSelector.vue";
import ProductExtraOptionTypeSelector from "./ProductExtraOptionTypeSelector.vue";

export default {
    name: "ProductExtraOptionsManager",
    components: {
        FeatureManage,
        ImagesManage,
        ProductExtraOptionRequestedByEntitySelector,
        ProductExtraOptionTypeSelector,
        ProductExtraOptionSectionWhereRequestedSelector
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        isOpenModalSelectItem: false,
        selectedFilters: [],
        features: [],
        images: [],
        $productExtraOptionSectionWhereRequested:
            productExtraOptionSectionWhereRequested,
        $productExtraOptionTypesEnum: productExtraOptionTypesEnum,
        itemTemp: {
            key: "",
            name: "",
            type: "",
            shortDescription: "",
            sectionWhereRequested: productExtraOptionSectionWhereRequested.none,
            requestedByEntity: productExtraOptionRequestedByEntities.reference,
            options: []
        },
        edit: false,
        showDuplicate: false,
        isEditingItem: false
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "tableDark"]),
        isInvalidCurrentItem() {
            return (
                !this.itemTemp.key ||
                !this.itemTemp.name ||
                !this.itemTemp.type ||
                !this.itemTemp.requestedByEntity ||
                this.showDuplicate
            );
        }
    },
    watch: {},
    methods: {
        onInputKey(value) {
            this.itemTemp.key = slugify(value);
            if (this.isEditingItem) return;
            this.showDuplicate = this.itemsCurrent.some(
                (item) => item.key === value
            );
        },
        updateOptionsByFeatures(values) {
            this.itemTemp.options = values.map((item) => ({
                key: item.value,
                name: item.name,
                value: item.value
            }));
        },
        updateOptionsByImages(values) {
            this.itemTemp.options = values.map((item) => ({
                key: `${item.id}`,
                name: item.alt,
                value: item.url
            }));
        },
        moveOrder(fromIndex, toIndex) {
            let arrAux = [...this.itemsCurrent];
            const aux = arrAux[fromIndex];
            arrAux.splice(fromIndex, 1);
            arrAux.splice(toIndex, 0, aux);
            this.$emit("change", arrAux);
        },
        editItem(fromIndex) {
            this.isEditingItem = true;
            let arrAux = [...this.itemsCurrent];
            this.itemTemp = arrAux[fromIndex];
            if (
                ["multiTextChoice", "textChoice"].includes(this.itemTemp.type)
            ) {
                this.features = this.itemTemp.options;
            } else if (["imageChoice"].includes(this.itemTemp.type)) {
                this.images = this.itemTemp.options.map((item, index) => ({
                    alt: item.name,
                    id: Number(item.key),
                    sequence: { sequence: index },
                    url: item.value
                }));
            }
            this.isOpenModalSelectItem = true;
        },
        deleteItem(fromIndex) {
            let itemsResult = [...this.itemsCurrent];
            itemsResult.splice(fromIndex, 1);
            this.$emit("change", itemsResult);
        },
        selectionFilter(id) {
            this.selectedFilters.push(id);
        },
        openNewFilter() {
            this.itemTemp = {
                key: "",
                name: "",
                type: "",
                shortDescription: "",
                sectionWhereRequested:
                    productExtraOptionSectionWhereRequested.none,
                requestedByEntity:
                    productExtraOptionRequestedByEntities.reference,
                options: []
            };
            this.isOpenModalSelectItem = true;
        },
        pushOrUpdateItem() {
            const itemsAux = [...this.itemsCurrent];
            if (this.isEditingItem) {
                const itemAux = itemsAux.findIndex(
                    (item) => item.key === this.itemTemp.key
                );
                itemsAux[itemAux] = this.itemTemp;
                this.isEditingItem = false;
            } else {
                itemsAux.push(this.itemTemp);
            }
            this.$emit("change", itemsAux);
            this.isOpenModalSelectItem = false;
        }
    }
};
</script>
