import { OperationsModes } from "../fields/operations-modes";
import { setApiURL } from "../lib/config";

export const PORT = process.env.VUE_APP_PORT || 8080;

const _setOperationMode = (operationModeStr) => {
    const key = Object.keys(OperationsModes).find(
        (key) => OperationsModes[key] === operationModeStr
    );
    const operationMode = key
        ? OperationsModes[key]
        : OperationsModes.posSimpleAccess;
    return operationMode;
};

const OPERATION_MODE_STR =
    process.env.VUE_APP_OPERATION_MODE || "pos-simple-access";

export const OPERATION_MODE = _setOperationMode(OPERATION_MODE_STR);
console.log("Running in [%s] operation mode", OPERATION_MODE);
export const ENABLED_ECOMMERCE_OPTIONS = [
    OperationsModes.develop,
    OperationsModes.marketplace
].includes(OPERATION_MODE);
export const IS_MARKETPLACE = OPERATION_MODE === OperationsModes.marketplace;
export const SITE_TYPE = IS_MARKETPLACE ? "marketplace" : "sistema";

export const COMPLETE_TARGET_GROUPS =
    process.env.COMPLETE_TARGET_GROUPS ||
    "boy_0,Niño menor de dos años;girl_0,Niña menor de dos años;girl_2_7,Niña de 2 a 7 años;boy_2_7,Niño de 2 a 7 años;girl_8_16,Niña de 8 a 16 años;boy_8_16,Niño de 8 a 16 años;woman,Femenino;man,Masculino;Unisex,unisex";
export const TARGET_GROUPS = COMPLETE_TARGET_GROUPS.split(";")
    .map((item) => item.split(","))
    .reduce((acc, item) => {
        acc[item[0]] = item[1];
        return acc;
    }, {});

export const MARKETPLACE_API_URL = setApiURL(
    OPERATION_MODE,
    process.env.VUE_APP_MARKETPLACE_API_URL
);
export const MARKETPLACE_PORTAL_URL =
    process.env.VUE_APP_MARKETPLACE_PORTAL_URL;
export const MARKETPLACE_PORTAL_SLOGAN =
    process.env.VUE_APP_MARKETPLACE_PORTAL_SLOGAN;
export const MARKETPLACE_URL = process.env.VUE_APP_MARKETPLACE_URL;
export const DEFAULT_MARKETPLACE_MARGIN = Number(
    process.env.VUE_APP_MARKETPLACE_MARGIN | "10"
);

export const SITE_BASE_PATH = process.env.VUE_APP_SITE_BASE_PATH || "/";
export const SITE_PRIMARY_COLOR =
    process.env.VUE_APP_SITE_PRIMARY_COLOR || "#FDD402";
export const SITE_SUCCESS_COLOR = "#0097D6";
export const SITE_DANGER_COLOR = "#EA5455";
export const SITE_WARNING_COLOR = "#FF9F43";
export const SITE_DARK_COLOR = "#1E1E1E";

export const DEFAULT_IMAGE_URL =
    process.env.VUE_APP_DEFAULT_IMAGE_URL || "/default-image.jpg";
export const PUBLIC_KEY = process.env.VUE_APP_PUBLIC_KEY.replace(/\\n/gm, "\n");

export const AUTH_REDIRECT_SIGN_IN = `${MARKETPLACE_PORTAL_URL}/callback`;
export const AUTH_REDIRECT_SIGN_OUT = `${MARKETPLACE_PORTAL_URL}/login`;
export const AWS_REGION = process.env.VUE_APP_AWS_REGION;
export const COGNITO_URL = process.env.VUE_APP_COGNITO_URL;
export const COGNITO_USERS_POOLS_ID =
    process.env.VUE_APP_COGNITO_USERS_POOLS_ID;
export const COGNITO_USERS_POOLS_WEB_CLIENT_ID =
    process.env.VUE_APP_COGNITO_USERS_POOLS_WEB_CLIENT_ID;

export const ENABLED_AUTH = OPERATION_MODE !== OperationsModes.posSimpleAccess;
export const ENABLED_ENCRYPT_SIGN_IN =
    PUBLIC_KEY && OPERATION_MODE == OperationsModes.posProtectedAccess;

export const ENABLED_UPLOAD_FILES = ENABLED_ECOMMERCE_OPTIONS;
export const ENABLED_SEO_TEST = ENABLED_ECOMMERCE_OPTIONS;
export const ENABLED_LOGISTIC = ENABLED_ECOMMERCE_OPTIONS;

const _getAuthConfiguration = () => {
    if (IS_MARKETPLACE) {
        if (
            !AWS_REGION ||
            !COGNITO_URL ||
            !COGNITO_USERS_POOLS_ID ||
            !COGNITO_USERS_POOLS_WEB_CLIENT_ID
        ) {
            console.error(
                "To operate in marketplace mode configure the environment variables for cognito"
            );
            return { enabledGoogleAuth: false, error: true };
        }
        return { enabledGoogleAuth: true, error: false };
    }
    return { enabledGoogleAuth: false, error: false };
};

const authConfiguration = _getAuthConfiguration();
export const ENABLED_GOOGLE_AUTH = authConfiguration.enabledGoogleAuth;
export const BUSINESS_NAME = process.env.VUE_APP_BUSINESS_NAME;
export const MARKETPLACE_PORTAL_TITLE =
    process.env.VUE_APP_MARKETPLACE_PORTAL_TITLE;

export const ERROR_CONFIG = authConfiguration.error;
