<template>
    <div class="container">
        <steps-viewer
            :steps="$data.$steps"
            :step="currentStep"
            @click="clickStep"
        />
        <div v-if="currentStep === 0" class="col-12 col-md-10 col-lg-8 mx-auto">
            <div class="d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="text-primary h2" data-testid="default-typography">
                        Sube el archivo con los productos
                    </p>
                    <span class="display-4 mx-3"> 🗃 </span>
                </div>

                <div>
                    <p data-testid="default-typography" class="my-4">
                        Carga de productos el inventario para la
                        {{
                            selectedMerchant.merchantType | merchantInfo("type")
                        }}
                        <span class="font-weight-bold">
                            "{{ selectedMerchant.name }}" </span
                        >. Llena los campos con la guía que encontraras en el
                        <a
                            href="/template_massive_load_products_references_and_inventories.xlsx"
                            target="_blank"
                            rel="noopener"
                        >
                            archivo base </a
                        >. Los productos se cargan de forma masiva para todo el
                        {{ $siteType }}. Recuerda que el archivo debe tener
                        máximo 1000 filas.
                    </p>
                    <span v-if="isLoading" class="text-center text-warning">
                        Cargando...
                    </span>
                </div>
                <products-and-inventories-file-loader
                    @loaded="loadProducts"
                    @loading="isLoading = true"
                    @error="isLoading = false"
                />
                <div class="mt-4">
                    <small>
                        Peso máximo: 20MB | Formato permitido: Excel (XLSX)
                    </small>
                </div>
            </div>
        </div>
        <div v-else-if="currentStep === 1" class="d-flex flex-column">
            <div class="d-flex flex-column align-items-center">
                <p
                    data-testid="default-typography"
                    class="my-4 font-weight-bold"
                >
                    Tienes errores en el archivo. A continuación te mostramos
                    una lista de errores por favor corrige tu archivo excel.
                    Cuando hayas corregido el archivo excel da click en "Volver
                    al paso anterior".
                </p>
                <div>
                    <vs-button relief @click="reset">
                        Volver al paso anterior
                    </vs-button>
                </div>
            </div>
            <error-viewer
                v-for="(errorItem, index) in errors"
                :key="index"
                :error="errorItem"
                :active="activeError"
                :base="$data.$errorsBase"
                @changeActive="changeActiveError($event)"
            />
        </div>
        <div v-else-if="currentStep === 2">
            <div class="my-3">
                <span>
                    Resumen: productos a ingresar
                    {{ productsToInsert.length }} productos a actualizar
                    {{ productsToUpdate.length }}
                </span>
            </div>
            <div v-if="productsToInsert.length">
                <div class="mb-4">
                    <span class="h3">
                        Productos a insertar
                        <span class="text-primary">
                            {{ productsToInsert.length }}
                        </span>
                    </span>
                </div>

                <product-with-references-manage
                    v-for="(item, index) in productsToInsert"
                    :key="'A' + index"
                    :product="item"
                    @remove="removeProductToInsert"
                    @update="updateProductToInsert"
                />
            </div>
            <div v-if="productsToUpdate.length">
                <div class="mb-4">
                    <span class="h3">
                        Productos a actualizar
                        <span class="text-primary">
                            {{ productsToUpdate.length }}
                        </span>
                    </span>
                </div>

                <product-with-references-manage
                    v-for="(item, index) in productsToUpdate"
                    :key="'B' + index"
                    :product="item"
                    @remove="removeProductToUpdate"
                    @update="updateProductToUpdate"
                />
            </div>
            <div class="my-4">
                <vs-button
                    class="mt-3 mb-5 col-9 mx-auto"
                    border
                    gradient
                    @click="saveMassiveProductsAndInventoriesComponent"
                >
                    <span class="h3"> Guardar Cambios </span>
                </vs-button>
            </div>
        </div>
        <div v-else-if="[3, 4].includes(currentStep)">
            <div v-if="isLoading">Procesando ...</div>
            <div v-else class="d-flex flex-wrap">
                <div class="col-12 mb-4">
                    <span class="text-success h2">Resultados</span>
                </div>
                <div class="col-4">
                    <strong class="">
                        Productos, referencias e inventario creado :
                        <span class="text-success">
                            {{ results.productsAndReferencesCreated.length }}
                        </span>
                    </strong>
                </div>
                <div class="col-4">
                    <strong class="">
                        Productos actualizados y referencias e inventario creado
                        :
                        <span class="text-success">
                            {{
                                results.productsUpdatedAndReferencesCreated
                                    .length
                            }}
                        </span>
                    </strong>
                </div>
                <div class="col-4">
                    <strong class="">
                        Productos actualizados y referencias actualizadas (Se
                        omitio la actualización de inventario) :
                        <span class="text-success">
                            {{ results.productsAndReferencesUpdated.length }}
                        </span>
                    </strong>
                </div>
                <div class="col-12 d-flex justify-content-center mt-4">
                    <vs-button @click="reset"> Iniciar nueva carga </vs-button>
                    <vs-button success @click="downloadJsonComponent">
                        Descargar Resultados JSON ⬇
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { downloadJson } from "@/common/lib/json";

import ProductWithReferencesManage from "@/components/products/ProductWithReferencesManage.vue";
import ProductsAndInventoriesFileLoader from "@/components/products/ProductsAndInventoriesFileLoader.vue";
import ErrorViewer from "@/components/utils/ErrorViewer.vue";
import StepsViewer from "@/components/utils/StepsViewer.vue";

export default {
    name: "MassiveLoadProductsAndInventories",
    components: {
        ProductsAndInventoriesFileLoader,
        StepsViewer,
        ErrorViewer,
        ProductWithReferencesManage
    },
    data: () => ({
        isLoading: false,
        loaderInstance: null,
        step: 0,
        $steps: [
            { name: "Sube archivo", enabledClicked: true },
            { name: "Valida errores en archivo" },
            { name: "Verifica los productos" },
            { name: "Guarda los cambios" }
        ],
        $errorsBase: [
            { name: 'Nombre de producto "name"', key: "name" },
            { name: 'Descripción "shortDescription"', key: "shortDescription" },
            { name: 'SKU "sku"', key: "sku" },
            { name: 'Código de barras "barcode"', key: "barcode" },
            { name: 'Tamaño/Talla/Valor "size"', key: "size" },
            { name: 'Color referencia "color"', key: "color" },
            { name: 'Código de color "colorRef"', key: "colorRef" },
            { name: 'Precio "price"', key: "price" },
            { name: 'Costo "cost"', key: "cost" },
            { name: 'Stock "stock"', key: "stock" }
        ],
        currentStep: 0,
        activeError: 0,
        productsToInsert: [],
        productsToUpdate: [],
        errors: [],
        results: {
            productsAndReferencesCreated: [],
            productsAndReferencesUpdated: [],
            productsUpdatedAndReferencesCreated: []
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (
            !(await verifyPageScope(
                "product:LoadMassiveWithInventories",
                "/app/products",
                true
            ))
        )
            return;
    },
    beforeDestroy() {},
    methods: {
        ...mapActions("products", [
            "verifyMassiveProductsAndInventories",
            "saveMassiveProductsAndInventories"
        ]),
        async loadProducts(rows) {
            const dialogResult = await this.$swal.fire({
                title: `Vas a cargar ${rows.length} filas ?`,
                background: this.backgroundColor,
                icon: "info",
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Si",
                cancelButtonText: "No 😖",
                confirmButtonColor: "#02913f",
                cancelButtonColor: "#f07325",
                allowOutsideClick: false
            });
            if (!dialogResult.isConfirmed) {
                window.location.href = this.$route.path;
                return;
            }
            this.verifyMassiveProductsComponent(rows);
        },
        async verifyMassiveProductsComponent(rows) {
            this.errors = [];
            this.productsToUpdate = [];
            this.productsToInsert = [];
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const result = await this.verifyMassiveProductsAndInventories({
                    rows,
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                if (!result.pass) {
                    this.errors = result.errors;
                    this.activeError = result.errors[0].index;
                    this.currentStep = 1;
                    return;
                }
                this.currentStep = 2;
                this.productsToUpdate = result.productsToUpdate;
                this.productsToInsert = result.productsToInsert;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/products");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async saveMassiveProductsAndInventoriesComponent() {
            const dialogResult = await this.$swal.fire({
                title: `Vas a insertar ${this.productsToInsert.length} productos y a actualizar ${this.productsToUpdate.length}?`,
                background: this.backgroundColor,
                icon: "info",
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Si",
                cancelButtonText: "No 😖",
                confirmButtonColor: "#02913f",
                cancelButtonColor: "#f07325",
                allowOutsideClick: false
            });
            if (!dialogResult.isConfirmed) {
                return;
            }

            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            this.currentStep = 3;
            try {
                const productsAndInventories = [
                    ...this.productsToInsert,
                    ...this.productsToUpdate
                ].map((item, index) => ({
                    index: index + 1,
                    item: {
                        sku: item.sku,
                        name: item.name,
                        shortDescription: item.shortDescription,
                        brandSlug: item.brand.slug,
                        lineSlug: item.subline.slug,
                        references: [
                            ...item.referencesToInsert,
                            ...item.referencesToUpdate
                        ].map((item2) => ({
                            barcode: item2.barcode,
                            price: item2.price,
                            priceOffer: item2.priceOffer,
                            cost: item2.cost,
                            color: item2.color,
                            stock: item2.stock,
                            colorRef: item2.colorRef,
                            size: item2.size,
                            images: item2.images.map((item3) => ({
                                id: item3.id,
                                sequence: item3.sequence.sequence
                            }))
                        }))
                    }
                }));
                this.results = await this.saveMassiveProductsAndInventories({
                    productsAndInventories,
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                this.currentStep = 4;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                this.currentStep = 3;
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        clickStep(step) {
            this.currentStep = step;
        },
        changeActiveError(ref) {
            this.activeError = ref;
        },
        reset() {
            this.errors = [];
            this.productsToUpdate = [];
            this.productsToInsert = [];
            this.currentStep = 0;
        },
        removeProductToInsert({ sku }) {
            const index = this.productsToInsert.findIndex(
                (item) => item.sku === sku
            );
            let productsToInsert = [...this.productsToInsert];
            productsToInsert.splice(index, 1);
            this.productsToInsert = [...productsToInsert];
        },
        updateProductToInsert(payload) {
            const index = this.productsToInsert.findIndex(
                (item) => item.sku === payload.sku
            );
            let productsToInsert = [...this.productsToInsert];
            productsToInsert[index] = {
                ...payload
            };
            this.productsToInsert = [...productsToInsert];
        },
        removeProductToUpdate({ sku }) {
            const index = this.productsToUpdate.findIndex(
                (item) => item.sku === sku
            );
            let productsToUpdate = [...this.productsToUpdate];
            productsToUpdate.splice(index, 1);
            this.productsToUpdate = [...productsToUpdate];
        },
        updateProductToUpdate(payload) {
            const index = this.productsToUpdate.findIndex(
                (item) => item.sku === payload.sku
            );
            let productsToUpdate = [...this.productsToUpdate];
            productsToUpdate[index] = {
                ...payload
            };
            this.productsToUpdate = [...productsToUpdate];
        },
        downloadJsonComponent() {
            downloadJson({
                payload: this.results,
                filename: "massiveLoadProductsAndInventoriesResult"
            });
        }
    }
};
</script>
