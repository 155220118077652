<template>
    <div class="w-100 d-flex flex-column justify-content-center my-2">
        <div class="d-flex flex-wrap justify-content-center">
            <div v-if="!!error" class="alert alert-danger" role="alert">
                {{ error }}
            </div>
        </div>

        <div v-if="wasUploaded" class="d-flex justify-content-center">
            <div class="alert alert-success p-2">
                <a :href="url" target="_blank" rel="noopener">
                    {{ itemCurrent.name }}
                </a>
            </div>
        </div>
        <div v-else class="d-flex flex-wrap d-flex justify-content-center">
            <label
                class="w-64 d-flex flex-column align-items-center px-4 py-6 btn btn-success rounded text-uppercase"
            >
                <img
                    src="@/assets/images/upload.svg"
                    height="20"
                    width="20"
                    alt="Private upload file"
                />
                <span class="mt-2 text-base leading-normal">
                    {{ selectedFile ? file.name : "Seleccione archivo" }}
                </span>
                <input
                    id="application-uploader"
                    :disabled="isLoading"
                    type="file"
                    accept="image/*,application/pdf"
                    class="d-none"
                    @input="clickUploadButton($event)"
                />
            </label>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "PrivateFileUploader",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: Object,
            required: false,
            default: () => ({ name: "", url: "" })
        },
        autoApprove: {
            default: () => false,
            type: Boolean,
            required: false
        },
        autoLoader: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        url: "",
        error: "",
        isLoading: false,
        wasUploaded: false,
        selectedFile: false,
        file: { size: 0, name: "" }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    methods: {
        ...mapActions("files", ["getEndPointUploadPrivateFile", "uploadFile"]),
        async clickUploadButton(event) {
            const auxFile = event.target.files || event.dataTransfer.files;
            if (!auxFile.length) return;
            await this.uploadFile(auxFile[0]);
        },
        async uploadFile(file) {
            this.file = {
                size: file.size / 1048576,
                name: file.name
            };
            if (this.file.size > 40) {
                this.error = "Archivo Demasiado Pesado Máximo 40 MB";
                return;
            }
            this.error = "";

            if (!this.autoApprove) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: `Aseguras que ${this.file.name} es el archivo adecuado ?`,
                    icon: "info",
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "Si",
                    cancelButtonText: "No 😖",
                    confirmButtonColor: "#02913f",
                    cancelButtonColor: "#f07325",
                    allowOutsideClick: false
                });
                if (!dialogResult.isConfirmed) {
                    this.selectedFile = false;
                    return;
                }
            }

            this.isLoading = true;
            this.$emit("loading", true);
            if (this.autoLoader) {
                this.loaderInstance = this.$vs.loading({ type: "circles" });
            }
            try {
                const { url, urlSigned } =
                    await this.getEndPointUploadPrivateFile({
                        name: this.file.name,
                        size: this.file.size
                    });
                this.url = url;
                this.$emit("change", {
                    url,
                    name: this.file.name
                });
                await this.uploadFile({
                    url: urlSigned,
                    file
                });
                this.$emit("created", {
                    url,
                    name: this.file.name
                });
                this.wasUploaded = true;
            } catch (error) {
                if (this.loaderInstance) {
                    this.loaderInstance.close();
                }
                this.error = error.message;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.wasUploaded = false;
                this.file = { name: "", size: 0 };
            } finally {
                this.$emit("loading", false);
                if (this.loaderInstance) {
                    this.loaderInstance.close();
                }
                this.isLoading = false;
            }
        }
    }
};
</script>
