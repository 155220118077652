<template>
    <div class="border-bottom border-info w-100 py-2">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-lg-row flex-column">
                <div>
                    <router-link
                        v-if="product.id"
                        :to="`/app/products/detail/${product.id}`"
                        class="btn-a p-0"
                    >
                        <strong> SKU {{ product.sku }}</strong>
                    </router-link>
                    <strong v-else>SKU {{ product.sku }}</strong>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <small
                    v-if="product.updatedAt"
                    class="text-center font-weight-normal"
                >
                    📆 {{ product.updatedAt | date }}
                </small>
                <vs-button icon border circle @click="toggleActive">
                    {{ isActive ? "➰" : "🔰" }}
                </vs-button>
                <div v-if="enabled" id="actions" class="d-flex">
                    <vs-button danger icon @click="remove">🗑</vs-button>
                </div>
            </div>
        </div>
        <b-collapse ref="container" :visible="isActive" class="col-11 mx-auto">
            <div class="h5">
                <span>Datos del producto</span>
            </div>
            <div class="d-flex flex-wrap mb-3">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="productComponent.name"
                        border
                        shadow
                        :state="stateEnabled"
                        icon
                        label="Nombre"
                        :disabled="!enabled"
                        @blur="updateProduct"
                    />
                    <div v-if="product.currentName !== undefined">
                        <small>
                            El nombre actual es:
                            {{ product.currentName | cut(60) }}
                        </small>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="productComponent.shortDescription"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Descripción"
                        :disabled="!enabled"
                        @blur="updateProduct"
                    />
                    <div v-if="product.currentShortDescription !== undefined">
                        <small>
                            La descripción actual es:
                            {{ product.currentShortDescription | cut(60) }}
                        </small>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <brand-selector
                        v-model="productComponent.brand"
                        :enabled="enabled"
                        @change="updateProduct"
                    />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <line-selector
                        v-model="productComponent.subline"
                        :default-filters="{}"
                        :fill="false"
                        :enabled="enabled"
                        @change="updateProduct"
                    />
                </div>
            </div>
            <div v-if="productComponent.referencesToInsert.length">
                <span class="h5">
                    Referencias a insertar
                    <span class="text-primary">
                        {{ product.referencesToInsert.length }}
                    </span>
                </span>
                <reference-simple-manage
                    v-for="reference in productComponent.referencesToInsert"
                    :key="reference.barcode"
                    :reference="reference"
                    :enabled="enabled"
                    @remove="removeReferencesToInsert"
                    @update="updateReferencesToInsert"
                />
            </div>
            <div v-if="productComponent.referencesToUpdate.length">
                <span class="h5">
                    Referencias a actualizar
                    <span class="text-primary">
                        {{ product.referencesToUpdate.length }}
                    </span>
                </span>
                <reference-simple-manage
                    v-for="reference in productComponent.referencesToUpdate"
                    :key="reference.barcode"
                    :reference="reference"
                    :enabled="enabled"
                    @remove="removeReferenceToUpdate"
                    @update="updateReferenceToUpdate"
                />
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import BrandSelector from "@/components/brands/BrandSelector.vue";
import LineSelector from "@/components/lines/LineSelector.vue";
import ReferenceSimpleManage from "@/components/references/ReferenceSimpleManage.vue";

export default {
    name: "ProductWithReferencesManage",
    components: { LineSelector, BrandSelector, ReferenceSimpleManage },
    props: {
        product: {
            type: Object,
            required: true,
            default: () => ({
                id: null,
                name: "",
                shortDescription: ""
            })
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["update", "remove"],
    data: () => ({
        productComponent: {
            id: null,
            sku: "",
            name: "",
            shortDescription: "",
            currentName: "Tinte duvy class 1.0 negro natural",
            currentShortDescription: "Tinte duvy class 1.0 negro natural",
            referencesToInsert: [],
            referencesToUpdate: [],
            brand: { slug: "marca-blanca", name: "Marca Blanca" }
        },
        isActive: false
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        }
    },
    watch: {
        product(value) {
            this.setProduct(value);
        }
    },
    mounted() {
        this.setProduct(this.product);
    },
    methods: {
        removeReferenceToUpdate({ barcode }) {
            if (
                this.productComponent.referencesToUpdate.length === 1 &&
                !this.productComponent.referencesToInsert.length
            ) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "No puedes dejar productos sin referencias",
                    icon: "info"
                });
                return false;
            }
            const index = this.productComponent.referencesToUpdate.findIndex(
                (item) => item.barcode === barcode
            );
            let referencesToUpdate = [
                ...this.productComponent.referencesToUpdate
            ];
            referencesToUpdate.splice(index, 1);
            this.productComponent.referencesToUpdate = [...referencesToUpdate];
            this.updateProduct();
        },
        updateReferenceToUpdate(payload) {
            const index = this.productComponent.referencesToUpdate.findIndex(
                (item) => item.barcode === payload.barcode
            );
            let referencesToUpdate = [
                ...this.productComponent.referencesToUpdate
            ];
            referencesToUpdate[index] = {
                ...payload
            };
            this.productComponent.referencesToUpdate = [...referencesToUpdate];
            this.updateProduct();
        },
        removeReferencesToInsert({ barcode }) {
            if (
                this.productComponent.referencesToInsert.length === 1 &&
                !this.productComponent.referencesToUpdate.length
            ) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "No puedes dejar productos sin referencias",
                    icon: "info"
                });
                return false;
            }
            const index = this.productComponent.referencesToInsert.findIndex(
                (item) => item.barcode === barcode
            );
            let referencesToInsert = [
                ...this.productComponent.referencesToInsert
            ];
            referencesToInsert.splice(index, 1);
            this.productComponent.referencesToInsert = [...referencesToInsert];
            this.updateProduct();
        },
        updateReferencesToInsert(payload) {
            const index = this.productComponent.referencesToInsert.findIndex(
                (item) => item.barcode === payload.barcode
            );
            let referencesToInsert = [
                ...this.productComponent.referencesToInsert
            ];
            referencesToInsert[index] = {
                ...payload
            };
            this.productComponent.referencesToInsert = [...referencesToInsert];
            this.updateProduct();
        },
        remove() {
            this.$emit("remove", { sku: this.product.sku });
        },
        updateProduct() {
            this.$emit("update", this.productComponent);
        },
        setProduct(product) {
            this.productComponent = {
                ...product
            };
        },
        toggleActive() {
            this.isActive = !this.isActive;
        }
    }
};
</script>
