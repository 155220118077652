<template>
    <div class="w-100 h-100 d-flex flex-column">
        <vs-select
            v-if="enabled"
            id="blogTypes"
            v-model="option"
            label="Tipo de blog"
            shadow
            border
            name="itemCurrent"
            :state="stateInputDark"
            class="w-100 mw-100 border-custom"
            @input="onInput"
        >
            <vs-option
                v-for="(value, key) in $data.$enabledOptions"
                :key="key"
                :label="value"
                :value="key"
            >
                {{ value }}
            </vs-option>
        </vs-select>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> Tipo de blog </label>
            </div>
            <div class="d-flex px-2 align-items-center h-100">
                <span class="ml-2">
                    {{ $data.$enabledOptions[itemCurrent] }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { blogTypes } from "@/common/fields/blog-types";

export default {
    name: "BlogTypesSelector",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => "tutorial",
            type: String,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "",
        $enabledOptions: blogTypes
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemCurrent(value) {
            this.option = value;
        }
    },
    mounted() {
        this.option = this.itemCurrent;
    },
    methods: {
        onInput() {
            this.$emit("change", this.option);
        }
    }
};
</script>
