import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createBrand(
        { commit, dispatch },
        { description, images, keywords, name, shortDescription, slug }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/brands", {
                name,
                description,
                shortDescription,
                slug,
                keywords,
                images
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    },

    async listBrandsPaginated({ commit, dispatch }, { complete, page }) {
        try {
            const { data } = await Http.instance.get("/bo/brands", {
                params: { page, complete }
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    },

    async listAllBrands({ commit, dispatch }, { active, keyword = "" }) {
        try {
            const { data } = await Http.instance.get("/bo/brands/all", {
                params: { active, keyword }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    },

    async getBrandBySlug({ commit, dispatch }, { slug }) {
        try {
            const { data } = await Http.instance.get(`/bo/brands/${slug}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    },

    async updateBrand(
        { commit, dispatch },
        { active, description, images, keywords, name, shortDescription, slug }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/brands", {
                name,
                description,
                keywords,
                shortDescription,
                slug,
                images,
                active
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    },

    async deleteBrand({ commit, dispatch }, { slug }) {
        try {
            await Http.instance.delete(`/bo/brands/${slug}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    },

    async listHighlightBrands({ commit, dispatch }, { lineSlug }) {
        try {
            const { data } = await Http.instance.get("/bo/brands/highlight", {
                params: { lineSlug }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    },

    async updateHighlightBrands(
        { commit, dispatch },
        { brandsSlugs, lineSlug }
    ) {
        try {
            const { data } = await Http.instance.put("/bo/brands/highlight", {
                lineSlug,
                brandsSlugs
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BRANDS", parseError);
            throw parseError;
        }
    }
};

export default actions;
