import { deliveryOptions } from "@/common/fields/delivery-options";
import { deliveryStatusesCodes } from "@/common/fields/delivery-status-codes";
import { paymentMethodsAbbreviationReader } from "@/common/fields/payment-methods";
import { paymentStatusesCodes } from "@/common/fields/payment-status-codes";

export const slugify = (value = "") => {
    return value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replaceAll(".", "")
        .replaceAll(",", "")
        .replaceAll(" ", "-");
};

export const validateConfirmationMessage = (str = "") => {
    return str.toLowerCase() === "si";
};

export const applyFormatOrder = (item) => ({
    ...item,
    deliveryStatusMessage:
        deliveryStatusesCodes[item.deliveryStatus] || deliveryStatusesCodes.pnd,
    paymentStatusMessage:
        paymentStatusesCodes[item.paymentStatus] || paymentStatusesCodes.pnd,
    deliveryOptionMessage:
        deliveryOptions[item.deliveryOption] || deliveryOptions.home,
    paymentMethodMessage:
        paymentMethodsAbbreviationReader[item.paymentMethod] ||
        paymentMethodsAbbreviationReader.cashondelivery
});
