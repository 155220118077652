<template>
    <div class="w-100">
        <div v-if="itemsCurrent.length">
            <div
                v-for="(item, index) in itemsCurrent"
                :key="item.id"
                class="w-100 d-flex mb-4 border rounded border-info h-28"
            >
                <div
                    class="d-flex justify-content-center align-items-center bg-info rounded h-100 col-2 col-lg-1 text-white"
                >
                    <strong>
                        {{
                            `${
                                item.url
                                    .split("")
                                    .reverse()
                                    .join("")
                                    .split(".")[0]
                            }`
                                .split("")
                                .reverse()
                                .join("")
                        }}
                    </strong>
                </div>
                <div class="w-100 px-2 py-2 position-relative col-10 col-lg-11">
                    <a
                        :href="`/app/files/detail/${item.id}`"
                        target="_blank"
                        class="text-primary"
                        rel="noopener"
                    >
                        <p>{{ item.alt | cut(40) }}</p>
                    </a>
                    <a :href="item.url" target="_blank" rel="noopener">
                        <small> URL </small>
                    </a>
                    <div
                        class="position-absolute -top-1 right-0 d-flex bg-custom"
                    >
                        <button
                            v-if="enabled"
                            class="btn len-3 d-flex align-items-center justify-content-center"
                            :disabled="index === 0"
                            @click="moveOrder(index, index - 1)"
                        >
                            ⬆
                        </button>
                        <div
                            class="bg-info len-3 rounded-circle d-flex align-items-center justify-content-center"
                        >
                            <small> {{ item.id }} </small>
                        </div>
                        <button
                            v-if="enabled"
                            class="btn len-3 d-flex align-items-center justify-content-center"
                            :disabled="index >= itemsCurrent.length - 1"
                            @click="moveOrder(index, index + 1)"
                        >
                            ⬇
                        </button>
                    </div>
                    <div class="position-absolute -bottom-1 right-0">
                        <button
                            v-if="enabled"
                            class="btn btn-danger len-3 d-flex align-items-center justify-content-center"
                            @click="deleteFile(index)"
                        >
                            🗑️
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-10 mx-auto py-3">
            <span class="h4"> No se han asociado archivos 0️⃣.</span>
        </div>

        <div class="w-100">
            <vs-tooltip v-if="enabled && $enabledEcommerceOptions" border>
                <button
                    class="w-100 btn btn-outline-info rounded h-24 d-flex justify-content-center align-items-center"
                    @click="openNewItem"
                >
                    <h2>➕</h2>
                </button>
                <template #tooltip> Agregar archivos</template>
            </vs-tooltip>
        </div>
        <div>
            <vs-dialog
                v-model="selectFile"
                width="80vw"
                scroll
                :loading="isLoading"
                :prevent-close="isLoading"
            >
                <template #header>
                    <div class="mt-2">
                        <h3 v-show="!isOpenFileCreator">
                            Seleccione los archivos
                        </h3>
                        <h3 v-show="isOpenFileCreator">Crear nuevo archivo</h3>
                    </div>

                    <div class="d-flex">
                        <vs-button
                            v-show="!isOpenFileCreator && selectedFiles.length"
                            icon
                            circle
                            @click="pushNewFile"
                        >
                            ✔️
                        </vs-button>
                        <vs-tooltip
                            v-if="$ability.hasScope('file:Create')"
                            border
                            bottom
                        >
                            <vs-button
                                v-if="$ability.hasScope('file:Update')"
                                circle
                                icon
                                floating
                                @click="isOpenFileCreator = !isOpenFileCreator"
                            >
                                {{ isOpenFileCreator ? "🔙" : "➕" }}
                            </vs-button>
                            <template #tooltip>
                                <span>
                                    {{
                                        isOpenFileCreator
                                            ? "Lista de archivos"
                                            : "Cargar nuevo archivo"
                                    }}
                                </span>
                            </template>
                        </vs-tooltip>
                    </div>
                </template>
                <div class="con-content">
                    <div v-if="isOpenFileCreator">
                        <file-uploader
                            v-model="file"
                            type="productFile"
                            auto-approve
                            required-alt
                            @loading="onLoading"
                            @created="selectionUploadFile"
                        />
                    </div>
                    <div v-else>
                        <div
                            class="d-flex justify-content-between flex-column flex-lg-row col-12"
                        >
                            <div
                                class="my-3 d-flex align-items-center rounded-circle justify-content-around"
                            >
                                <vs-input
                                    v-model="keyword"
                                    border
                                    shadow
                                    type="text"
                                    placeholder="Buscar .."
                                    class="mr-2"
                                    @click-icon="advanceSearch"
                                    @keyup.enter="advanceSearch"
                                >
                                    <template #icon> 🔎 </template>
                                </vs-input>
                                <vs-button
                                    circle
                                    icon
                                    floating
                                    @click="reloadItems"
                                >
                                    <reload-icon />
                                </vs-button>
                            </div>

                            <vs-pagination
                                v-model="currentPage"
                                :length="pagesNumber"
                                @input="listItems()"
                            />
                        </div>
                        <div
                            v-if="filesList.length"
                            class="w-100 d-flex flex-wrap"
                        >
                            <div
                                v-for="(item, index) in filesList"
                                :key="index"
                                class="col-12 mt-3"
                            >
                                <button
                                    class="btn rounded p-0 w-100 bg-info d-flex h-10 align-items-center text-white"
                                    :class="
                                        selectedFiles.includes(item.id)
                                            ? `${borderCardDark} border-4`
                                            : 'border'
                                    "
                                    @click="selectionFile(item.id)"
                                >
                                    <strong class="px-2 col-1">
                                        {{
                                            `${
                                                item.url
                                                    .split("")
                                                    .reverse()
                                                    .join("")
                                                    .split(".")[0]
                                            }`
                                                .split("")
                                                .reverse()
                                                .join("")
                                        }}
                                    </strong>

                                    <div class="d-flex col-10 text-left">
                                        <small>
                                            {{ item.alt || item.url }}
                                        </small>
                                    </div>
                                    <a
                                        :href="`/app/files/detail/${item.id}`"
                                        target="_blank"
                                        rel="noopener"
                                        class="col-1"
                                    >
                                        🖊️
                                    </a>
                                </button>
                            </div>
                        </div>
                        <div v-else class="col-11 mx-auto mt-4">
                            <strong>
                                Nuestro sistema NO encuentra resultados. Intenta
                                modificar los filtros de búsqueda.
                            </strong>
                        </div>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import FileUploader from "@/components/utils/FileUploader.vue";

export default {
    name: "FilesManage",
    components: {
        FileUploader,
        ReloadIcon
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [
                {
                    alt: "alt",
                    id: 1,
                    sequence: { sequence: 1 },
                    private: false,
                    url: ""
                }
            ],
            required: true
        },
        id: {
            type: String,
            default: () => "0",
            required: true
        },
        message: {
            type: String,
            default: () => "product",
            required: false
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        selectFile: false,
        selectedFiles: [],
        isLoading: false,
        currentPage: 1,
        pagesNumber: 0,
        isOpenFileCreator: false,
        filesList: [],
        showTable: false,
        itemsNumber: 0,
        file: { id: null, name: "" },
        backup: {
            items: [],
            pagination: { pagesNumber: 1, itemsNumber: 1 }
        },
        keyword: ""
    }),
    computed: {
        ...mapState("files", ["files"]),
        ...mapGetters("control", ["backgroundColor", "borderCardDark"])
    },
    watch: {
        keyword(value) {
            if (value) {
                this.filesList = this.files.filter((item) =>
                    item.alt.includes(value.toLowerCase())
                );
                this.itemsNumber = this.filesList.length;
                this.pagesNumber = 1;
                this.currentPage = 1;
            } else {
                this.filesList = [...this.backup.items];
                this.itemsNumber = Number(this.backup.pagination.itemsNumber);
                this.pagesNumber = Number(this.backup.pagination.pagesNumber);
            }
        }
    },
    mounted() {},
    methods: {
        ...mapActions("files", ["listFilesPaginated"]),
        async openNewItem() {
            this.selectFile = true;
            this.listItems(true);
        },
        async loadMoreFiles() {
            this.currentPage = this.currentPage + 1;
            await this.listItems();
        },
        async reloadItems() {
            this.keyword = "";
            this.currentPage = 1;
            await this.listItems(true);
        },
        async advanceSearch() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        async listItems(complete = false) {
            this.isLoading = true;
            try {
                const { items, pagination } = await this.listFilesPaginated({
                    page: this.currentPage,
                    keyword: this.keyword,
                    complete
                });
                this.filesList = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.backup = {
                    items,
                    pagination: {
                        itemsNumber: Number(this.itemsNumber),
                        pagesNumber: Number(this.pagesNumber)
                    }
                };
                this.showTable = true;
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        moveOrder(fromIndex, toIndex) {
            let arrAux = [...this.itemsCurrent];
            const aux = arrAux[fromIndex];
            arrAux.splice(fromIndex, 1);
            arrAux.splice(toIndex, 0, aux);
            const filesResult = arrAux.map((item, index) => ({
                ...item,
                sequence: { sequence: index }
            }));
            this.$emit("change", filesResult);
        },
        selectionFile(id) {
            let fromIndex = this.selectedFiles.findIndex((item) => item === id);
            if (fromIndex === -1) {
                this.selectedFiles.push(id);
            } else {
                this.selectedFiles.splice(fromIndex, 1);
                let arrAux = [...this.itemsCurrent];
                fromIndex = arrAux.findIndex((item) => item === id);
                if (fromIndex !== -1) {
                    arrAux.splice(fromIndex, 1);
                    this.$emit("change", arrAux);
                }
            }
        },
        pushNewFile() {
            let filesAux = this.filesList.filter((i) =>
                this.selectedFiles.includes(i.id)
            );
            if (filesAux.length) {
                const filesResult = [...this.itemsCurrent];

                filesAux = filesAux.map((item, index) => ({
                    ...item,
                    sequence: { sequence: this.itemsCurrent.length + index }
                }));
                filesAux = [...filesResult, ...filesAux];

                filesAux = filesAux.filter(
                    (value, index, self) =>
                        self.findIndex((item) => item.id === value.id) === index
                );
                this.selectFile = false;
                this.$emit("change", filesAux);
            }
        },
        deleteFile(fromIndex) {
            let filesResult = [...this.itemsCurrent];
            filesResult.splice(fromIndex, 1);
            this.$emit("change", filesResult);
        },
        selectionUploadFile(data) {
            this.filesList.unshift({ ...data, uploaded: false });
            this.selectedFiles.unshift(data.id);
            this.isOpenFileCreator = false;
        },
        onLoading(value) {
            this.isLoading = value;
        }
    }
};
</script>
