<template>
    <div>
        <div class="row flex-column">
            <div class="text-center">
                <h1>Registro de producto</h1>
            </div>
            <div class="text-justify col-12">
                <span>
                    En esta sección, tienes la opción de crear un nuevo producto
                    al completar solamente los campos esenciales, además de
                    agregar una referencia predeterminada adicional. Este
                    producto se registrará como marca blanca y será clasificado
                    dentro de la línea "otros", junto con otros valores
                    adicionales por defecto.
                </span>
                <span v-if="$enabledEcommerceOptions">
                    El producto no se mostrará en la página principal. Para
                    habilitar su visibilidad, deberás activalo en la pantalla de
                    detalles del producto, una vez creado el producto.
                </span>

                <vs-checkbox
                    v-if="selectedMerchant.merchantId"
                    v-model="needRecordInventory"
                    warn
                    label-before
                >
                    <span style="font-size: 1rem">
                        Registrar el inventario para la
                        {{
                            selectedMerchant.merchantType | merchantInfo("type")
                        }}
                        seleccionada en la parte superior
                        <span class="text-primary">
                            "{{ selectedMerchant.name }}"
                        </span>
                        &nbsp;
                    </span>
                </vs-checkbox>
                <vs-checkbox v-model="needRoutes" warn label-before>
                    <span style="font-size: 1rem">
                        Asociar a marca y línea &nbsp;
                    </span>
                </vs-checkbox>
            </div>
        </div>

        <div class="d-flex flex-wrap">
            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    id="productName"
                    v-model="productComponent.name"
                    border
                    state="primary"
                    shadow
                    icon
                    label="Nombre del producto"
                    @blur="blurName"
                >
                    <template
                        v-if="!isValidName & introValidators.name"
                        #message-danger
                    >
                        Nombre invalido
                    </template>
                </vs-input>
            </div>
            <div class="col-12 col-md-6 mt-5">
                <barcode-scanner-input
                    v-model="productComponent.barcode"
                    enabled
                    check
                />
            </div>
            <div class="col-12 col-md-6 mt-5">
                <input-money-component
                    v-model="productComponent.price"
                    enabled
                    border
                    label="Precio"
                    :message-error="
                        !isValidPrice & introValidators.price
                            ? 'Precio no puede ser $0'
                            : ''
                    "
                    @blur="blurPrice"
                />
            </div>
            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="productComponent.size"
                    border
                    state="primary"
                    shadow
                    icon
                    label="Tamaño/Talla/Valor"
                />
            </div>
            <fragment v-if="needRecordInventory">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="inventoryComponent.stock"
                        border
                        shadow
                        state="primary"
                        type="number"
                        min="1"
                        label="Stock"
                        @input="calculateUnitCost"
                    >
                        <template #message-success>
                            <div v-show="messageCost">
                                <span>{{ messageCost }} </span>
                            </div>
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5 d-flex align-items-center">
                    <input-money-component
                        v-model="costTemp"
                        enabled
                        border
                        :label="
                            isInputCostUnit
                                ? 'Costo de unidad'
                                : 'Costo de lote'
                        "
                        :message-error="
                            !isValidCost & introValidators.cost
                                ? 'Costo de unidad debe estar entre $0 y el precio'
                                : ''
                        "
                        class="w-100 mw-100"
                        @blur="validateCost"
                    />
                    <vs-tooltip>
                        <vs-button
                            icon
                            border
                            :active="isInputCostUnit"
                            @click="updateUnitCostSw"
                        >
                            {{ isInputCostUnit ? "1️⃣" : "🔢" }}
                        </vs-button>
                        <template #tooltip>
                            <div class="content-tooltip">
                                <span v-if="isInputCostUnit">
                                    Registrar con costo de lote
                                </span>
                                <span v-else>
                                    Registrar con costo de unidad
                                </span>
                            </div>
                        </template>
                    </vs-tooltip>
                </div>
            </fragment>
            <fragment v-if="needRoutes">
                <div class="col-12 col-md-6 mt-5">
                    <brand-selector v-model="brand" enabled fill />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <subline-selector
                        v-model="subline"
                        :default-filters="{}"
                        enabled
                        fill
                    />
                </div>
            </fragment>
            <div class="col-12 mt-3">
                <images-manage
                    :id="'0'"
                    v-model="images"
                    message="de producto"
                    enabled
                />
            </div>
        </div>

        <div
            class="row mt-3 mb-5 flex-column align-items-center justify-content-center"
        >
            <vs-button @click="createProductFastComponent">
                Registrar producto
            </vs-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { titleRegex } from "@/common/lib/regex";

import BrandSelector from "@/components/brands/BrandSelector.vue";
import SublineSelector from "@/components/lines/SublineSelector.vue";
import BarcodeScannerInput from "@/components/utils/BarcodeScannerInput.vue";
import ImagesManage from "@/components/utils/ImagesManage.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ProductFastCreate",
    components: {
        ImagesManage,
        BrandSelector,
        SublineSelector,
        InputMoneyComponent,
        BarcodeScannerInput
    },
    data: () => ({
        needRoutes: false,
        needRecordInventory: false,
        costTemp: 0,
        images: [],
        messageCost: "",
        isLoading: true,
        loaderInstance: null,
        isInputCostUnit: true,
        brand: { name: "", slug: "" },
        subline: { name: "", slug: "" },
        productComponent: {
            name: "",
            size: "Unico",
            price: 1000,
            barcode: ""
        },
        inventoryComponent: {
            cost: 0,
            stock: 1
        },
        introValidators: {
            name: false,
            cost: false,
            price: false,
            stock: false
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"]),
        isValidName() {
            return titleRegex.test(this.productComponent.name);
        },
        isValidPrice() {
            return this.productComponent.price > 0;
        },
        isValidCost() {
            return (
                this.inventoryComponent.cost < this.productComponent.price &&
                this.inventoryComponent.cost > 0
            );
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("product:Record", "/app/products");
    },
    beforeDestroy() {
        this.$swal.close();
    },
    methods: {
        ...mapActions("products", ["createProductFast"]),
        async createProductFastComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const result = await this.createProductFast({
                    ...this.productComponent,
                    images: this.images.map((item) => ({
                        id: item.id,
                        sequence: item.sequence.sequence
                    })),
                    ...{
                        ...(this.needRecordInventory
                            ? {
                                  ...this.inventoryComponent,
                                  merchantId: this.selectedMerchant.merchantId,
                                  merchantType:
                                      this.selectedMerchant.merchantType
                              }
                            : null)
                    },
                    ...{
                        ...(this.needRoutes
                            ? {
                                  brandSlug: this.brand.slug,
                                  lineSlug: this.subline.slug
                              }
                            : null)
                    }
                });
                this.loaderInstance.close();
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: `Producto creado con el ID: ${result.id}`,
                    text: "Deseas ir al detalle del producto registrado o continuar subiendo productos ?",
                    icon: "success",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp"
                    },
                    focusConfirm: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de productos",
                    cancelButtonText: "Nuevo producto",
                    allowOutsideClick: false
                });
                if (dialogResult.isConfirmed) {
                    this.$router.push(`/app/products/detail/${result.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/products/");
                }
                if (dialogResult.isDismissed) {
                    this.resetItem();
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        validateData() {
            this.introValidators = {
                name: true,
                cost: true,
                price: true
            };

            const verifyInventory = this.needRecordInventory
                ? this.isValidCost
                : true;

            const toComplete = [];
            verifyInventory;
            if (!this.isValidName) {
                toComplete.push("Nombre");
            }

            if (!this.isValidPrice) {
                toComplete.push("Precio");
            }

            if (!verifyInventory) {
                toComplete.push("Costo");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Producto invalido",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        validateCost() {
            this.introValidators.cost = true;
            this.calculateUnitCost();
        },
        updateUnitCostSw() {
            if (this.isInputCostUnit) {
                this.costTemp = this.costTemp * this.inventoryComponent.stock;
            } else {
                this.costTemp = this.costTemp / this.inventoryComponent.stock;
            }
            this.isInputCostUnit = !this.isInputCostUnit;
            this.calculateUnitCost();
        },
        calculateUnitCost() {
            let costUnit = 0;
            if (this.isInputCostUnit) {
                costUnit = +this.costTemp;
                this.inventoryComponent.cost = +costUnit;
                this.messageCost = `Costo de lote ${this.$options.filters.money(
                    costUnit * this.inventoryComponent.stock
                )}`;
            } else {
                costUnit = this.costTemp / this.inventoryComponent.stock;
                this.inventoryComponent.cost = +costUnit;
                this.messageCost = `Costo de unidad ${this.$options.filters.money(
                    costUnit
                )}`;
            }
        },
        resetItem() {
            this.productComponent = {
                name: "",
                size: "Unico",
                price: 0,
                barcode: ""
            };
            this.introValidators = {
                name: false,
                cost: false,
                price: false,
                stock: false
            };
            this.images = [];
            this.unitCost = "";
        },
        blurName() {
            this.introValidators.name = true;
        },
        blurPrice() {
            this.introValidators.price = true;
            if (!this.costTemp) {
                this.costTemp = parseInt(this.productComponent.price * 0.7);
                this.calculateUnitCost();
            }
        }
    }
};
</script>
