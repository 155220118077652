<template>
    <main class="mb-5 pb-5">
        <div class="my-10 pb-10">
            <h1 class="h2">
                Detalles de usuario #{{ itemComponent.id }} /
                <strong class="text-primary">
                    {{ itemComponent.firstName }}
                    {{ itemComponent.lastName }}
                </strong>
            </h1>
        </div>
        <vs-navbar
            v-if="id"
            id="tabs-products"
            v-model="activeTab"
            center-collapsed
            not-line
            class="position-static"
        >
            <template #left>
                <vs-navbar-item id="home">
                    <router-link
                        to="/app/users"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                </vs-navbar-item>
                <vs-navbar-item
                    id="general"
                    :active="['general', 'home'].includes(activeTab)"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none">
                            información general
                        </span>
                        <span>&nbsp;⚙</span>
                    </div>
                </vs-navbar-item>

                <vs-navbar-item
                    id="security"
                    :active="activeTab === 'security'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> Seguridad </span>
                        <span>&nbsp;🔐</span>
                    </div>
                </vs-navbar-item>
                <vs-navbar-item
                    id="merchants"
                    :active="activeTab === 'merchants'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-lg-block d-none"> Comercios </span>
                        <span>&nbsp;🌃</span>
                    </div>
                </vs-navbar-item>
            </template>
            <template #right>
                <vs-switch
                    v-if="$ability.hasScope('user:Update')"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </template>
        </vs-navbar>

        <div v-show="['general', 'home'].includes(activeTab)">
            <h3 class="text-success my-3">Datos básicos 👩‍🦱</h3>
            <div class="row mt-5">
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.email"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Correo electrónico"
                        disabled
                    />
                </div>
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.uid"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Id externo"
                        disabled
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.firstName"
                        border
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        shadow
                        label="Nombres"
                        :disabled="!enabledUpdate"
                    />
                </div>
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.lastName"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Apellidos"
                        :disabled="!enabledUpdate"
                    />
                </div>
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <identification-type-selector
                        v-model="itemComponent.identificationType"
                        :enabled="enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.identificationNumber"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Número de Identificación"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.phone"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Celular de contacto"
                        type="number"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <city-code-selector
                        v-model="itemComponent.cityCode"
                        :enabled="enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.address"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Dirección"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.addressComplement"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Complemento de dirección"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.neighborhood"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Barrio"
                        :disabled="!enabledUpdate"
                    />
                </div>
                <div class="col-12 col-md-6 my-4 mx-auto">
                    <checkbox-selector
                        v-model="itemComponent.needOnboarding"
                        label="Necesita onboarding"
                        :enabled="enabledUpdate"
                    />
                </div>
            </div>
            <div class="d-flex justify-content-center my-4">
                <vs-button
                    :disabled="!enabledUpdate"
                    @click="updateBasicDataUser"
                >
                    Actualizar información básica
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'merchants'">
            <h3 class="text-success my-3">Alamcénes asociados 🌃</h3>
            <div class="col-8 pt-4">
                <merchants-role-selector
                    v-model="itemComponent.merchants"
                    :enabled="enabledUpdate"
                />
            </div>
            <div
                v-if="
                    $ability.hasScope('user:UpdateMerchants') && enabledUpdate
                "
                class="col-12 d-flex justify-content-center"
            >
                <vs-button
                    :loading="isLoadingMerchants"
                    class="mt-4"
                    @click="updateMerchants"
                >
                    Actualizar accesos a alamcéns
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'security'">
            <h3 class="text-success my-3">Seguridad 🔐</h3>
            <div class="my-4">
                en esta sección puedes modficar los accesos de un usuario.
                Puedes modificar los roles de un usuario y reestablecer
                contraseña.
            </div>
            <h4>Roles</h4>
            <div class="col-8 pt-4">
                <role-selector
                    v-model="itemComponent.roles"
                    :enabled="enabledUpdate"
                />
            </div>
            <div
                v-if="$ability.hasScope('user:UpdateRoles') && enabledUpdate"
                class="col-12 d-flex justify-content-center"
            >
                <vs-button
                    :loading="isLoadingRoles"
                    class="mt-4"
                    @click="updateRoles"
                >
                    Actualizar roles
                </vs-button>
            </div>
            <div class="d-flex mt-4">
                <h4 class="mr-3">Permisos</h4>
                <vs-button circle icon floating @click="reloadScopes">
                    <reload-icon />
                </vs-button>
            </div>
            <permissions-table
                v-show="scopes.length"
                :items="scopes"
                show-role
            />
        </div>
    </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { validateConfirmationMessage } from "@/common/lib/transformers";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import MerchantsRoleSelector from "@/components/merchants/MerchantsRoleSelector.vue";
import PermissionsTable from "@/components/permissions/PermissionsTable.vue";
import RoleSelector from "@/components/roles/RoleSelector.vue";
import IdentificationTypeSelector from "@/components/users/IdentificationTypeSelector.vue";
import CheckboxSelector from "@/components/utils/CheckboxSelector.vue";
import CityCodeSelector from "@/components/utils/CityCodeSelector.vue";

export default {
    name: "DetailUsers",
    components: {
        ReloadIcon,
        IdentificationTypeSelector,
        CityCodeSelector,
        PermissionsTable,
        MerchantsRoleSelector,
        RoleSelector,
        CheckboxSelector
    },
    data: () => ({
        itemComponent: {
            id: "4",
            uid: "4",
            needOnboarding: false,
            identificationType: "cc",
            identificationNumber: "...",
            firstName: "...",
            lastName: "...",
            cityCode: "11001",
            address: "...",
            neighborhood: "...",
            email: "name@domain.com",
            phone: "...",
            checked: true,
            active: true,
            roles: [],
            merchants: []
        },
        isLoadingRoles: false,
        isLoadingMerchants: false,
        loaderInstance: null,
        activeTab: "general",
        enabledUpdate: false,
        isLoading: false,

        scopes: [],
        id: "0"
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("user:Get", "/app/users"))) return;
            const id = this.$route.params.id;
            this.id = id;
            await this.getBaseUser(id);
        }
    },
    methods: {
        ...mapActions("users", [
            "updateUser",
            "getUserById",
            "updateUserRoles",
            "updateUserMerchants",
            "getUserScopesById"
        ]),
        async getBaseUser(id) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "points" });
            try {
                this.itemComponent = await this.getUserById({ id });
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateBasicDataUser() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "points" });
            try {
                await this.updateUser(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async updateRoles() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                icon: "info",
                title: "Estas a punto de cambiar los roles y por ende los permisos de un usuario.",
                text: "Recuerda un usuario solo puede tener dos roles y no se puede asignar rol super, eres consiente de lo que estas haciendo ?. Escribe 'Si' para actualizar",
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (
                dialogResult.isConfirmed &&
                validateConfirmationMessage(dialogResult.value)
            ) {
                this.loaderInstance = this.$vs.loading({ type: "points" });
                try {
                    await this.updateUserRoles({
                        userId: this.itemComponent.id,
                        rolesSlugs: this.itemComponent.roles.map(
                            (item) => item.slug
                        )
                    });
                    this.isLoading = false;
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("success");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Actualización exitosa",
                        icon: "success",
                        showCloseButton: true
                    });
                } catch (error) {
                    this.isLoading = false;
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                }
            }
        },
        async updateMerchants() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                icon: "info",
                title: `Estas a punto de actualizar el control del usuario  sobre un comercio.`,
                text: `Recuerda si el usuario tiene perfil admin o super no se necesita permiso sobre cada comercio. Escribe "Si" para confirmar.`,
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (
                dialogResult.isConfirmed &&
                validateConfirmationMessage(dialogResult.value)
            ) {
                this.loaderInstance = this.$vs.loading({ type: "points" });
                try {
                    await this.updateUserMerchants({
                        userId: this.itemComponent.id,
                        merchants: this.itemComponent.merchants.map(
                            ({ merchantId, merchantType }) => ({
                                merchantId,
                                merchantType
                            })
                        )
                    });
                    this.isLoading = false;
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("success");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Actualización exitosa",
                        icon: "success",
                        showCloseButton: true
                    });
                } catch (error) {
                    this.isLoading = false;
                    this.loaderInstance.close();
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                }
            }
        },
        async reloadScopes() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                const scopes = await this.getUserScopesById({
                    id: this.id
                });
                this.scopes = scopes.map((item) => ({
                    name: item.permissionName,
                    slug: item.permissionSlug,
                    role: item.roleName
                }));
                this.loaderInstance.close();
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
