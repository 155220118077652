<template>
    <div
        class="w-ticket d-flex flex-column align-items-center justify-content-center"
    >
        <div
            id="barcode-container"
            class="bg-white text-black d-flex flex-column align-items-center py-2 px-2"
        >
            <slot name="top"></slot>
            <barcode-viewer class="w-100" :input="barcode" />
            <slot name="bottom"></slot>
        </div>

        <vs-button class="mt-3" color="#02913f" relief @click="generateImage">
            <span class="py-1"> Descargar Imagen </span>
        </vs-button>
    </div>
</template>

<script>
import { downLoadImage, elementToImage } from "@/common/lib/images";

import BarcodeViewer from "@/components/utils/BarcodeViewer.vue";

export default {
    name: "BarcodeGenerator",
    components: { BarcodeViewer },
    props: {
        barcode: {
            default: () => "",
            required: true,
            type: String
        },
        label: {
            default: () => "",
            required: false,
            type: String
        }
    },
    methods: {
        async generateImage() {
            const imgData = await elementToImage(
                document.getElementById("barcode-container")
            );
            downLoadImage(imgData.image, `barcode_${this.barcode}`);
        }
    }
};
</script>
