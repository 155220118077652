<template>
    <div class="h-100">
        <div class="d-flex flex-wrap">
            <div class="col-12 offset-lg-1 col-lg-10 mb-2 text-center">
                <h1 class="h3">
                    Registrar inventario para
                    {{ selectedMerchant.merchantType | merchantInfo("type") }}
                    <strong class="text-primary">
                        {{ selectedMerchant.name }}
                    </strong>
                </h1>
            </div>
            <div class="col-12 col-lg-1 d-flex justify-content-center">
                <vs-tooltip border>
                    <vs-button
                        icon
                        border
                        :active="enableHelp"
                        @click="enableHelp = !enableHelp"
                    >
                        ℹ️
                    </vs-button>
                    <template #tooltip>
                        <small>
                            ¿ Necesitas ayuda para registrar inventario ?
                        </small>
                    </template>
                </vs-tooltip>
            </div>
        </div>

        <div v-if="enableHelp" class="d-flex flex-wrap mt-2">
            <div class="col-10 mx-auto rounded border border-success py-4">
                <span>
                    En esta sección puedes registrar inventario para tu
                    comercio.
                </span>
                <ul class="list-group">
                    <li class="py-2 px-1">
                        1. Busca los productos que deseas agregar. Puedes
                        utilizar el buscador ubicado en la parte inferior o
                        escanear el código de barras del producto.
                    </li>
                    <li class="py-2 px-1">
                        2. Ingresa las cantidades de producto que deseas
                        agregar, el costo correspondiente de dichas cantidades y
                        de ser necesario el precio de venta.
                    </li>
                    <li class="py-2 px-1">
                        3. Haz click en el botón que se habilitará "Registrar
                        inventario".
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="!isOnboarding" class="d-flex flex-wrap">
            <div
                v-if="$ability.hasScope('cashOutput:Create')"
                class="my-2 col-12 col-md-4"
            >
                <vs-checkbox v-model="needCashOutput" warn label-before>
                    <span> Crear un gasto</span>
                </vs-checkbox>
            </div>
        </div>
        <div v-else class="text-justify">
            <span>
                Es momento de agregar inventario a tu nueva tienda. Ingresa en
                el buscador de la parte inferior la palabra
                <strong> "cuaderno" </strong>. Selecciona una de las opciones
                que se deesplegará. Ingresa el valor al que quieres vender el
                producto y lo que te ha costado, nosotros te recomendaremos un
                precio. Finalmete da click en el botón "Registrar inventario".
            </span>
        </div>

        <div v-if="needCashOutput" class="d-flex flex-wrap">
            <div
                class="col-12 d-flex flex-column flex-lg-row justify-content-between"
            >
                <payment-methods-checker v-model="sourcePay" mode="output">
                    <template #title>
                        <span class="font-weight-bold"> Método de pago </span>
                    </template>
                </payment-methods-checker>
            </div>

            <div class="col-12 col-md-6 mt-3">
                <div class="d-flex align-items-center justify-content-between">
                    <strong>Concepto</strong>
                    <vs-tooltip border>
                        <vs-button icon circle border> ℹ </vs-button>
                        <template #tooltip> Resume lo que compraste</template>
                    </vs-tooltip>
                </div>
                <vs-input
                    v-model="concept"
                    border
                    type="text"
                    name="concept"
                    placeholder="Compras distri innovalombia enero"
                    class="w-100"
                    @focus="onFocusSomeInput"
                    @blur="onBlurConcept"
                >
                    <template
                        v-if="!isValidConcept & introValidators.concept"
                        #message-danger
                    >
                        El cocepto debe tener mínimo un caracter
                    </template>
                </vs-input>
            </div>
            <div class="col-12 col-md-6 mt-3">
                <div class="d-flex align-items-center justify-content-between">
                    <strong>Proveedor</strong>
                    <vs-tooltip border>
                        <vs-button icon circle border> ℹ </vs-button>
                        <template #tooltip> Nombre de tu proveedor </template>
                    </vs-tooltip>
                </div>
                <vs-input
                    v-model="provider"
                    border
                    type="text"
                    placeholder="Distri Innovalombia"
                    name="provider"
                    class="w-100"
                    @focus="onFocusSomeInput"
                    @blur="onBlurProvider"
                >
                </vs-input>
            </div>
            <div v-if="$enabledEcommerceOptions" class="col-12 mt-3">
                <private-file-uploader v-model="receiptFile" auto-loader />
            </div>
        </div>

        <div
            class="d-flex flex-wrap flex-column mt-3 justify-content-center align-items-center"
        >
            <span class="text-center font-weight-bold">
                Selecciona las referencias que vas a ingresar al inventario
            </span>
            <div class="mt-3 w-100">
                <inventories-search-scanner
                    :enabled-scanner="enabledScanner"
                    :auto-focus="isOnboarding"
                    @input="validatePushReferences"
                />
            </div>
        </div>

        <div class="d-flex flex-wrap z-0 mt-3 h-min-96">
            <h4 v-show="references.length" class="mx-2 mt-3">Referencias</h4>
            <reference-inventory-items
                v-for="(reference, index) in references"
                :key="index"
                class="col-md-11 col-12 mx-auto"
                required-price
                :reference="reference"
                @update="changeReference"
                @remove="removeReference"
                @focus="onFocusSomeInput"
                @blur="onBlurSomeInput"
            />
        </div>

        <div v-if="references.length > 0" class="d-flex flex-wrap z-0 mt-4">
            <div class="col-10 mx-auto d-flex justify-content-end py-3">
                <span class="h3">Valor referencias {{ total | money }}</span>
            </div>
            <vs-button
                class="mt-3 mb-5 col-9 mx-auto"
                border
                gradient
                @click="createInventoryComponent"
            >
                <span class="h3"> Registrar inventario </span>
            </vs-button>
        </div>

        <div
            v-if="isOnboarding"
            class="d-flex justify-content-center my-3 link-secondary"
        >
            <router-link to="/app"> Omitir </router-link>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { titleRegex } from "@/common/lib/regex";

import InventoriesSearchScanner from "@/components/inventories/InventoriesSearchScanner.vue";
import PaymentMethodsChecker from "@/components/orders/PaymentMethodsChecker.vue";
import ReferenceInventoryItems from "@/components/references/ReferenceInventoryItems.vue";
import PrivateFileUploader from "@/components/utils/PrivateFileUploader.vue";

export default {
    name: "NewInventory",
    components: {
        PrivateFileUploader,
        ReferenceInventoryItems,
        InventoriesSearchScanner,
        PaymentMethodsChecker
    },
    data: () => ({
        loaderInstance: null,
        provider: "",
        concept: "",
        needCashOutput: false,
        enabledScanner: false,
        isOnboarding: false,
        total: 0,
        moneyBack: 0,
        receiptFile: { url: "", name: "" },
        sourcePay: "cash",
        enableHelp: false,
        references: [],
        introValidators: { provider: false, concept: false }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"]),
        isValidProvider() {
            return titleRegex.test(this.provider);
        },
        isValidConcept() {
            return titleRegex.test(this.concept);
        }
    },
    created() {
        this.isOnboarding = this.$route.path.includes("onboarding");
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (
            !(await verifyPageScope(
                "inventory:Create",
                "/app/inventories",
                true
            ))
        )
            return;

        this.enabledScanner = !this.isOnboarding;
    },
    methods: {
        ...mapActions("inventories", ["createManyInventories"]),
        async createInventoryComponent() {
            if (!this.validateData()) {
                return;
            }

            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const result = await this.createManyInventories({
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType,
                    references: this.references.map((item) => ({
                        referenceId: item.id,
                        quantity: parseInt(item.quantity),
                        costNew: item.costNew,
                        priceNew: item.priceNew
                    })),
                    ...(this.needCashOutput
                        ? {
                              cashOutput: {
                                  concept: this.concept,
                                  provider: this.provider,
                                  sourcePay: this.sourcePay,
                                  receiptFile: this.receiptFile
                              }
                          }
                        : null)
                });

                this.loaderInstance.close();
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Operación exitosa",
                    text: `🟢 Productos nuevos en inventario: ${result.inventoriesRecorded}.
                    🟢 Actualizaciones de stock ${result.inventoriesUpdated}.`,
                    icon: "success",
                    showCloseButton: true
                });
                this.resetItem();
                if (this.isOnboarding) {
                    return this.$router.push("/onboarding/order");
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        calculateTotals() {
            this.total = this.references.reduce(
                (a, b) => a + b.costNew * b.quantity,
                0
            );
        },
        validatePushReferences(reference) {
            const index = this.references.findIndex(
                (item) => item.id === reference.id
            );
            if (index === -1) {
                this.references.unshift({
                    ...reference,
                    priceNew: reference.priceOffer,
                    costNew: reference.cost,
                    quantity: 1
                });
            } else {
                let references = [...this.references];
                references[index] = {
                    ...references[index],
                    quantity: references[index].quantity + 1
                };
                this.references = [...references];
            }
            this.calculateTotals();
        },
        changeReference({ costNew, id, priceNew, quantity }) {
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references[index] = {
                ...references[index],
                quantity,
                costNew,
                priceNew
            };
            this.references = [...references];
            this.calculateTotals();
        },
        removeReference({ id }) {
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references.splice(index, 1);
            this.references = [...references];
            this.calculateTotals();
        },
        onBlurConcept() {
            this.onBlurSomeInput();
        },
        onBlurProvider() {
            this.onBlurSomeInput();
        },
        onBlurSomeInput() {
            this.enabledScanner = true;
        },
        onFocusSomeInput() {
            this.enabledScanner = false;
        },
        resetItem() {
            this.references = [];
            this.enabledScanner = !this.$isPhone;
            this.provider = "";
            this.concept = "";
            this.needCashOutput = false;
            this.needCashOutput = false;
            this.total = 0;
            this.receiptFile = { url: "", name: "" };
            this.introValidators = {
                provider: false,
                concept: false
            };
        },
        validateData() {
            const toComplete = [];
            if (this.needCashOutput) {
                this.introValidators = {
                    provider: true,
                    concept: true
                };
                if (!this.isValidProvider) {
                    toComplete.push("Proveedor");
                }
                if (!this.isValidConcept) {
                    toComplete.push("Concepto");
                }
            }

            if (!this.references.length) {
                toComplete.push("Referencias");
            }
            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Completa la información",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        }
    }
};
</script>
