<template>
    <div class="border-bottom border-info d-flex flex-wrap">
        <div
            class="col-lg-2 col-md-3 col-4 d-none d-md-flex justify-content-center align-items-center py-3"
        >
            <div class="w-28 h-28 p-0">
                <img
                    class="w-100 h-100"
                    :src="reference.images[0].url | image"
                    :alt="reference.images[0].alt"
                    loading="lazy"
                />
            </div>
        </div>
        <div
            class="col-lg-10 col-md-9 col-12 d-flex flex-column justify-content-around py-3 px-1"
        >
            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                    <strong>
                        {{ reference.product.name }}
                    </strong>
                    <small>
                        Prod
                        <router-link
                            :to="`/app/products/detail/${reference.productId}`"
                            class="btn-a p-0"
                        >
                            {{
                                reference.product.sku
                                    ? `SKU ${reference.product.sku}`
                                    : `ID ${reference.productId}`
                            }}
                        </router-link>
                        | Ref
                        <router-link
                            :to="`/app/products/detail/${reference.productId}?reference=${reference.id}&active=references`"
                            class="btn-a p-0"
                        >
                            {{
                                reference.barcode
                                    ? `Cód. ${reference.barcode}`
                                    : `ID ${reference.id}`
                            }}
                        </router-link>
                    </small>
                </div>
                <div v-if="enabled" id="actions" class="d-flex">
                    <vs-button danger icon @click="remove">🗑</vs-button>
                </div>
            </div>

            <div class="d-block d-md-none w-28 h-28 p-0 mx-auto mt-3">
                <img
                    class="w-100 h-100"
                    :src="reference.images[0].url | image"
                    :alt="reference.images[0].alt"
                    loading="lazy"
                />
            </div>

            <div class="d-flex flex-wrap mt-3">
                <div class="col-6 col-lg-4 d-flex flex-column">
                    <strong class="">Color </strong>
                    <div class="d-flex align-items-center">
                        <small class="text-capitalize">
                            {{ reference.color }}
                        </small>
                        <div
                            class="ml-1 len-3 rounded-circle border border-secondary"
                            :style="{
                                'background-color': reference.colorRef
                            }"
                        ></div>
                    </div>
                </div>
                <div class="col-6 col-lg-4 mt-3 d-flex flex-column">
                    <strong class="">Tamaño </strong>
                    <small class="text-capitalize">
                        {{ reference.size }}
                    </small>
                </div>
                <div class="col-6 col-lg-4 mt-3 d-flex flex-column">
                    <strong class="">U. Actuales </strong>
                    <small>
                        {{ reference.stock }}
                    </small>
                </div>
                <div class="col-6 col-lg-4 mt-3 d-flex flex-column">
                    <strong class="">Precio actual u. </strong>
                    <small>
                        {{ reference.priceOffer | money }}
                    </small>
                </div>
                <div class="col-6 col-lg-4 mt-3 d-flex flex-column">
                    <strong class="">Cost actual u. </strong>
                    <small>
                        {{ reference.cost | money }}
                    </small>
                </div>
                <div class="col-6 col-lg-4 mt-3 d-flex flex-column">
                    <strong class="">Margen bruto actual </strong>
                    <small>
                        {{ revenueCurrent | percent }}
                    </small>
                </div>
                <div class="col-6 col-lg-4 mt-3 d-flex flex-column">
                    <strong class="">
                        {{
                            isInputCostUnit
                                ? "Costo de lote"
                                : "Costo de unidad"
                        }}
                    </strong>
                    <small>
                        {{ messageValue | money }}
                    </small>
                </div>
                <div class="col-6 col-lg-4 mt-3 d-flex flex-column">
                    <strong class="">Nuevo Margen Burto</strong>
                    <small
                        :class="{
                            'text-success': revenue > 0.3,
                            'text-danger': revenue < 0.08
                        }"
                    >
                        {{ revenue | percent }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-lg-6 mt-5">
                    <vs-input
                        v-model="quantity"
                        border
                        shadow
                        min="1"
                        type="number"
                        :state="stateEnabled"
                        :disabled="!enabled"
                        label="Stock"
                        class="opacity-false"
                        @input="calculateUnitCost"
                        @focus="onFocus"
                        @blur="onBlur"
                    />
                </div>
                <div class="col-12 col-lg-6 mt-5 d-flex">
                    <input-money-component
                        v-model="costTemp"
                        border
                        :enabled="enabled"
                        :label="
                            isInputCostUnit
                                ? 'Costo de unidad'
                                : 'Costo de lote'
                        "
                        :message-error="
                            !isValidCost & introValidators.costNew
                                ? 'Costo debe Ser Mayor A $0 y Menor al Precio'
                                : ''
                        "
                        class="w-100 mw-100 opacity-false"
                        @input="calculateUnitCost"
                        @focus="onFocus"
                        @blur="onBlurCostOrPrice"
                    />
                    <vs-tooltip v-if="enabled">
                        <vs-button
                            icon
                            border
                            :active="isInputCostUnit"
                            @click="updateUnitCostSw"
                        >
                            {{ isInputCostUnit ? "1️⃣" : "🔢" }}
                        </vs-button>
                        <template #tooltip>
                            <div class="content-tooltip">
                                <span v-if="isInputCostUnit">
                                    Registrar con costo de lote
                                </span>
                                <span v-else>
                                    Registrar con costo de unidad
                                </span>
                            </div>
                        </template>
                    </vs-tooltip>
                </div>
                <div
                    v-if="reference.needRecord && requiredPrice"
                    class="col-12 col-lg-6 mt-5 d-flex"
                >
                    <input-money-component
                        v-model="priceNew"
                        border
                        :enabled="enabled"
                        label="Precio de venta"
                        class="w-100 mw-100"
                        @input="calculateUnitCost"
                        @focus="onFocus"
                        @blur="onBlurCostOrPrice"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { DEFAULT_IMAGE_URL } from "@/common/constants";

import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "ReferenceInventoryItems",
    components: { InputMoneyComponent },
    props: {
        reference: {
            type: Object,
            required: true,
            default: () => ({
                id: 20,
                size: "pequeño",
                color: "rojo",
                colorRef: "#f00",
                quantity: 1,
                cost: 0,
                costNew: 0,
                price: 10000,
                priceOffer: 10000,
                needRecord: false,
                images: [
                    {
                        id: 1,
                        url: DEFAULT_IMAGE_URL,
                        alt: "alt"
                    }
                ],
                product: {
                    id: "",
                    name: "",
                    shortDescription: ""
                }
            })
        },
        enabled: {
            type: Boolean,
            required: false,
            default: () => true
        },
        requiredPrice: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    emits: ["update", "remove", "focus", "blur"],
    data: () => ({
        quantity: 0,
        costTemp: 0,
        priceNew: 0,
        costNew: 0,
        revenue: 0,
        isInputCostUnit: true,
        introValidators: { costNew: false },
        messageValue: 0,
        revenueCurrent: 0
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"]),
        stateEnabled() {
            return this.enabled ? this.stateInputDark : "";
        },
        isValidCost() {
            return this.costNew > 0 && this.costNew <= this.priceNew;
        }
    },
    watch: {
        reference(value) {
            this.setInput(value);
        }
    },
    mounted() {
        const costNew =
            this.reference.costNew ||
            this.reference.cost ||
            this.reference.priceOffer * 0.7;
        this.quantity = Number(this.reference.quantity);
        this.costTemp = +costNew;
        this.costNew = +costNew;
        this.messageValue = Number(costNew);
        this.revenue = 1 - costNew / this.reference.priceOffer;
        this.revenueCurrent =
            1 - this.reference.cost / this.reference.priceOffer;
        this.setInput(this.reference);
        this.changeReference();
    },
    methods: {
        goToMainPage(slug) {
            window.open(`${this.$marketplaceUrl}/${slug}`, "_blank").focus();
        },
        remove() {
            this.$emit("remove", { id: this.reference.id });
        },
        validateCost() {
            this.introValidators.cost = true;
            this.calculateUnitCost();
        },
        applyCost() {
            if (this.isInputCostUnit) {
                this.costNew = +this.costTemp;
            } else {
                this.costNew = this.costTemp / this.quantity;
            }
        },
        updateUnitCostSw() {
            this.applyCost();
            this.isInputCostUnit = !this.isInputCostUnit;
            this.calculateUnitCost();
        },
        calculateUnitCost() {
            this.applyCost();
        },
        changeReference() {
            this.$emit("update", {
                id: this.reference.id,
                costNew: this.costNew,
                priceNew: this.priceNew,
                quantity: this.quantity
            });
        },
        setInput(value) {
            this.quantity = +value.quantity;
            this.messageValue = this.isInputCostUnit
                ? value.costNew * value.quantity
                : +value.costNew;
            const costNew =
                value.costNew || value.cost || value.priceOffer * 0.7;
            this.priceNew = +value.priceNew || value.price;
            this.applyCost();
            this.costNew = +costNew;
            this.revenue = 1 - this.costNew / this.priceNew;
        },
        onFocus() {
            this.$emit("focus");
        },
        onBlur() {
            this.$emit("blur");
            this.changeReference();
        },
        onBlurCostOrPrice() {
            this.introValidators.costNew = true;
            this.onBlur();
        }
    }
};
</script>
