<template>
    <div>
        <div v-show="show" class="w-100 d-flex justify-content-center">
            <div
                id="order-invoice"
                ref="orderTemplate"
                class="w-ticket bg-white text-dark py-3"
            >
                <div class="d-flex flex-column align-items-center">
                    <img
                        src="/img/logos/complete_logo.png"
                        width="80"
                        alt="Logo"
                    />
                    <div
                        class="text-center d-flex flex-column align-items-center justify-content-center"
                    >
                        <div v-if="order.store" class="d-flex flex-column">
                            <span>
                                {{ order.store.name }}
                            </span>
                            <span>
                                {{ order.store.address }}
                            </span>
                        </div>

                        <small v-else>{{ $marketplaceUrl }}</small>
                        <small>
                            {{ order.createdAt | date }}
                        </small>
                    </div>
                </div>

                <div class="mb-3 pl-2">
                    <div
                        v-if="order.customer && order.customer.firstName"
                        class="d-flex w-100"
                    >
                        <span class="font-weight-bold">Cliente:&nbsp;</span>
                        <span>
                            {{ order.customer.firstName }}&nbsp;{{
                                order.customer.lastName
                            }}
                        </span>
                    </div>
                    <div v-if="order.paymentMethod" class="d-flex w-100">
                        <span class="font-weight-bold">
                            Método de pago:&nbsp;
                        </span>
                        <span>{{ paymentMethod }}</span>
                    </div>
                    <div v-if="order.valuePaid < order.total">
                        <span class="font-weight-bold"> Abono:&nbsp; </span>
                        <span> {{ order.valuePaid | currency }} </span>
                    </div>
                </div>

                <table class="table print border-bottom border-2">
                    <thead>
                        <tr>
                            <th scope="col-5">
                                <span class="font-weight-bold text-sm">
                                    Producto
                                </span>
                            </th>
                            <th scope="col-1">
                                <span class="font-weight-bold text-sm">
                                    Uds.
                                </span>
                            </th>
                            <th scope="col-3">
                                <span class="font-weight-bold text-sm">
                                    $Ud.
                                </span>
                            </th>

                            <th scope="col-3">
                                <span class="font-weight-bold text-sm">
                                    Total
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in references" :key="index">
                            <td class="col-5 pr-0">
                                <span class="text-sm">
                                    {{ item.product.name | cut(36) }}<br />
                                    #{{ item.id }} | T {{ item.size }}
                                </span>
                            </td>
                            <th scope="col-1 text-center">
                                <span class="text-sm">
                                    {{ item.quantity }}
                                </span>
                            </th>
                            <td class="col-3 text-center">
                                <small>
                                    {{
                                        (item.priceOffer -
                                            item.appliedDiscountPerReference ||
                                            0) | currency(true, true)
                                    }}
                                </small>
                            </td>
                            <td class="col-3">
                                <small>
                                    {{
                                        ((item.priceOffer -
                                            item.appliedDiscountPerReference ||
                                            0) *
                                            item.quantity)
                                            | currency(true, true)
                                    }}
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1" scope="colgroup">
                                <div
                                    class="h-100 d-flex justify-content-center align-items-end"
                                >
                                    <span class="font-weight-bold h3 m-0">
                                        Total
                                    </span>
                                </div>
                            </th>
                            <th colspan="3" scope="colgroup">
                                <div
                                    class="h-100 d-flex justify-content-center align-items-end"
                                >
                                    <span class="h4 m-0">
                                        {{ order.total | currency(true, true) }}
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>

                <div
                    class="mt-3 d-flex flex-column justify-content-center align-items-center"
                >
                    <barcode-viewer :input="order.uuid" />
                    <small> #{{ order.id }} - {{ order.uuid }} </small>
                    <p class="text-center d-flex justify-content-center">
                        ¡GRACIAS POR TU COMPRA !
                    </p>
                </div>
            </div>
        </div>

        <div v-if="showPrint" class="w-100 d-flex justify-content-center mt-3">
            <vs-button color="#02913f" relief @click="generateImage">
                <span class="py-1"> IMÁGEN </span>
            </vs-button>
            <vs-button color="#f07325" relief @click="generatePdf">
                <span class="py-1 mx-3"> PDF </span>
            </vs-button>
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
import { paymentMethodsAbbreviation } from "@/common/fields/payment-methods";
import {
    paymentStatuses,
    paymentStatusesCodes
} from "@/common/fields/payment-status-codes";
import { downLoadImage, elementToImage, openImage } from "@/common/lib/images";
import { downLoadPDF, elementToPDF, openPDF } from "@/common/lib/pdf";

import BarcodeViewer from "@/components/utils/BarcodeViewer.vue";

export default {
    name: "OrderPrinter",
    components: { BarcodeViewer },
    props: {
        show: {
            default: () => false,
            required: false,
            type: Boolean
        },
        order: {
            default: () => ({
                id: 0,
                uuid: "12123131",
                paymentMethod: "cashonpickup",
                paymentStatus: "pya",
                customerName: "",
                createdAt: "2022-07-15T02:39:03.833Z",
                total: 10,
                references: [],
                anonymousReferences: []
            }),
            required: false,
            type: Object
        },
        showPrint: {
            default: () => false,
            required: false,
            type: Boolean
        }
    },
    data: () => ({
        paymentMethod: paymentStatusesCodes.pnd,
        references: []
    }),
    watch: {
        order(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.order);
    },
    methods: {
        async generateImage() {
            const imgData = await elementToImage(
                document.getElementById("order-invoice")
            );
            if (!this.$isPhone) {
                downLoadImage(imgData.image, `order_${this.order.id}`);
            } else {
                openImage(imgData, `Orden ${this.order.id}`);
            }
        },
        async generatePdf() {
            const pdf = await elementToPDF(
                document.getElementById("order-invoice")
            );
            if (this.$isPhone) {
                downLoadPDF(pdf, `order_${this.order.id}.pdf`);
            } else {
                openPDF(pdf);
            }
        },
        setInput(value) {
            if (value.paymentStatus === paymentStatuses.approved) {
                this.paymentMethod =
                    paymentMethodsAbbreviation[value.paymentMethod];
            }
            this.references = [
                ...value.references,
                ...value.anonymousReferences
            ].filter((item) => item.quantity);
            if (!this.showPrint) {
                this.generateImage();
            }
        }
    }
};
</script>
