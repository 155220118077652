<template>
    <div class="w-100 d-flex flex-column justify-content-center my-2">
        <div class="d-flex flex-wrap justify-content-center">
            <div v-if="!!error" class="alert alert-danger" role="alert">
                {{ error }}
            </div>
        </div>
        <div v-if="requiredAlt" class="d-flex flex-wrap px-4 py-4">
            <div class="col-12">
                <div class="form-group py-2">
                    <vs-input
                        v-model="alt"
                        type="text"
                        name="alt"
                        :disabled="isLoading"
                        label="Nombre del archivo"
                        class="input-theme"
                        state="success"
                        :class="validAlt ? 'border-custom' : ''"
                        aria-label="Alt"
                        icon-after
                        autocomplete="off"
                        @blur="introValidators.alt = true"
                    >
                        <template #icon> 📃</template>
                        <template #message-danger>
                            <div v-show="!validAlt && introValidators.alt">
                                No ingrese simbolos raros, debe tener entre 8 y
                                64 carácteres.
                            </div>
                        </template>
                    </vs-input>
                </div>
            </div>
        </div>
        <div
            v-if="wasUploaded && itemCurrent.id"
            class="d-flex justify-content-center"
        >
            <div class="alert alert-success p-2">
                <a :href="url" target="_blank" rel="noopener">
                    {{ itemCurrent.name }}({{ itemCurrent.id }})
                </a>
            </div>
        </div>
        <div
            v-else
            class="d-flex flex-wrap d-flex justify-content-center"
            @click="clickButton"
        >
            <label
                class="w-64 d-flex flex-column align-items-center px-4 py-6 btn btn-success rounded text-uppercase"
            >
                <img
                    src="@/assets/images/upload.svg"
                    height="20"
                    width="20"
                    alt="upload file"
                />
                <span class="mt-2 text-base leading-normal">
                    {{ selectedFile ? file.name : "Seleccione archivo" }}
                </span>
                <input
                    id="application-uploader"
                    :disabled="isLoading || (!validAlt && requiredAlt)"
                    type="file"
                    accept="image/*,application/pdf"
                    class="d-none"
                    @input="uploadButton($event)"
                />
            </label>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { altRegex } from "@/common/lib/regex";

export default {
    name: "FileUploader",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: Object,
            required: false,
            default: () => ({ id: null, name: "" })
        },
        type: {
            default: () => "productFile",
            type: String,
            required: false
        },
        autoApprove: {
            default: () => false,
            type: Boolean,
            required: false
        },
        autoLoader: {
            default: () => false,
            type: Boolean,
            required: false
        },
        requiredAlt: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        id: null,
        url: "",
        alt: "",
        error: "",
        isLoading: false,
        wasUploaded: false,
        selectedFile: false,
        file: { name: "", type: "productFile", url: "" },
        introValidators: { alt: false }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"]),
        validAlt() {
            return altRegex.test(this.alt);
        }
    },
    methods: {
        ...mapActions("files", [
            "getEndPointUploadFile",
            "uploadFile",
            "createFile"
        ]),
        async uploadButton(event) {
            const auxFile = event.target.files || event.dataTransfer.files;
            if (!auxFile.length) return;
            this.file = auxFile[0];
            const fileSize = this.file.size / 1048576;
            if (fileSize > 40) {
                this.error = "Archivo Demasiado Pesado Máximo 40 MB";
                return;
            }
            this.error = "";

            if (!this.autoApprove) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: `Aseguras que ${this.file.name} es el archivo adecuado ?`,
                    icon: "info",
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "Si",
                    cancelButtonText: "No 😖",
                    confirmButtonColor: "#02913f",
                    cancelButtonColor: "#f07325",
                    allowOutsideClick: false
                });
                if (!dialogResult.isConfirmed) {
                    this.selectedFile = false;
                    return;
                }
            }

            this.isLoading = true;
            this.$emit("loading", true);
            if (this.autoLoader) {
                this.loaderInstance = this.$vs.loading({ type: "circles" });
            }
            try {
                const { alt, id, url, urlSigned } =
                    await this.getEndPointUploadFile({
                        name: this.file.name,
                        size: this.file.size,
                        type: this.type,
                        alt: this.alt
                    });
                this.url = url;
                this.$emit("change", {
                    id,
                    url,
                    alt: this.alt,
                    type: this.type,
                    name: this.file.name
                });
                await this.uploadFile({
                    url: urlSigned,
                    file: this.file
                });
                this.$emit("created", {
                    name: this.file.name,
                    alt,
                    url,
                    id
                });
                this.wasUploaded = true;
            } catch (error) {
                if (this.loaderInstance) {
                    this.loaderInstance.close();
                }
                this.error = error.message;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.wasUploaded = false;
                this.file = { name: "undefined", size: "11111" };
            } finally {
                this.$emit("loading", false);
                if (this.loaderInstance) {
                    this.loaderInstance.close();
                }
                this.isLoading = false;
            }
        },
        async clickButton() {
            if (!this.validateData()) {
                this.error =
                    'Antes de subir la archivo verifica haber llenado el campo "Nombre de archivo"';
            }
        },
        validateData() {
            this.introValidators = { alt: false };
            return this.validAlt || !this.requiredAlt;
        }
    }
};
</script>
