<template>
    <div>
        <div class="d-flex flex-column">
            <div
                id="title-general"
                class="d-flex flex-column flex-md-row justify-content-between align-items-center"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <router-link
                        to="/app/cash-outputs"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                    <h1 class="h2">
                        &nbsp;Detalle de gasto
                        <span class="text-primary"> #{{ id }} </span>
                    </h1>
                </div>
            </div>
        </div>
        <div v-if="loadedAllData" id="bill-features">
            <div class="d-flex flex-wrap">
                <div v-if="id" class="col-12 mt-3 p-0 d-flex">
                    <small>
                        Fecha de creación 📆💾
                        {{ itemComponent.createdAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.merchant.name"
                        border
                        shadow
                        :state="stateEnabled"
                        :label="
                            itemComponent.merchant.type
                                | merchantInfo('type')
                                | transformString('capitalize')
                        "
                        class="opacity-false"
                        disabled
                    >
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.user.email"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Registrado por"
                        class="opacity-false"
                        disabled
                    >
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.concept"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Concepto"
                        class="opacity-false"
                        disabled
                    >
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.provider"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Proveedor"
                        class="opacity-false"
                        disabled
                    >
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <input-money-component
                        v-model="itemComponent.value"
                        border
                        label="Valor"
                        class="opacity-false"
                        :enabled="false"
                    />
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <input-money-component
                        v-model="itemComponent.valuePaid"
                        border
                        label="Valor pagado"
                        class="opacity-false"
                        :enabled="false"
                    />
                </div>
            </div>

            <div v-if="itemComponent.receiptFile" class="d-flex flex-wrap mt-3">
                <div class="col-12 d-flex">
                    <button class="btn btn-primary">
                        Descargar comprobante de pago
                        {{ itemComponent.receiptFile.name }}
                    </button>
                </div>
            </div>

            <div
                v-if="itemComponent.inputs.length > 0"
                class="d-flex flex-wrap z-0 mt-3 mb-4 h-min-32"
            >
                <div class="col-12">
                    <h4 class="mt-3">Referencias ingresadas al inventario</h4>
                    <reference-inventory-items
                        v-for="(reference, index) in itemComponent.inputs"
                        :key="'A' + index"
                        :enabled="false"
                        class="col-md-11 col-12 mx-auto"
                        :reference="reference"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { EMPTY_MONEY_CONFIG } from "@/common/lib/money";

import ReferenceInventoryItems from "@/components/references/ReferenceInventoryItems.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "CashOutputDetail",
    components: { InputMoneyComponent, ReferenceInventoryItems },
    data: () => ({
        id: 0,
        isLoading: false,
        loaderInstance: null,
        loadedAllData: false,
        enabledUpdate: false,
        itemComponent: {
            id: 3,
            value: 845,
            valuePaid: 845,
            concept: "Proce",
            provider: "Distr",
            createdAt: "2023-04-10T01:51:38.015Z",
            moneyConfig: EMPTY_MONEY_CONFIG,
            receiptFile: { url: "", name: "" },
            inputs: [],
            user: {
                id: "b8529984-56ab-4783-9fa9-a14fd7ce9a06",
                email: "innovalombia@gmail.com"
            },
            merchant: {
                id: "c444962d-4c2e-42fa-bc43-600cfe10cebd",
                name: "Timiza",
                type: "store"
            }
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("cashOutput:Get", "/app/cash-outputs")))
            return;
        const id = Number(this.$route.params.id);
        this.id = id;
        await this.getBaseCashOutput({
            id,
            merchantId: this.$route.query.merchantId,
            merchantType: this.$route.query.merchantType
        });
    },
    methods: {
        ...mapActions("cashOutput", ["getCashOutputById"]),
        async getBaseCashOutput({ id, merchantId, merchantType }) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                this.itemComponent = await this.getCashOutputById({
                    id,
                    merchantId,
                    merchantType
                });
                this.loadedAllData = true;
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/cash-outputs");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        }
    }
};
</script>
